import React, { forwardRef, useImperativeHandle } from 'react';
// import useStyles from 'isomorphic-style-loader/useStyles';
import { withStyles } from '@mui/styles';;
import s from './Dialogs.module.css';
import { Grid, Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import TableComponent from 'src/components/Table/TableComponent';
import PrimaryButton from 'src/components/Buttons/PrimaryButton.js';
import apiRequest from 'src/utils/api';
import Modal from 'src/components/Modal/Modal';

function TranQtyTrack({ }, tranQtyTrackRef) {
  const UseFocus = () => {
    const htmlElRef = React.useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  // useStyles(s);

  const [batch, setBatch] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const buttonRef = React.useRef(null);

  useImperativeHandle(
    tranQtyTrackRef,
    () => ({
      open(batchData, rowData) {
        handleOpen();
        setBatch(batchData);
        setRows(rowData);
      },
    }),
    [],
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleInvoiceOpen(row) {
    try {
      window.open(`${row?.navigate_to}`, '_blank');
    } catch (error) {
      alert(error.message);
    }
  }
  return (
    <Modal
      open={open}
      content={{
        header: `Track Tran Quantity (${batch?.product_name ?? ''} -  ${batch?.batch_number ?? ''
          })`,
        body: (
          <Box style={{ minHeight: '300px' }}>
            {/* <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '56px',
                width: '100%',
                padding: '0 8px',
                marginBottom: '8px',
                borderRadius: '8px',
                alignItems: 'center',
              }}
            >
              <PrimaryButton
                title="Generate"
                executeClick={() => {
                  getProductLedgerReport(product);
                }}
                customWidth="128px"
                customHeight="29px"
                ref={buttonRef}
              />
            </Grid> */}
            <TableComponent
              columns={[
                { id: 'type', label: 'Type', minWidth: 40, type: 'label' },
                {
                  id: 'entry_number',
                  label: 'Entry #',
                  minWidth: 40,
                  type: 'link',
                },
                {
                  id: 'entry_date',
                  label: 'Entry Date',
                  minWidth: 40,
                  type: 'date',
                },
                { id: 'quantity', label: 'Qty', minWidth: 40, type: 'label' },
                { id: 'free', label: 'Free', minWidth: 40, type: 'label' },
                {
                  id: 'added_by',
                  label: 'Added By',
                  minWidth: 50,
                  type: 'label',
                },
              ]}
              rows={rows}
              linkTextClicked={handleInvoiceOpen}
              customHeight="256px"
              rowClickable={false}
              colorOnRowSelected={false}
            />
            {/* <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '44px',
                width: '100%',
                justifyContent: 'space-between',
                padding: '0 8px',
                marginBottom: '8px',
                marginTop: '8px',
                borderRadius: '8px',
              }}
            >
              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Opening Stock:&nbsp;</p>
                <p className={s.headerValues}>
                  {analytics?.opening_stock || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Inward:&nbsp;</p>
                <p className={s.headerValues}>{analytics?.inward || 0}</p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Outward:&nbsp;</p>
                <p
                  className={s.headerValues}
                  // style={{
                  //   color:
                  //     analytics?.net_outstanding_amount !=
                  //     analytics?.closing_balance
                  //       ? '#E16005'
                  //       : '',
                  // }}
                >
                  {analytics?.outward || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Closing Stock:&nbsp;</p>
                <p
                  className={s.headerValues}
                  // style={{
                  //   color:
                  //     analytics?.net_outstanding_amount !=
                  //     analytics?.closing_balance
                  //       ? '#E16005'
                  //       : '',
                  // }}
                >
                  {analytics?.closing_stock || 0}
                </p>
              </Grid>
            </Grid> */}
          </Box>
        ),
      }}
      modalClose={handleClose}
      size="xsm"
    />
  );
}

export default forwardRef(TranQtyTrack);

import {
  Page,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  PDFDownloadLink,
  View,
  Text,
} from '@react-pdf/renderer';
import styled from "@emotion/styled";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Snackbar from '@mui/material/Snackbar'
import { StyleConstants, ColorConstants } from "src/styles/StyleConstants";
//import { useSnackbar } from 'notistack';





import React, { Component, Fragment } from 'react';
import ReportTitle from './ReportTitle';
import ReportTable from './ReportTable';
import ReportFooter from './ReportFooter';

import s from './Invoice.module.css';


const StyledPDFDownloadLink = styled(PDFDownloadLink)`

font-weight: bold;
font-size: 1.5rem;
width: 250px;   
text-align: center; 
  background-color: ${ColorConstants.PRIMARY};  
  border-radius: 5px;
  color: white;
  padding: 1.5rem;
  text-decoration: none;
  display: flex;
    justify-content: center;
    gap: 10px;

`;

const DownloadLinkContainer = styled.div`
 position: absolute;
 top: 20%;
 left:0;
 right:0;
 margin-left: auto;
 margin-right: auto;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 padding: 1rem;
 text-align: center;
`;

export default function Main({
  documentTitle = '',
  pdfSize = 'A4',
  title = '',
  columns,
  products,
  firmDetails,
  maxRowsPerPagePortrait = 46,
  maxRowsPerPageLandscape = 31,
  timestamp,
  username,
  orientation = 'portrait',
  tableType,
  moduleSettings,
  printBlob = false,
  additionalHeaderRow,
  reportSettings,
  isMobile = false,
  closePreview = () => { },
}) {
  //const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  Font.register({
    family: 'Helvetica',
    fonts: [
      {
        src: `./data/Helvetica.ttf`,
      },
      {
        src: `./data/Helvetica-Bold.ttf`,
        fontWeight: 'bold',
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 11,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      lineHeight: 1.5,
      flexDirection: 'column',
    },
    logo: {
      width: 74,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  });

  var printPages;

  const pages = [];
  let max_items =
    orientation === 'portrait'
      ? maxRowsPerPagePortrait - (additionalHeaderRow?.length > 0 ? 1 : 0)
      : maxRowsPerPageLandscape - (additionalHeaderRow?.length > 0 ? 1 : 0);

  let product_rows = [...products];

  const count = parseInt((product_rows.length / max_items).toFixed(0));
  let i = 0;
  if (count > product_rows.length / max_items) {
    var start = 0;
    var end = 0;
    for (i = 0; i < count; i++) {
      end =
        product_rows.length >= start + max_items
          ? start + (i == 0 ? max_items - 2 : max_items)
          : product_rows.length;
      pages[i] = product_rows.slice(start, end);
      start = end;
    }
  } else {
    var start = 0;
    var end = 0;
    for (i = 0; i < count + 1; i++) {
      end =
        product_rows.length >= start + max_items
          ? start + (i == 0 ? max_items - 2 : max_items)
          : product_rows.length;
      pages[i] = product_rows.slice(start, end);
      start = end;
    }
  }

  printPages = pages.map((page, index) => {
    if (page?.length > 0)
      return (
        <Page size={pdfSize} orientation={orientation} style={styles.page}>
          <ReportTitle
            title={title}
            firmDetails={firmDetails}
            length={pages?.length}
            page={index + 1}
            reportSettings={
              pdfSize == 'A4'
                ? reportSettings
                : { ...reportSettings, half_page: false }
            }
          />
          <ReportTable
            columns={columns}
            rows={page}
            maxItems={index == 0 ? max_items - 2 : max_items}
            tableType={tableType}
            additionalHeaderRow={additionalHeaderRow}
            reportSettings={
              pdfSize == 'A4'
                ? reportSettings
                : { ...reportSettings, half_page: false }
            }
          />
          <ReportFooter
            date={timestamp}
            username={username}
            reportSettings={
              pdfSize == 'A4'
                ? reportSettings
                : { ...reportSettings, half_page: false }
            }
          />
        </Page>
      );
  });


  if (printBlob) {
    return <Document title={documentTitle}>{printPages}</Document>;
  }
  if (isMobile) {
    return <DownloadLinkContainer onClick={(e) => {
      setTimeout(() => {
        // enqueueSnackbar('Report successfully downloaded', {
        //   variant: 'success',
        //   autoHideDuration: 100000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   sx: { position: 'absolute' },
        // });
        closePreview();
      }, 1000);
    }}>
      <p>{`${documentTitle} - ${firmDetails?.firm_name}`}</p>
      <StyledPDFDownloadLink fileName={`${documentTitle} - ${firmDetails?.firm_name}`} document={<Document title={documentTitle} >{printPages}</Document>}>
        <FileDownloadIcon fontSize={'large'} /> Download
      </StyledPDFDownloadLink>
    </DownloadLinkContainer>
  }
  return (
    <Fragment>
      <div style={{ height: '100%' }}>
        <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
          <Document title={documentTitle}>{printPages}</Document>
        </PDFViewer>
      </div>
    </Fragment>
  );

}


import * as React from "react";
import styled from "@emotion/styled";
import { Logo } from "./Logo";
import { StyleConstants, ColorConstants } from "src/styles/StyleConstants";
import { Nav } from "./Nav";
interface Props {
  title: string;
}
export function NavBar(props: Props) {
  return (
    <Wrapper>
      <Logo title={props?.title} />
      <Nav />
    </Wrapper>
  );
}

const Wrapper = styled.header`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: ${StyleConstants.NAV_BAR_HEIGHT};
  padding: ${StyleConstants.PAGE_PADDING};
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

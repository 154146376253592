import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  ThemeProvider,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { useSelector } from 'react-redux';

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#bedcfa',
        },
      },
      row: {
        height: 10,
      },
      head: {
        height: 10,
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  row: {
    border: 'none',
    height: 10,
  },
});

export default function SupplierSearchTable({ rows, rowSelected }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [selected, setSelected] = useState(rows.length > 0 ? rows[0].code : '');
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows.length > 0 ? 0 : -1);
  const rowRef = useRef();
  const customizations = useSelector((state) => state.customizations);

  const columns = customizations?.supplier_search?.defaultColumns ?? [
    { id: 'code', label: 'Code', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 200 },
    { id: 'city', label: 'City', minWidth: 50 },
    { id: 'address_1', label: 'Addr 1', minWidth: 60 },
    { id: 'address_2', label: 'Addr 2', minWidth: 60 },
    { id: 'address_3', label: 'Addr 3', minWidth: 60 },
    { id: 'pincode', label: 'Pincode', minWidth: 50 },
    { id: 'state', label: 'State', minWidth: 80 },
    { id: 'phone', label: 'Phone', minWidth: 80 },
    { id: 'gstin', label: 'GSTIN', minWidth: 80 },
    { id: 'email', label: 'Email', minWidth: 80 },
    { id: 'contact_person', label: 'Cont. Person', minWidth: 100 },
    { id: 'secondary_phone', label: 'Sec. Phone', minWidth: 80 },
    { id: 'sms_cell', label: 'SMS Phone', minWidth: 80 },
  ];
  function handleClick(code) {
    const data = [findArrayElement(rows, code)];
    console.log(data);
    rowSelected(data);
  }

  function findArrayElement(array, code) {
    return array.find((obj) => {
      return obj.code === code;
    });
  }

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows[0].id);

      setRows(
        rows.map((item) =>
          item.id === rows[0].id ? { ...item, code: 'changed' } : item,
        ),
      );
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows.length - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      }
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function keyDown() {
    if (currentSelected < rows.length - 1) {
      const val = currentSelected + 1;

      setCurrent(val);
      setSelected(rows[val].id);

      setRows([...rows]);
    } else if (currentSelected == rows.length - 1) {
      setCurrent(0);
      setSelected(rows[0].id);

      setRows([...rows]);
    }
    console.log(rows.length);
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function rowClicked() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].code)];
      console.log(data);
      rowSelected(data);
    }
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={rowClicked}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <ThemeProvider theme={theme}>
                    <TableRow
                      hover
                      className={classes.row}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={row.id === selected}
                      ref={row.id === selected ? rowRef : null}
                      onClick={() => handleClick(row.code)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </ThemeProvider>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

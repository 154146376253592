// // // import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';

import Print from './Main';

export default function PrintPreview({
  title,
  subTitle,
  columns = [],
  rows,
  orientation = 'portrait',
  tableType = 'data-grid',
  moduleSettings = {},
  additionalHeaderRow = [],
  reportSettings = {},
}) {
  // // useStyles(s);

  const userState = useSelector((state) => state.user);
  const firmDetails = useSelector((state) => state.settings);
  const [timestamp, setTimeStamp] = useState('');

  React.useEffect(() => {
    const time = Moment().format('HH:mm:ss');
    setTimeStamp(`${Moment().format('DD-MMM-YYYY')} ${time}`);
  }, []);

  return (
    <Print
      title={title}
      subTitle={subTitle}
      columns={columns}
      products={rows}
      firmDetails={firmDetails}
      timestamp={timestamp}
      username={userState?.username}
      orientation={orientation}
      tableType={tableType}
      moduleSettings={moduleSettings}
      additionalHeaderRow={additionalHeaderRow}
      reportSettings={reportSettings}
    />
  );
}

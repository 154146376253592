import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import { Grid, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import BlockIcon from '@mui/icons-material/Block';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Moment from 'moment';
import { numberFormat } from 'src/constants/number';
import { id } from 'date-fns/locale';
import { DeleteOutlined } from 'src/components/icons/customIcons';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { COLORS } from 'src/constants/colors';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import ColorButton from 'src/components/Buttons/ColorButton';
import { InformationFilled, Gift } from 'src/components/icons/customIcons';

const CustomCheckbox = withStyles({
  root: {
    color: COLORS.cta_blue,
    '&$checked': {
      color: COLORS.cta_blue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      background: '#F3F7FA',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

export default function TableComponent({
  columns,
  rows,
  updateRows,
  tableName = 'default',
  updateRowSelected = () => { },
  updateRowClicked = () => { },
  rowViewed = () => { },
  linkTextClicked = () => { },
  primaryButtonClicked = () => { },
  infoIconClicked = () => { },
  updateRowDeleted = () => { },
  updateActiveStatus,
  isFirstSelected = false,
  rowClickable = true,
  colorOnRowSelected = true,
  customHeight = '100%',
  checkedRows,
  handleRowCheck,
  handleAllRowCheck,
  reverseOrder = false,
  keyNavigation = false,
  styles = {
    root: {},
  },
}) {
  const theme = createTheme({
    overrides: {
      MuiTableRow: {
        root: {
          '#TableComponent &$selected, &$selected:hover': {
            backgroundColor: colorOnRowSelected ? '#d8ebfe' : '#fff',
          },
        },
        row: {
          height: 10,
        },
        head: {
          height: 10,
        },
      },
    },
  });

  const useStyles = makeStyles({
    root: {
      width: '100%',
      ...styles?.root,
    },
    container: {
      backgroundColor: '#f2f2f2',
      height: customHeight,
    },
    row: {
      border: 'none',
      height: 10,
      '& .Mui-selected': {
        color: '#3c98e8',
      },
    },
    textfield: {
      height: 20,
    },
    informationIconContainer: {
      height: '15px',
      width: '15px',
      backgroundColor: '#f4faff',
      outline: '1px solid #cad8df',
      padding: '4px',
      margin: '0',
    },
  });
  const classes = useStyles();
  const today = Moment().format('YYYY-MM-DD');
  const [currentSelected, setCurrent] = React.useState(
    rows?.length > 0 ? 0 : -1,
  );

  const [rowData, setRows] = React.useState(rows);

  const [selected, setSelected] = React.useState(
    rows?.length > 0 && isFirstSelected ? rows[0].id : '',
  );

  useEffect(() => {
    if (rows?.length > 0 && isFirstSelected) {
      setSelected(rows[0]?.id);
      setCurrent(0);
    }
  }, [rows]);

  const isRowChecked = (id) => checkedRows?.indexOf(id) !== -1;

  const rowRef = React.useRef();
  function handleValueChange(value, id, code) {
    updateRows(value, id, code);
  }

  function handleClick(id) {
    const data = [findArrayElement(rows, id)];
    setSelected(id);
    updateRowSelected(data);
  }

  function rowClicked(id) {
    if (currentSelected != -1) {
      let data = [findArrayElement(rows, id)];
      updateRowClicked(data);
    }
  }

  function deleteClick(id) {
    const data = [findArrayElement(rows, id)];
    updateRowDeleted(data);
  }

  function findArrayElement(array, id) {
    return array.find((obj) => {
      return obj.id === id;
    });
  }

  function keyDown(e) {
    if (keyNavigation && rows?.length > 0) {
      e.preventDefault();
      if (currentSelected < rows.length - 1 && isFirstSelected) {
        const val = currentSelected + 1;
        setCurrent(val);
        handleClick(rows[val].id);
        setRows([...rows]);
      } else if (currentSelected == rows.length - 1 && isFirstSelected) {
        setCurrent(0);
        handleClick(rows[0].id);
        setRows([...rows]);
      }
      rowRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'end',
        inline: 'start',
      });
    }
  }

  function keyUp(e) {
    if (keyNavigation && rows?.length > 0) {
      e.preventDefault();
      if (currentSelected == -1 && isFirstSelected) {
        setCurrent(0);
        handleClick(rows[0].id);
        setRows([...rows]);
      } else if (currentSelected > -1 && isFirstSelected) {
        if (currentSelected == 0) {
          var val = rows.length - 1;
          setCurrent(val);
          handleClick(rows[val].id);
          setRows([...rows]);
        } else {
          var val = currentSelected - 1;
          setCurrent(val);
          handleClick(rows[val].id);
          setRows([...rows]);
        }
      }
      rowRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'end',
        inline: 'start',
      });
    }
  }

  function renderIndicators(row) {
    let signsArr = [];
    if (row?.indicator_flags?.[0]?.selling_price_change_indicator)
      signsArr.push({ letter: 'M', info: 'Selling Price Changed' });
    if (row?.indicator_flags?.[0]?.net_rate_indicator)
      signsArr.push({ letter: 'N', info: 'Billed on Net Rate' });
    if (row?.indicator_flags?.[0]?.stock_completed_indicator)
      signsArr.push({ letter: 'C', info: 'Stock Completed' });
    if (row?.indicator_flags?.[0]?.low_stock_indicator)
      signsArr.push({ letter: 'L', info: 'Low Stock' });
    if (row?.indicator_flags?.[0]?.item_supplied_indicator)
      signsArr.push({ letter: 'S', color: '#16c163', info: 'Item Supplied' });
    if (row?.indicator_flags?.[0]?.item_claimed_indicator)
      signsArr.push({ letter: 'C', color: '#16c163', info: 'Item Claimed' });
    if (row?.indicator_flags?.[0]?.item_replacement_indicator)
      signsArr.push({
        letter: 'R',
        color: '#16c163',
        info: 'Replacement Item',
      });
    if (row?.indicator_flags?.[0]?.item_not_calulated_indicator)
      signsArr.push({ letter: 'NC', info: 'Item not Calculated' });
    if (row?.indicator_flags?.[0]?.packing_reminder_item)
      signsArr.push({
        letter: 'P',
        color: '#eb10ea',
        info: 'Packing Reminder',
      });
    if (row?.indicator_flags?.[0]?.composite_packing_indicator)
      signsArr.push({
        letter: 'CP',
        color: '#eb10ea',
        info: 'Composite Packing Item',
      });
    if (row?.indicator_flags?.[0]?.auto_scheme_indicator)
      signsArr.push({
        letter: 'SCH',
        color: '#eb10ea',
        info: 'Auto Sale Scheme',
      });
    if (row?.indicator_flags?.[0]?.batch_mismatch_indicator)
      signsArr.push({
        letter: 'BM',
        color: '#df0e0e',
        info: 'Batch Mismatch Item',
      });
    if (row?.indicator_flags?.[0]?.damage_indicator)
      signsArr.push({ letter: 'D', color: '#df0e0e', info: 'Damaged Item' });
    if (row?.indicator_flags?.[0]?.purchased_today)
      signsArr.push({ letter: 'PT', info: 'Item Purchased Today' });
    if (row?.indicator_flags?.[0]?.goods_return_indicator)
      signsArr.push({ letter: 'GR', info: 'Goods Return' });
    if (row?.indicator_flags?.[0]?.sample_indicator)
      signsArr.push({ letter: 'S', color: '#16c163', info: 'Sample Item' });
    return (
      <>
        {signsArr?.length > 0 ? (
          signsArr.map((sign) => (
            <Tooltip
              placement="top"
              title={<p className="tooltip">{sign.info}</p>}
              disableFocusListener
              disableTouchListener
              arrow
            >
              <span
                style={{
                  display: 'inline-flex',
                  backgroundColor: 'inherit',
                  marginLeft: '4px',
                  borderRadius: '4px',
                  border: `solid 1px ${sign.color ?? '#f56926'}`,
                  padding: '0px 3px',
                  color: `${sign.color ?? '#f56926'}`,
                  fontSize: '9px',
                  alignItems: 'center',
                }}
              >
                {sign?.letter}
              </span>
            </Tooltip>
          ))
        ) : (
          <></>
        )}
        {row?.indicator_flags?.[0]?.gift_item ? (
          <span style={{ marginLeft: '4px' }}>
            <Gift />
          </span>
        ) : (
          <></>
        )}
      </>
    );
  }

  function reverse(array) {
    var output = [];
    for (var i = array.length - 1; i > -1; i--) {
      output.push(array[i]);
    }

    return output;
  }

  return (
    <>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={() => {
          if (currentSelected != -1) rowClicked(rows[currentSelected]?.id);
        }}
      />
      <TableContainer className={classes.container} id="TableComponent">
        <Table
          stickyHeader
          size="small"
          aria-label="sticky table"
          className={classes.root}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) =>
                column?.id === 'select' ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    padding="checkbox"
                  >
                    <CustomCheckbox
                      checked={
                        rows?.length === checkedRows?.length && rows?.length > 0
                          ? true
                          : false
                      }
                      onChange={(event) => handleAllRowCheck(event)}
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.id}
                    align={column.align || 'left'}
                    style={{
                      minWidth: column.minWidth,
                      height: '10px',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(reverseOrder ? reverse(rows) : rows)?.map((row, rowIndex) => {
              let bg = '',
                bold = '';
              if (row?.is_special_bill === true) {
                bg = '#f7fed9';
                bold = '900';
              } else if (row?.is_cancelled == true) {
                bg = '#FF8484';
              }
              return (
                <ThemeProvider theme={theme}>
                  <TableRow
                    hover
                    className={classes.tableRow}
                    role="checkbox"
                    selected={row.id === selected}
                    style={{ backgroundColor: bg }}
                    ref={row.id === selected ? rowRef : null}
                    onClick={() => {
                      if (rowClickable) {
                        handleClick(row.id);
                        rowClicked(row.id);
                      }
                    }}
                    tabIndex={-1}
                    key={row?.id}
                  >
                    {columns?.map((column) => {
                      const value = row[column.id];
                      if (
                        tableName === 'prevBillsOrderItems' &&
                        column.id === 'available_qty'
                      ) {
                        const span_color =
                          row.sale_quantity + (row?.sale_free || 0) >
                            (row?.available_qty || 0)
                            ? '#e76b60'
                            : '#02aa7b';

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                display: 'block',
                                float: 'right',
                                borderRadius: '16px',
                                width: '48px',
                                backgroundColor: span_color,
                                textAlign: 'center',
                              }}
                            >
                              {row.available_qty}
                            </span>
                          </TableCell>
                        );
                      }
                      if (
                        (tableName === 'purchaseInvoiceItems' ||
                          tableName === 'transferInInvoiceItems') &&
                        column.id === 'available_qty'
                      ) {
                        const span_color =
                          row.purchase_quantity + (row?.purchase_free || 0) >
                            (row?.available_qty || 0)
                            ? '#e76b60'
                            : '#02aa7b';

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: '#fff', fontWeight: 'bold' }}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                display: 'block',
                                float: 'right',
                                borderRadius: '16px',
                                width: '48px',
                                backgroundColor: span_color,
                                textAlign: 'center',
                              }}
                            >
                              {row.available_qty}
                            </span>
                          </TableCell>
                        );
                      }
                      if (
                        tableName === 'saleInvoiceItems' &&
                        column.id === 'available_quantity'
                      ) {
                        const span_color =
                          row.sale_quantity + (row?.sale_free || 0) >
                            (row?.available_quantity || 0)
                            ? '#e76b60'
                            : '#02aa7b';

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: '#fff', fontWeight: 'bold' }}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                display: 'block',
                                float: 'right',
                                borderRadius: '16px',
                                width: '48px',
                                backgroundColor: span_color,
                                textAlign: 'center',
                              }}
                            >
                              {/* {row.available_quantity} */}
                              {value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'label') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{
                              height: '5px',
                              fontWeight: `${bold}`,
                              ...column?.cellStyle,
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                      if (column.type == 'boolean') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{ height: '5px', fontWeight: `${bold}` }}
                          >
                            {value == true ? 'Yes' : 'No'}
                          </TableCell>
                        );
                      }
                      if (column.type == 'cheque_status') {
                        let spanColor = '#fff';
                        switch (row?.status) {
                          case 0:
                            spanColor = '#a0e58f';
                            break;
                          case 1:
                            spanColor = '#b6bdcb';
                            break;
                          case 2:
                            spanColor = '#83bfff';
                            break;
                          case 3:
                            spanColor = '#ffce57';
                            break;
                          case 4:
                            spanColor = '#ffbcbc';
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{ height: '5px', fontWeight: `${bold}` }}
                          >
                            <span
                              style={{
                                padding: '2px 5px',
                                borderRadius: '8px',
                                border: `1.5px solid ${spanColor}`,
                              }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'crdb_status') {
                        let spanColor = '#fff';
                        switch (row?.status) {
                          case 0:
                            spanColor = '#a0e58f';
                            break;
                          case 1:
                            spanColor = '#b6bdcb';
                            break;
                          case 2:
                            spanColor = '#ffbcbc';
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{ height: '5px', fontWeight: `${bold}` }}
                          >
                            <span
                              style={{
                                padding: '2px 5px',
                                borderRadius: '8px',
                                border: `1.5px solid ${spanColor}`,
                              }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'coloredLabel') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{
                              height: '5px',
                              color: '#8b2fd7',
                              fontWeight: '500',
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                      if (column.type == 'margin') {
                        const bg_color =
                          parseFloat(value || 0) < 0 ? '#FFCAC5' : '#BEEBD2';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'center'}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                backgroundColor: `${bg_color}`,
                                borderRadius: '8px',
                                padding: '2px 5px',
                              }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'scheme') {
                        const bg_color =
                          row.scheme_modified == 0
                            ? '#f2f2f2'
                            : row.scheme_modified == 1
                              ? '#FFCAC5'
                              : '#BEEBD2';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            size="small"
                            style={{
                              height: '5px',
                              backgroundColor: `${bg_color}`,
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                      if (column.type == 'batch_scheme') {
                        const sch =
                          row.sale_scheme_quantity == '' ||
                            row.sale_scheme_quantity == 0 ||
                            row.sale_scheme_quantity == null
                            ? ''
                            : `${row.sale_scheme_quantity}+${row.sale_scheme_free}`;
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            size="small"
                          >
                            {sch}
                          </TableCell>
                        );
                      }
                      if (column.type == 'age') {
                        const start = Moment(row.entry_date, 'YYYY-MM-DD');
                        const end = Moment().startOf('day');
                        const range = Moment.duration(end.diff(start)).asDays();
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            size="small"
                          >
                            {range}
                          </TableCell>
                        );
                      }
                      if (column.type == 'image') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'center'}
                          >
                            <IconButton
                              style={{ padding: '2px 6px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                window.confirm(
                                  `Are you sure you wish to delete this item ?\n${row?.product_name ?? ''
                                  }`,
                                ) && deleteClick(row.id);
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </TableCell>
                        );
                      }
                      if (column.type == 'discontinue+print') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'center'}
                          >
                            <IconButton
                              style={{ padding: '2px 6px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (row?.status_name == 'Cancelled') {
                                  window.confirm(
                                    'Are you sure you wish to uncancel this item?',
                                  ) && deleteClick(row.id);
                                } else {
                                  window.confirm(
                                    'Are you sure you wish to cancel this item?',
                                  ) && deleteClick(row.id);
                                }
                              }}
                            >
                              <BlockIcon />
                            </IconButton>

                            <IconButton
                              style={{ padding: '2px 6px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                updateRowClicked(row);
                              }}
                            >
                              <PrintIcon />
                            </IconButton>
                          </TableCell>
                        );
                      }
                      if (column.type == 'cancel') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'center'}
                          >
                            {row?.bill_type == 'Sale' &&
                              row?.dispatch_status == false &&
                              row?.received_amount == 0 ? (
                              <IconButton
                                style={{ padding: '2px 6px' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.confirm(
                                    'Are you sure you wish to cancel this item?',
                                  ) && deleteClick(row.id);
                                }}
                              >
                                <BlockIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        );
                      }
                      if (column.type == 'checkbox') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Checkbox
                              checked={row.is_active}
                              size="small"
                              inputProps={{
                                'aria-label': 'checkbox with small size',
                              }}
                              color="primary"
                              style={{ margin: '-10px 0px -10px 10px' }}
                              onClick={() => {
                                const answer = window.confirm(
                                  `Are you sure you wish to change the Active status ?`,
                                );
                                if (answer) {
                                  const data = [findArrayElement(rows, row.id)];
                                  updateActiveStatus(data, !row.is_active);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      }
                      if (column.type == 'multiSelectCheckbox') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            padding="checkbox"
                          >
                            <CustomCheckbox
                              checked={isRowChecked(row.id)}
                              onChange={(event) => {
                                event.stopPropagation();
                                handleRowCheck(event, row.id);
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          </TableCell>
                        );
                      }
                      if (column.type == 'value') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleClick(row.id)}
                            style={{ fontWeight: `${bold}` }}
                          >
                            {value == undefined ? '' : numberFormat(value || 0)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'coloredValue') {
                        const bg_color =
                          row.selling_price_type == 1 ? '#FDE2BA' : '';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              backgroundColor: `${bg_color}`,
                              fontWeight: `${bold}`,
                            }}
                            onClick={() => handleClick(row.id)}
                          >
                            {numberFormat(value)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'selling_value') {
                        const price =
                          row.new_selling_price == ''
                            ? value
                            : row.new_selling_price;
                        const bg_color =
                          row.rate_modified == 0
                            ? '#f2f2f2'
                            : row.rate_modified == 1
                              ? '#FFCAC5'
                              : '#BEEBD2';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ backgroundColor: `${bg_color}` }}
                          >
                            {numberFormat(price)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'date') {
                        const date =
                          value === '' || value == null || value == 'null'
                            ? ''
                            : Moment(value).format('DD/MM/YYYY');
                        return (
                          <TableCell
                            style={{ fontWeight: `${bold}` }}
                            key={column.id}
                            align={column.align}
                          >
                            {date}
                          </TableCell>
                        );
                      }
                      if (column.type == 'dateTime') {
                        const date =
                          value === '' || value == null
                            ? ''
                            : Moment(value)
                              .utc()
                              .local()
                              .format('DD-MM-YYYY HH:mm');
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {date}
                          </TableCell>
                        );
                      }
                      if (column.type == 'salable') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value == true ? 'Yes' : 'No'}
                          </TableCell>
                        );
                      }
                      if (column.type == 'expiry') {
                        const date =
                          value == '' || value == null || value == 'null'
                            ? ''
                            : Moment(value).format('MM/YYYY');
                        const end = new Date();
                        const start = new Date(value);
                        const range =
                          (start - end) / (1000 * 60 * 60 * 24 * 30);
                        const bg_color = range <= 6 ? '#FFCAC5' : '#BEEBD2';

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                backgroundColor: `${bg_color}`,
                                borderRadius: '8px',
                                padding: '2px 5px',
                              }}
                            >
                              {date}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'inward_pending') {
                        const bg_color = value == 0 ? '#BEEBD2' : '#FFCAC5';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                backgroundColor: `${bg_color}`,
                                borderRadius: '8px',
                                padding: '2px 5px',
                                display: 'block',
                                width: '90%',
                              }}
                            >
                              {value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'indays') {
                        const days =
                          value == ''
                            ? ''
                            : Moment(value).diff(Moment(today), 'days');

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleClick(row.id)}
                          >
                            {days}
                          </TableCell>
                        );
                      }
                      if (column.type == 'age') {
                        const start = new Date(row.entry_date);
                        const end = new Date();
                        const range =
                          Math.abs(start - end) / (1000 * 60 * 60 * 24);
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {range.toFixed(0)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_selling_price') {
                        const fColor = row?.indicator_flags?.[0]
                          ?.selling_price_indicator
                          ? '#E16005'
                          : '';
                        const bgColor =
                          row?.selling_price_type == 1 ? '#FDE2BA' : '';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: fColor, backgroundColor: bgColor }}
                          >
                            {value == undefined ? '' : numberFormat(value)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_purchase_price') {
                        const fColor = row?.indicator_flags?.[0]
                          ?.purchase_price_indicator
                          ? '#E16005'
                          : '';
                        const bgColor = '';
                        // row?.selling_price_type == 1 ? '#FDE2BA' : '';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: fColor, backgroundColor: bgColor }}
                          >
                            {value == undefined ? '' : numberFormat(value)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_mrp') {
                        const fColor = row?.indicator_flags?.[0]?.mrp_indicator
                          ? '#E16005'
                          : '';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: fColor }}
                          >
                            {value == undefined ? '' : numberFormat(value)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_margin') {
                        const bg_color =
                          parseFloat(value || 0) < 0 ? '#FFCAC5' : '#BEEBD2';
                        const border = row?.indicator_flags?.[0]
                          ?.margin_indicator
                          ? 'solid 1.5px #E16005'
                          : '';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'center'}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                backgroundColor: `${bg_color}`,
                                borderRadius: '8px',
                                padding: `${border == '' ? '2' : '1'}px 5px`,
                                outline: `${border}`,
                              }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_product_name') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                              }}
                            >
                              {value}
                              {renderIndicators(row)}
                            </div>
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_uom_type') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <span
                              style={{
                                backgroundColor: 'inherit',
                                color: '#8b2fd7',
                                fontSize: '11px',
                                alignItems: 'center',
                                fontWeight: '500',
                              }}
                            >
                              {[
                                { value: 0, label: 'Loose' },
                                { value: 1, label: 'Strip' },
                              ]
                                .filter((e) => e.value === value)?.[0]
                                ?.label?.slice(0, 1) ?? 'L'}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_entry_number') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color:
                                row.post_to_accounts == false ? '#E16005' : '',
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_crdb_amount') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              fontWeight: value > 0 ? 600 : '',
                              color: value > 0 ? '#397bc8' : '',
                            }}
                          >
                            {value == undefined ? '' : numberFormat(value || 0)}
                          </TableCell>
                        );
                      }
                      if (column.type == 'indicated_quantity') {
                        let bg =
                          parseFloat(value || 0) > 0 ? '#02aa7b' : '#e76b60';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: '#fff', fontWeight: 'bold' }}
                            onClick={() => handleClick(row.id)}
                          >
                            <span
                              style={{
                                display: 'block',
                                float: 'right',
                                borderRadius: '16px',
                                width: '48px',
                                backgroundColor: bg,
                                textAlign: 'center',
                              }}
                            >
                              {value}
                            </span>
                          </TableCell>
                        );
                      }
                      if (column.type == 'serial_number') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value + 1}
                          </TableCell>
                        );
                      }
                      if (column.type == 'editable_cell') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ height: '5px' }}
                          >
                            <CssTextField
                              placeholder=""
                              value={value ?? ''}
                              autoFocus={
                                column?.autoFocus && rowIndex == 0
                                  ? true
                                  : false
                              }
                              variant="outlined"
                              margin="normal"
                              style={{ margin: 0 }}
                              inputProps={{
                                style: { ...column?.textFieldStyle },
                              }}
                              onChange={(e) =>
                                handleValueChange(
                                  e.target.value,
                                  row.id,
                                  column.id,
                                )
                              }
                            />
                          </TableCell>
                        );
                      }
                      if (column.type == 'link') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <p
                              style={{
                                margin: '0',
                                color: '#0060d0',
                                fontWeight: '400',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                linkTextClicked(row);
                              }}
                            >
                              {column?.linkText ?? value ?? ''}
                            </p>
                          </TableCell>
                        );
                      }
                      if (column.type == 'info_icon') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              className={classes.informationIconContainer}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                infoIconClicked(row);
                              }}
                            >
                              <InformationFilled />
                            </IconButton>
                          </TableCell>
                        );
                      }
                      if (column.type == 'primary_button') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <PrimaryButton
                              title={column?.buttonText}
                              buttonSize="mini"
                              executeClick={() => {
                                primaryButtonClicked(row);
                              }}
                              disabled={row?.is_generated}
                            />
                          </TableCell>
                        );
                      }
                      if (column.type == 'view') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Grid
                              container
                              style={{
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                justifyContent:
                                  column.align == 'right'
                                    ? 'flex-end'
                                    : 'center',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#d2e6ff',
                                  margin: '2px 12px 2px 0',
                                  padding: '4px 6px',
                                  borderRadius: '6px',
                                  cursor: 'pointer',
                                }}
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  rowViewed(row);
                                }}
                              >
                                <VisibilityOutlinedIcon
                                  style={{
                                    fontSize: '15px',
                                    color: '#0053B4',
                                  }}
                                />
                              </span>
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (column.type == 'edit+delete') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Grid container justifyContent="center">
                              <Grid item md={6}>
                                <IconButton
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    window.confirm(
                                      `Are you sure you wish to delete - ${row?.entry_number ?? row?.label
                                      }?`,
                                    ) && deleteClick(row?.id);
                                  }}
                                  style={{ padding: '6px' }}
                                >
                                  <DeleteOutlined
                                    style={{
                                      fontSize: '15px',
                                      color: '#222222',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid item md={6}>
                                <IconButton
                                  onClick={(ev) => {
                                    updateRowSelected(row);
                                  }}
                                  style={{ padding: '6px' }}
                                >
                                  <EditOutlinedIcon
                                    style={{
                                      fontSize: '15px',
                                      color: '#222222',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (column.type == 'orderActions') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Grid
                              container
                              style={{
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                justifyContent: 'flex-end',
                                paddingRight: '4px',
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: '#d2e6ff',
                                  margin: '2px 12px 2px 0',
                                  padding: '4px 6px',
                                  borderRadius: '6px',
                                  cursor: 'pointer',
                                }}
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  rowViewed(row);
                                }}
                              >
                                <VisibilityOutlinedIcon
                                  style={{
                                    fontSize: '15px',
                                    color: '#0053B4',
                                  }}
                                />
                              </span>
                              <ColorButton
                                title="Approve"
                                customHeight="24px"
                                customWidth="67px"
                                buttonColor="#2981e9"
                                buttonColorHover="#0c5ebf"
                                customStyle={{ fontSize: '11px' }}
                                executeClick={() => {
                                  updateRowSelected(row, 'approve');
                                }}
                              />
                              <ColorButton
                                title="Reject"
                                customHeight="24px"
                                customWidth="67px"
                                buttonColor="#e75252"
                                buttonColorHover="#db3030"
                                customMargin="0 0 0 6px"
                                customStyle={{ fontSize: '11px' }}
                                executeClick={() => {
                                  updateRowSelected(row, 'reject');
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (column.type == 'sno') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align || 'left'}
                            size="small"
                            style={{ height: '5px', fontWeight: `${bold}` }}
                          >
                            {rowIndex + 1}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ height: '5px' }}
                        >
                          <TextField
                            className={classes.textfield}
                            autofocus
                            id="standard-basic"
                            onChange={(e) =>
                              handleValueChange(
                                e.target.value,
                                row.id,
                                column.id,
                              )
                            }
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </ThemeProvider>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

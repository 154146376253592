import React, { useState, useEffect, useRef } from 'react';

// // import useStyles from 'isomorphic-style-loader/useStyles';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';;
import {
  Dialog,
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
} from '@mui/material';
import './sp-styles.css'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DateFnsUtils from '@date-io/date-fns';
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogTitle, DialogContent } from 'src/components/Modal/Dialog';
import Moment from 'moment';
import { financialDateRange } from 'src/constants/financialDateRange';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import Select from 'react-select';
import apiRequest from 'src/utils/api';
import { useDispatch, useSelector } from 'react-redux';
// import { updateEntryStore, resetEntryStore } from 'actions/entry';
// import {
//   updateEntryStateStore,
//   resetEntryStateStore,
// } from 'actions/entrystate';
import { useNavigate } from "react-router-dom";

import { numberFormat } from 'src/utils/number';
import IconOnlyButton from 'src/components/Buttons/IconOnlyButton';
import { SendEmail } from 'src/components/icons/customIcons';
import SupplierSearchTable from 'src/components/Table/SupplierSearchTable';
import CompanyTable from 'src/components/Table/CompanyTable';
import CompanyGroupTable from 'src/components/Table/CompanyGroupTable';
import SearchTable from 'src/components/Table/SearchTable';
import ProductTable from 'src/components/Table/ProductTable';
import AreaSearch from 'src/components/Search/AreaSearch.js';
import CustomerSearch from 'src/components/Search/CustomerSearch.js';
import SupplierSearch from 'src/components/Search/SupplierSearch.js';
import TransportSearch from 'src/components/Search/TransportSearch.js';
import BranchSearch from 'src/components/Search/BranchSearch.js';
import RepTable from 'src/components/Table/RepTable.js';
import CategoryTable from 'src/components/Table/CategoryTable';
import PatientTable from 'src/components/Table/PatientTable';
import UserTable from 'src/components/Table/UserTable';
import LedgerTable from 'src/components/Table/LedgerTable';
import TransportTable from 'src/components/Table/TransportTable';
import DiseaseCategoryTable from 'src/components/Table/DiseaseCategoryTable';
import Modal from 'src/components/Modal/Modal';
import PrintPreview from 'src/components/ReportPDF/index.js';
import Blob from './emailBlob';
import Output from './Output';
import s from './Reports.module.css';
// import type { RootState } from "src/store/store";

import { COLORS } from 'src/constants/colors';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -6px) scale(0.8)',
    },
  },
})(TextField);

const CSSKeyboardDatePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '100%',
      borderRadius: '6px',
      fontSize: '12px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-input': {
      height: '100%',
      padding: '7px 8px',
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A5ADB0',
      borderRadius: '6px',
    },
    '& .MuiInputAdornment-root .MuiIconButton-root': {
      padding: '0',
    },
  },
})(TextField);

export default function Reports() {


  // useStyles(s);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedReport = useSelector(
    (state) => state.report?.selectedReport
  );

  const dmsReportSettings = useSelector(
    (state) => state.report?.reportSettings
  );

  useEffect(() => {
    if (!selectedReport?.filters) {
      navigate('/login')
    }
  }, [selectedReport]);
  // if (!selectedReport?.filters) {
  //   return <></>
  // }

  const {
    filters,
    title,
    print_orientation,
    split_by,
    api_endpoint,
    report_view,
    date_range,
    summary,
    settings,
  } = selectedReport || {};

  // if (!filters) {
  //   return <></>
  // }
  const selectedBranch = useSelector((state) => state.branch?.selectedBranch);
  const currentUser = useSelector((state) => state.user);

  const entryState = useSelector((state) => state.entrystate);



  const [timestamp, setTimeStamp] = useState('');
  const [rows, setRows] = useState([]);
  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [from, setFrom] = useState('');
  const [minFrom, setMinFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState('');
  const [input, setInput] = useState({});
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [createBlob, setCreateBlob] = useState(false);
  const [emailDetails, setEmailDetails] = useState({});
  const [emailOptionsOpen, setEmailOptionsOpen] = useState(false);
  const [orientation, setOrientation] = useState(print_orientation);
  const [reportSummary, setReportSummary] = useState({});
  const [reportSettings, setReportSettings] = useState({});
  const [pdfSize, setPdfSize] = useState('');

  const [companyOpen, setCompanyOpen] = useState(false);
  const [companyRows, setCompanyRows] = useState([]);
  const [company, setCompany] = useState({
    id: '',
    code: '',
    name: '',
    email: '',
  });
  const [companyGroupOpen, setCompanyGroupOpen] = useState(false);
  const [companyGroupRows, setCompanyGroupRows] = useState([]);
  const [companyGroup, setCompanyGroup] = useState({
    id: '',
    code: '',
    name: '',
    email: '',
  });
  const [supplierRows, setSupplierRows] = useState([]);
  const [supplierOpen, setSupplierOpen] = useState(false);
  const [supplier, setSupplier] = useState({
    id: '',
    code: '',
    name: '',
    email: '',
  });
  const [productOpen, setProductOpen] = useState(false);
  const [productRows, setProductRows] = useState([]);
  const [product, setProduct] = useState({
    id: null,
    code: '',
    name: '',
    packing: '',
  });
  const [customerOpen, setCustomerOpen] = useState(false);
  const [customer, setCustomer] = useState({
    id: 0,
    code: '',
    name: '',
    email: '',
  });

  const [customerCategoryRows, setCustomerCategoryRows] = useState([]);
  const [customerCategoryOpen, setCustomerCategoryOpen] = useState(false);
  const [customerCategory, setCustomerCategory] = useState({
    id: null,
    code: '',
    name: '',
  });

  const [area, setArea] = useState({
    id: null,
    name: '',
  });

  const [repOpen, setRepOpen] = useState(false);
  const [repRows, setRepRows] = useState([]);
  const [rep, setRep] = useState({
    id: null,
    name: '',
    email: '',
  });
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categoryRows, setCategoryRows] = useState([]);
  const [category, setCategory] = useState({
    id: null,
    name: '',
  });

  const [patientOpen, setPatientOpen] = useState(false);
  const [patientRows, setPatientRows] = useState([]);
  const [patient, setPatient] = useState({
    id: null,
    name: '',
  });

  const [userOpen, setUserOpen] = useState(false);
  const [userRows, setUserRows] = useState([]);
  const [user, setUser] = useState({
    id: null,
    name: '',
  });

  const [ledgerOpen, setLedgerOpen] = useState(false);
  const [ledgerRows, setLedgerRows] = useState([]);
  const [ledger, setLedger] = useState({
    id: null,
    name: '',
  });
  const [transportOpen, setTransportOpen] = useState(false);
  const [transportRows, setTransportRows] = useState([]);
  const [transport, setTransport] = useState({
    id: null,
    code: '',
    name: '',
  });
  const [diseaseOpen, setDiseaseOpen] = useState(false);
  const [diseaseRows, setDiseaseRows] = useState([]);
  const [disease, setDisease] = useState({
    id: null,
    code: '',
    name: '',
  });
  const [branch, setBranch] = useState({
    id: null,
    name: '',
  });

  const [pdfOpen, setPdfOpen] = useState(false);
  const [printColumns, setPrintColumns] = useState([]);
  const [printRows, setPrintRows] = useState([]);

  console.log('daterange 1',date_range,reportSettings)


  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };
  const [fromRef, setFromFocus] = UseFocus();
  const [toRef, setToFocus] = UseFocus();

  let fyBegin;
  let fyEnd;
  const today = Moment().format('YYYY-MM-DD');
  const time = Moment().format('HH:mm:ss');
  [fyBegin, fyEnd] = financialDateRange(today);

  React.useEffect(() => {
    clearFilters();
  }, [title]);

  React.useEffect(() => {
    const dateRange = date_range;
    console.log('dateRange', dateRange)
    if (!dateRange) {
      setMinFrom(moment().startOf('month').format('YYYY-MM-DD'));
    }

    if (dateRange == 1) {
      setMinFrom(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    }
    if (dateRange == 2) {
      setMinFrom(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
    }
    if (dateRange == 3) {
      setMinFrom(moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'));
    }
    if (dateRange == 6) {
      setMinFrom(moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'));
    }

    if (dateRange == 12) {
      if (moment().month() > 2) {
        setMinFrom(moment().month(3).startOf('month').format('YYYY-MM-DD'));
      } else {
        setMinFrom(moment().subtract(12, 'months').month(3).startOf('month').format('YYYY-MM-DD'));

      }
    }


  }, [date_range]);

  // React.useEffect(() => {
  //   console.log('minFrom date===', minFrom)
  // }, [minFrom])


  // React.userEffect(() => { }, [selectedReport])

  React.useEffect(() => {
    if (pdfSize != '') setPdfOpen(true);
  }, [pdfSize]);

  useEffect(() => {
    if (filters?.length > 0) {
      // SET DEFAULT FOR RADIO BUTTONS
      let radio_filter1 = filters?.filter(
        (e) => e.type == 'radio_button_group1',
      );
      let radio_filter2 = filters?.filter(
        (e) => e.type == 'radio_button_group2',
      );
      setInput({
        ...input,
        radio_button_group1:
          radio_filter1?.length > 0
            ? radio_filter1?.[0]?.values?.split(',')?.[0] ?? ''
            : '',
        radio_button_group2:
          radio_filter2?.length > 0
            ? radio_filter2?.[0]?.values?.split(',')?.[0] ?? ''
            : '',
      });

      // SET DATE FIELD DEFAULTS
      if (filters?.filter((filter) => filter.type == 'from')?.length > 0)
        setFrom(fetchDefaultDate('from'));
      if (filters?.filter((filter) => filter.type == 'to')?.length > 0)
        setTo(fetchDefaultDate('to'));
      if (filters?.filter((filter) => filter.type == 'as_on')?.length > 0)
        setTo(fetchDefaultDate('as_on'));
    }
  }, [filters]);

  React.useEffect(() => {
    if (Object.keys(emailDetails)?.length > 0) setCreateBlob(true);
  }, [emailDetails]);

  React.useEffect(() => {
    setReportSummary(summary);
  }, [summary]);
  React.useEffect(() => {
    setReportSettings(settings);
  }, [settings]);

  const handleLoadingOpen = () => {
    setLoadingOpen(true);
  };
  const handleLoadingClose = () => {
    setLoadingOpen(false);
  };

  function clearFilters() {
    setTimeStamp(`${Moment(today).format('DD-MMM-YYYY')} ${time}`);
    setRows([]);
    setColumns([]);
    setFrom(today);
    setTo(today);
    setInput({});
    setReportSummary({});
    setCompany({
      id: '',
      code: '',
      name: '',
      email: '',
    });
    setCompanyGroup({
      id: '',
      code: '',
      name: '',
      email: '',
    });
    setSupplier({
      id: '',
      code: '',
      name: '',
      email: '',
    });
    setProduct({
      id: null,
      code: '',
      name: '',
      packing: '',
    });
    setCustomer({
      id: 0,
      code: '',
      name: '',
      email: '',
    });
    setArea({
      id: null,
      name: '',
    });
    setRep({
      id: null,
      name: '',
      email: '',
    });
    setPatient({
      id: null,
      name: '',
    });
  }

  function fetchDefaultDate(type) {
    let filter = filters?.filter((filter) => filter.type == type)?.[0];
    let default_date = today;
    switch (filter?.default) {
      case 'Start of month':
        default_date = moment(today)?.format('YYYY-MM')?.concat('-01');
        break;
      case 'Start of FY':
        default_date = fyBegin;
        break;
      case 'End of FY':
        default_date = fyEnd;
        break;
      default:
        break;
    }
    return default_date;
  }

  const apiRequest = async (url, body, method) => {
    const branchHeader = {
      branch_id: selectedBranch?.branch_id,
      client_id: selectedBranch?.client_id,
      rep_id: selectedBranch?.id,
      phone: selectedBranch?.phone

    }
    // console.log('branchHeader', branchHeader, btoa(JSON.stringify(branchHeader)))
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        "sp360-header": btoa(JSON.stringify(branchHeader)),
        'Authorization': `Bearer ${currentUser?.access_token}`,

      },
    };
    if (method != 'GET') {
      options.body = JSON.stringify(body);
    }


    const data = await fetch(`${process.env.REACT_APP_SP360_URL}${url}`, options);
    return await data.json();

  }

  ////// Company ///////////////////
  async function getCompany(query) {
    try {
      setCompanyRows([]);
      const body = {
        id: selectedBranch?.company_id?.map((row) => row?.id)
      }
      const response = await apiRequest(
        `api/company_list?search=${query}`,
        body,
        'POST',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {

        setCompanyRows(response.results);
        handleCompanyOpen();
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleCompanyOpen = () => {
    setCompanyOpen(true);
  };
  const handleCompanyClose = () => {
    setCompanyOpen(false);
  };
  function companySelected(data) {
    setCompany({
      id: data[0].id,
      code: data[0].code,
      name: data[0].name,
      email: data[0].email ?? '',
    });
    handleCompanyClose();
  }

  ////// Company Group ///////////////////
  async function getCompanyGroup(query) {
    try {
      setCompanyGroupRows([]);
      const response = await apiRequest(
        `api/division_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setCompanyGroupRows(response.results);
        handleCompanyGroupOpen();
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function getCompanyDivision(query) {
    try {
      if (companyGroup?.id) {
        const response = await apiRequest(
          `api/company_list?division_id=${companyGroup?.id}&search=${query}`,
          null,
          'GET',
        );
        if (response.results.length == 0) {
          window.confirm(`No Results found !`);
        } else {
          setCompanyRows([]);
          setCompanyRows(response.results);
          handleCompanyOpen();
        }
      } else {
        alert('Please select company before proceeding to select division  !');
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleCompanyGroupOpen = () => {
    setCompanyGroupOpen(true);
  };
  const handleCompanyGroupClose = () => {
    setCompanyGroupOpen(false);
  };
  function companyGroupSelected(data) {
    setCompanyGroup({
      id: data[0].id,
      code: data[0].code,
      name: data[0].name,
      email: data[0]?.email ?? '',
    });

    handleCompanyGroupClose();
  }

  ////// Supplier ///////////////////
  function supplierSelected(rowsData, mode = 'single') {
    if (mode == 'multi') {
      let ids = (rowsData ?? []).map((row) => row?.id);
      if (rowsData?.length > 0) {
        setSupplier({
          ...supplier,
          id: ids,
          name: rowsData[0]?.label,
          email: rowsData
            ?.filter((row) => (row?.email ?? '') !== '')
            ?.map(({ email }) => email)
            ?.join(','),
        });
      } else {
        setSupplier({ ...supplier, id: '', name: '' });
      }
    } else {
      setSupplier({
        ...supplier,
        id: rowsData[0]?.id,
        code: rowsData[0]?.code,
        name: rowsData[0]?.name,
        email: rowsData[0]?.email ?? '',
      });
    }
    setSupplierOpen(false);
  }

  ////// Customer ///////////////////
  function customerSelected(rowsData, mode = 'single') {
    if (mode == 'multi') {
      let ids = (rowsData ?? []).map((row) => row?.id);
      if (rowsData?.length > 0) {
        setCustomer({
          ...customer,
          id: ids,
          name: rowsData[0].label,
          email: rowsData
            ?.filter((row) => (row?.email ?? '') !== '')
            ?.map(({ email }) => email)
            ?.join(','),
        });
      } else {
        setCustomer({ ...customer, id: '', name: '' });
      }
    } else {
      setCustomer({
        ...customer,
        id: rowsData[0]?.id,
        code: rowsData[0]?.code,
        name: rowsData[0]?.name,
        email: rowsData[0]?.email ?? '',
      });
    }
    setCustomerOpen(false);
  }

  ////// Customer Category ///////////////////
  async function getCustomerCategory(query) {
    try {
      setCustomerCategoryRows([]);
      const response = await apiRequest(
        `api/customer_category_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setCustomerCategoryRows(response.results);
        setCustomerCategoryOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function customerCategorySelected(data) {
    setCustomerCategory({
      id: data[0].id,
      code: data[0].code,
      name: data[0].name,
    });
    setCustomerCategoryOpen(false);
  }

  ////// Products ///////////////////
  async function searchProduct(query) {
    handleLoadingOpen();
    try {
      const response = await apiRequest(
        `api/products_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setProductRows([]);
        setProductRows(response.results);
        handleProductOpen();
      }
      handleLoadingClose();
    } catch (error) {
      console.error(error);
      handleLoadingClose();
    }
  }
  const handleProductOpen = () => {
    setProductOpen(true);
  };
  const handleProductClose = () => {
    setProductOpen(false);
  };
  function productSelected(data) {
    setProduct({
      id: data[0].id,
      code: data[0].code,
      name: data[0].name,
      packing: data[0].packing,
    });
    handleProductClose();
  }

  ////// Patients ///////////////////
  async function getPatient(query) {
    try {
      setPatientRows([]);
      const response = await apiRequest(
        `api/patient_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setPatientRows(response.results);
        handlePatientOpen();
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handlePatientOpen = () => {
    setPatientOpen(true);
  };
  const handlePatientClose = () => {
    setPatientOpen(false);
  };
  function patientSelected(data) {
    setPatient({
      ...patient,
      id: data[0].id,
      name: data[0].name,
    });
    handlePatientClose();
  }

  ////// User ///////////////////
  async function getUser(query) {
    try {
      setUserRows([]);
      const response = await apiRequest(
        `api/accounts/users_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setUserRows(response.results);
        handleUserOpen();
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleUserOpen = () => {
    setUserOpen(true);
  };
  const handleUserClose = () => {
    setUserOpen(false);
  };
  function userSelected(data) {
    setUser({
      ...user,
      id: data[0].id,
      name: data[0].name,
    });
    handleUserClose();
  }

  ////// Ledger ///////////////////
  async function getLedger(query) {
    try {
      setLedgerRows([]);
      const response = await apiRequest(
        `api/ledger_list/all?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setLedgerRows(response.results);
        handleLedgerOpen();
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleLedgerOpen = () => {
    setLedgerOpen(true);
  };
  const handleLedgerClose = () => {
    setLedgerOpen(false);
  };
  function ledgerSelected(data) {
    setLedger({
      ...ledger,
      id: data[0].id,
      name: data[0].name,
    });
    handleLedgerClose();
  }

  ////// Transport ///////////////////
  const handleTransportOpen = () => {
    setTransportOpen(true);
  };
  const handleTransportClose = () => {
    setTransportOpen(false);
  };
  function transportSelected(rowsData, mode = 'single') {
    if (mode == 'multi') {
      let ids = (rowsData ?? []).map((row) => row?.id);
      if (rowsData?.length > 0) {
        setTransport({
          ...transport,
          id: ids,
          name: rowsData[0]?.label || '',
        });
      } else {
        setTransport({ ...transport, id: '', name: '' });
      }
    } else {
      setTransport({
        ...transport,
        id: rowsData[0].id,
        name: rowsData[0].name,
      });
    }
  }

  ////// Rep ///////////////////
  async function searchRep(query) {
    try {
      const response = await apiRequest(
        `api/rep_list?search=${query}`,
        null,
        'GET',
      );
      setRepRows([]);
      if (response?.results.length == 0) {
        alert(`No Results for ${query}`);
      } else {
        setRepRows(response?.results);
        setRepOpen(true);
      }
    } catch (error) {
      alert(`Please try again \n${error.message}`);
    }
  }
  function areaSelected(rowsData, mode = 'single') {
    if (mode == 'multi') {
      let ids = (rowsData ?? []).map((row) => row?.id);
      if (rowsData?.length > 0) {
        setArea({
          ...area,
          id: ids,
          name: rowsData[0]?.label || '',
        });
      } else {
        setArea({ ...area, id: '', name: '' });
      }
    } else {
      setArea({
        ...area,
        id: rowsData[0].id,
        name: rowsData[0].name,
      });
    }
  }
  function repSelected(rowData) {
    setRep({
      ...rep,
      id: rowData[0].id,
      name: rowData[0].name,
      email: rowData[0]?.email ?? '',
    });
    setRepOpen(false);
  }

  ////// Category ///////////////////
  async function getCategory(query) {
    try {
      setCategoryRows([]);
      const response = await apiRequest(
        `api/category_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setCategoryRows(response.results);
        setCategoryOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function categorySelected(rowData) {
    setCategory({
      ...category,
      id: rowData[0].id,
      name: rowData[0].name,
    });
    setCategoryOpen(false);
  }

  ////// Branch ///////////////////
  function branchSelected(rowsData, mode = 'single') {
    if (mode == 'multi') {
      let ids = (rowsData ?? []).map((row) => row?.id);
      if (rowsData?.length > 0) {
        setBranch({
          ...branch,
          id: ids,
          name: rowsData[0]?.label || '',
        });
      } else {
        setBranch({ ...branch, id: '', name: '' });
      }
    } else {
      setBranch({
        ...branch,
        id: rowsData[0].id,
        name: rowsData[0].name,
      });
    }
  }

  ////// Disease ///////////////////
  async function searchDisease(query) {
    try {
      setDiseaseRows([]);
      const response = await apiRequest(
        `api/disease_category_list?search=${query}`,
        null,
        'GET',
      );
      if (response.results.length == 0) {
        window.confirm(`No Results found !`);
      } else {
        setDiseaseRows(response.results);
        setDiseaseOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleDiseaseOpen = () => {
    setDiseaseOpen(true);
  };
  const handleDiseaseClose = () => {
    setDiseaseOpen(false);
  };
  function diseaseSelected(rowData) {
    setDisease({
      ...disease,
      id: rowData[0].id,
      name: rowData[0].name,
    });
    setDiseaseOpen(false);
  }

  const repModalContent = {
    body: <RepTable rows={repRows} rowSelected={repSelected} />,
  };

  const categoryModalContent = {
    body: <CategoryTable rows={categoryRows} rowSelected={categorySelected} />,
  };

  async function getReport() {
    try {
      if (!company.id || company.id == '') {
        alert('Company selection is mandatory');
        return;

      }

      if (moment(from).isBefore(minFrom)) {
        alert(`From date should be greater than or equal to ${moment(minFrom).format('DD-MM-YYYY')}`);
        return;
      }
      if (checkMandatoryFields() && company.id && company.id != '') {
        handleLoadingOpen();
        setRows([]);

        const windowUrl = window.location.search;
        let report_id = windowUrl.replace('?', '');
        let body = {
          from: from,
          to: to,
          as_on: to,
          company_id: company.id ?? '',
          companygroup_id: companyGroup.id ?? '',
          supplier_id: supplier.id ?? '',
          product_id: product.id ?? '',
          customer_id: customer.id ?? '',
          customer_category_id: customerCategory.id ?? '',
          area_id: area.id ?? '',
          rep_id: rep.id ?? '',
          transport_id: transport?.id ?? '',
          category_id: category?.id ?? '',
          patient_id: patient?.id ?? '',
          user_id: user?.id ?? '',
          ledger_id: ledger?.id ?? '',
          disease_id: disease?.id ?? '',
          branch_id: selectedBranch?.branch_id ?? '',
          input1: input?.input1 ?? '',
          input2: input?.input2 ?? '',
          input3: input?.input3 ?? '',
          input4: input?.input4 ?? '',
          string_input1: input?.string_input1 ?? '',
          string_input2: input?.string_input2 ?? '',
          checkbox1: input?.checkbox1 ?? false,
          checkbox2: input?.checkbox2 ?? false,
          checkbox3: input?.checkbox3 ?? false,
          radio_button_group1: input?.radio_button_group1 ?? null,
          radio_button_group2: input?.radio_button_group2 ?? null,
          dropdown1: input?.dropdown1 ?? null,
          split_by: split_by,
          id: selectedReport?.id,
        };

        //Remove blank attributes from an body(Object)
        Object.keys(body).forEach(
          (k) =>
            (body[k] === undefined || body[k] === null || body[k] === '') &&
            delete body[k],
        );

        body.cid = 0;
        body.cgid = 0;


        const response = await apiRequest(`api/generate_query_report`, body, "POST");


        const {
          columns,
          dataRows,
          printColumns: pc,
          printRows: pr,
          summary,
        } = response?.results; // pc & pr - alias

        if (response?.results?.dataRows?.length == 0) {
          alert(`No Results found !`);
        } else {
          setColumns(columns);
          setRows([...dataRows]);
          setPrintColumns(pc);
          setPrintRows(pr);
          setReportSummary(summary);
        }
        handleLoadingClose();
      } else {
        alert('Please apply mandatory filters (*)');
      }
    } catch (error) {
      handleLoadingClose();
      // console.log('error', error)
      alert(error.message);
    }
  }

  function handleReportTitle() {
    if (
      filters?.filter((filter) => filter.type == 'from')?.length > 0 &&
      filters?.filter((filter) => filter.type == 'to')?.length > 0
    )
      return `${title}\n( ${Moment(from).format('DD-MM-YYYY')}  to  ${Moment(
        to,
      ).format('DD-MM-YYYY')} )`;
    else if (filters?.filter((filter) => filter.type == 'as_on')?.length > 0)
      return `${title}\n(  As on ${Moment(to).format('DD-MM-YYYY')}  )`;
    else return `${title}`;
  }

  function handleReportSubTitle() {
    if (
      filters?.filter((filter) => filter.type == 'from')?.length > 0 &&
      filters?.filter((filter) => filter.type == 'to')?.length > 0
    )
      return `From  ${Moment(from).format('DD-MM-YYYY')}  To  ${Moment(
        to,
      ).format('DD-MM-YYYY')}`;
    else if (filters?.filter((filter) => filter.type == 'as_on')?.length > 0)
      return `As on  ${Moment(to).format('DD-MM-YYYY')}`;
    else return `REPORT`;
  }

  function checkboxToggle(event, id) {
    let selected_rows_clone = [...checkboxSelectedRows];
    let index = selected_rows_clone.findIndex((row_id) => row_id == id);
    if (event.target.checked == true) {
      if (index == -1) {
        let temp_rows = [...checkboxSelectedRows];
        temp_rows.push(id);
        setCheckboxSelectedRows(temp_rows);
      }
    } else if (event.target.checked == false) {
      let temp_rows = [...checkboxSelectedRows];
      temp_rows.splice(index, 1);
      setCheckboxSelectedRows(temp_rows);
    }
  }

  function allCheckboxToggle(event, rows) {
    if (event.target.checked == true) {
      let selected_rows_clone = rows?.map((row) => row?.id);
      setCheckboxSelectedRows(selected_rows_clone);
    } else if (event.target.checked == false) {
      setCheckboxSelectedRows([]);
    }
  }

  function checkMandatoryFields() {
    let check = true;
    filters
      ?.filter((f) => f?.mandatory == true)
      ?.forEach((filter) => {
        switch (filter?.type) {
          case 'product':
            if ((product?.id ?? '') == '') check = false;
            break;
          case 'company':
            if ((company?.id ?? '') == '') check = false;
            break;
          case 'company_group':
            if ((companyGroup?.id ?? '') == '') check = false;
            break;
          case 'supplier':
            if ((supplier?.id ?? '') == '') check = false;
            break;
          case 'customer':
            if ((customer?.id ?? '') == '') check = false;
            break;
          case 'area':
            if ((area?.id ?? '') == '') check = false;
            break;
          case 'rep':
            if ((rep?.id ?? '') == '') check = false;
            break;
          default:
            break;
        }
      });
    return check;
  }

  async function sendMail() {
    setLoadingOpen(true);
    try {
      let body = rows?.filter((row) => checkboxSelectedRows?.includes(row?.id));
      let response;
      if (window.location.href.includes('bulk_stock_email')) {
        response = await apiRequest(
          `api/get_stock_list?company_id=${company?.id}`,
          null,
          'GET',
        );
      } else {
        if (checkboxSelectedRows?.length > 0) {
          response = await apiRequest(
            `api/sendChequeRemainderEmailForSelectedCustomers/`,
            body,
            'POST',
          );
        }
      }
      if (response) {
        setCheckboxSelectedRows([]);
      }
    } catch (error) {
      alert(error.message);
    }
    setLoadingOpen(false);
  }

  const renderFilter = (filter) => {
    if (filter.type == '') {
      return <Grid item md={filter.gridItem} />;
    }
    if (filter.type == 'from') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="From :"
            className={s.margin}
            variant="outlined"
            value={from}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={fromRef}
            inputProps={{
              min: minFrom,
            }}


            onChange={(event) => {
              setFrom(event.target.value);
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {

              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'to') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="To :"
            className={s.margin}
            variant="outlined"
            value={to}
            onChange={(event) => {
              setTo(event.target.value);
            }}
            type="date"
            inputRef={toRef}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'as_on') {
      if (filter.format == 'YYYY-MM') {
        return (
          <Grid item md={filter.gridItem}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CSSKeyboardDatePicker
                className={s.margin}
                style={{ height: '100%' }}
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/yy"
                margin="normal"
                label="Expiry"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { padding: '8px 10px' } }}
                value={moment(to).format('YYYY-MM')}
                onChange={(date) => {
                  setTo(
                    date == 'Invalid Date'
                      ? ''
                      : moment(date).format('YYYY-MM'),
                  );
                }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        );
      }
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            className={s.margin}
            label="As on :"
            variant="outlined"
            value={to}
            onChange={(event) => {
              setTo(event.target.value);
            }}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'company') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Company"
            required={true}
            className={s.margin}
            variant="outlined"
            size="small"
            value={company.name || ''}
            InputProps={{
              endAdornment: <SearchIcon onClick={() => { getCompany(company?.name) }} fontSize="small" />,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setCompany({
                ...company,
                id: '',
                name: event.target.value,
                code: '',
                email: '',
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                getCompany(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'company_group') {
      return <Grid item md={filter.gridItem}> </Grid>;

      // return (
      //   <Grid item md={filter.gridItem}>
      //     <CssTextField
      //       label="Company Group"
      //       required={filter?.mandatory || false}
      //       className={s.margin}
      //       variant="outlined"
      //       size="small"
      //       value={companyGroup.name || ''}
      //       InputProps={{
      //         endAdornment: <SearchIcon fontSize="small" />,
      //       }}
      //       InputLabelProps={{
      //         shrink: true,
      //       }}
      //       onChange={(event) => {
      //         setCompanyGroup({
      //           ...companyGroup,
      //           id: '',
      //           name: event.target.value,
      //           code: '',
      //           email: '',
      //         });
      //       }}
      //       onKeyPress={(ev) => {
      //         if (ev.key === 'Enter') {
      //           getCompanyGroup(ev.target.value);
      //         }
      //       }}
      //     />
      //   </Grid>
      // );
    }
    if (filter.type == 'supplier') {
      return (
        <Grid item md={filter.gridItem}>
          <SupplierSearch
            text={supplier?.name || ''}
            supplierOpen={supplierOpen}
            setSupplierOpen={setSupplierOpen}
            supplierSelected={(data) => supplierSelected(data)}
            supplierMultiSelected={(data) => supplierSelected(data, 'multi')}
            clearMultiSelect={() => {
              setSupplier({
                id: null,
                name: '',
              });
            }}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
              style: 'reports',
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'product') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Product"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={product.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" onClick={() => { searchProduct(product?.name) }} />,
            }}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              setProduct({
                id: '',
                name: event.target.value,
                code: '',
                packing: '',
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                searchProduct(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'packing') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Packing"
            className={s.margin}
            variant="outlined"
            size="small"
            value={product.packing || ''}
            inputProps={{
              readOnly: true,
              disabled: false,
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'customer') {
      return (
        <Grid item md={filter.gridItem}>
          <CustomerSearch
            text={customer?.name || ''}
            customerOpen={customerOpen}
            setCustomerOpen={setCustomerOpen}
            customerSelected={(data) => customerSelected(data)}
            selectedBranch={selectedBranch}
            currentUser={currentUser}
            customerMultiSelected={(data) => customerSelected(data, 'multi')}
            clearMultiSelect={() => {
              setCustomer({
                id: null,
                name: '',
              });
            }}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
              style: 'reports',
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'customer_category') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Cust. Category"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={customerCategory.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setCustomerCategory({
                ...customer,
                id: '',
                name: event.target.value,
                code: '',
              });
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                getCustomerCategory(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'division_customer') {
      return (
        <Grid item md={filter.gridItem}>
          <CustomerSearch
            text={customer?.name || ''}
            customerOpen={customerOpen}
            setCustomerOpen={setCustomerOpen}
            customerSelected={(data) => customerSelected(data)}
            customerMultiSelected={(data) => customerSelected(data, 'multi')}
            clearMultiSelect={() => {
              setCustomer({
                id: null,
                name: '',
              });
            }}
            companyCustomer={true}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
              style: 'reports',
            }}
          />
          {/* <CssTextField
            label="Customer"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={customer.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setCustomer({
                ...customer,
                id: '',
                code: '',
                name: event.target.value,
                email: '',
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                getDivisionCustomer(ev.target.value);
              }
            }}
          /> */}
        </Grid>
      );
    }
    if (filter.type == 'company_division') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Division"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={company.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setCompany({
                ...company,
                id: '',
                code: '',
                name: event.target.value,
                email: '',
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                getCompanyDivision(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'patient') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Patient Name/Mobile"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={patient.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setPatient({
                ...patient,
                id: '',
                name: event.target.value,
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                getPatient(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'area') {
      return (
        <Grid item md={filter.gridItem}>
          <AreaSearch
            text={area?.name || ''}
            areaSelected={(data) => areaSelected(data)}
            areaMultiSelected={(data) => areaSelected(data, 'multi')}
            clearMultiSelect={() => {
              setArea({
                id: null,
                name: '',
              });
            }}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'rep') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Rep"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={rep?.name || ''}
            onChange={(ev) => {
              setRep({
                ...rep,
                code: '',
                name: ev.target.value,
                id: '',
                email: '',
              });
            }}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            InputLabelProps={{ shrink: true }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();

                searchRep(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'transport') {
      return (
        <Grid item md={filter.gridItem}>
          <TransportSearch
            text={transport?.name || ''}
            transportSelected={(data) => transportSelected(data)}
            transportMultiSelected={(data) => transportSelected(data, 'multi')}
            clearMultiSelect={() => {
              setTransport({
                id: null,
                name: '',
              });
            }}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'disease_category') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Disease Category"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={disease.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setDisease({
                ...disease,
                id: '',
                code: '',
                name: event.target.value,
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                searchDisease(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'category') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Category Name"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={category.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setCategory({
                ...category,
                id: '',
                name: event.target.value,
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                getCategory(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'user') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="User Code/Name"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={user.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setUser({
                ...user,
                id: '',
                name: event.target.value,
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                getUser(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'account_ledger') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label="Ledger"
            required={filter?.mandatory || false}
            className={s.margin}
            variant="outlined"
            size="small"
            value={ledger.name || ''}
            InputProps={{
              endAdornment: <SearchIcon fontSize="small" />,
            }}
            onChange={(event) => {
              setLedger({
                ...ledger,
                id: '',
                name: event.target.value,
              });
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                getLedger(ev.target.value);
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'branch') {
      return (
        <Grid item md={filter.gridItem}>
          <BranchSearch
            text={branch?.name || ''}
            branchSelected={(data) => branchSelected(data)}
            branchMultiSelected={(data) => branchSelected(data, 'multi')}
            clearMultiSelect={() => {
              setBranch({
                id: null,
                name: '',
              });
            }}
            renderProps={{
              multiSelection: filter?.multiselect,
              required: filter?.mandatory || false,
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'input1') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.input1 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                input1: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'input2') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.input2 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                input2: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'input3') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.input3 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                input3: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'input4') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.input4 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                input4: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'string_input1') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.string_input1 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                string_input1: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'string_input2') {
      return (
        <Grid item md={filter.gridItem}>
          <CssTextField
            label={`${filter.label} :`}
            className={s.margin}
            variant="outlined"
            value={input?.string_input2 ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setInput({
                ...input,
                string_input2: event.target.value,
              });
            }}
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
              }
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'checkbox1') {
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            size="medium"
            color="primary"
            checked={input?.checkbox1 ?? false}
            onChange={(event) => {
              setInput({
                ...input,
                checkbox1: event.target.checked,
              });
            }}
            style={{ padding: '0px 4px' }}
          />
          <label style={{ margin: '2px 0 0 2px', fontSize: '13px' }}>
            {filter?.label ?? ''}
          </label>
        </Grid>
      );
    }
    if (filter.type == 'checkbox2') {
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            size="medium"
            color="primary"
            checked={input?.checkbox2 ?? false}
            onChange={(event) => {
              setInput({
                ...input,
                checkbox2: event.target.checked,
              });
            }}
            style={{ padding: '0px 4px' }}
          />
          <label style={{ margin: '2px 0 0 2px', fontSize: '13px' }}>
            {filter?.label ?? ''}
          </label>
        </Grid>
      );
    }
    if (filter.type == 'checkbox3') {
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            size="medium"
            color="primary"
            checked={input?.checkbox3 ?? false}
            onChange={(event) => {
              setInput({
                ...input,
                checkbox3: event.target.checked,
              });
            }}
            style={{ padding: '0px 4px' }}
          />
          <label style={{ margin: '2px 0 0 2px', fontSize: '13px' }}>
            {filter?.label ?? ''}
          </label>
        </Grid>
      );
    }
    if (filter.type == 'radio_button_group1') {
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <fieldset className={s.detailsfieldset}>
            <RadioGroup
              row
              aria-label="radio-group"
              name="row-radio-buttons-group"
              onChange={(event) => {
                setInput({
                  ...input,
                  radio_button_group1: event?.target?.value,
                });
              }}
              value={input?.radio_button_group1 ?? ''}
              style={{ flexGrow: 1, justifyContent: 'space-around' }}
            >
              {filter.label?.split(',')?.map((label, index) => {
                return (
                  <FormControlLabel
                    value={filter.values?.split(',')?.[index]}
                    control={<Radio style={{ color: COLORS.cta_blue }} />}
                    label={label}
                    labelPlacement="end"
                  />
                );
              })}
            </RadioGroup>
          </fieldset>
        </Grid>
      );
    }
    if (filter.type == 'radio_button_group2') {
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <fieldset className={s.detailsfieldset}>
            <RadioGroup
              row
              aria-label="radio-group"
              name="row-radio-buttons-group"
              onChange={(event) => {
                setInput({
                  ...input,
                  radio_button_group2: event?.target?.value,
                });
              }}
              value={input?.radio_button_group2 ?? ''}
              style={{ flexGrow: 1, justifyContent: 'space-around' }}
            >
              {filter.label?.split(',')?.map((label, index) => {
                return (
                  <FormControlLabel
                    value={filter.values?.split(',')?.[index]}
                    control={<Radio style={{ color: COLORS.cta_blue }} />}
                    label={label}
                    labelPlacement="end"
                  />
                );
              })}
            </RadioGroup>
          </fieldset>
        </Grid>
      );
    }
    if (filter.type == 'dropdown1') {
      let options = filter?.values?.split(',')?.map((val, index) => {
        return {
          value: val,
          label: filter.label?.split(',')?.[index],
        };
      });
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Select
            className={s.select}
            placeholder="Select"
            value={options.filter((option) => option.value == input?.dropdown1)}
            options={options}
            isClearable={true}
            onChange={(event) => {
              setInput({
                ...input,
                dropdown1: event?.value,
              });
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                neutral20: '#A5ADB0',
                neutral30: '#A5ADB0',
                neutral40: 'black',
              },
            })}
            styles={{
              control: (styles) => ({
                ...styles,
                height: '100%',
                margin: '0px',
                minHeight: '35px',
                height: '35px',
                borderRadius: '4px',
                borderColor: '#3c98e8',
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: '11px',
              }),
              valueContainer: (styles) => ({
                ...styles,
                padding: '0px 8px',
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                width: '0px',
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                padding: '4px',
              }),
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'dropdown2') {
      let options = filter?.values?.split(',')?.map((val, index) => {
        return {
          value: val,
          label: filter.label?.split(',')?.[index],
        };
      });
      return (
        <Grid
          item
          md={filter.gridItem}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Select
            className={s.select}
            placeholder="Select"
            value={options.filter((option) => option.value == input?.dropdown2)}
            options={options}
            isClearable={true}
            onChange={(event) => {
              setInput({
                ...input,
                dropdown2: event?.value,
              });
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                neutral20: '#A5ADB0',
                neutral30: '#A5ADB0',
                neutral40: 'black',
              },
            })}
            styles={{
              control: (styles) => ({
                ...styles,
                height: '100%',
                margin: '0px',
                minHeight: '35px',
                height: '35px',
                borderRadius: '4px',
                borderColor: '#3c98e8',
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: '11px',
              }),
              valueContainer: (styles) => ({
                ...styles,
                padding: '0px 8px',
              }),
              indicatorSeparator: (styles) => ({
                ...styles,
                width: '0px',
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                padding: '4px',
              }),
            }}
          />
        </Grid>
      );
    }
    if (filter.type == 'generate') {
      return (
        <Grid item md={filter.gridItem} style={{ display: 'flex' }}>
          <PrimaryButton
            title="Generate"
            executeClick={getReport}
            customWidth="100%"
            customHeight="35px"
          />
          {window.location.href.includes('cheques_status_report') ||
            window.location.href.includes('customers_without_cheques') ||
            window.location.href.includes('bulk_stock_email') ? (
            <IconOnlyButton
              executeClick={() => {
                sendMail();
              }}
              disabled={rows.length === 0}
              customMargin="0 0 0 10px"
              icon={<SendEmail />}
            />
          ) : (
            <></>
          )}
        </Grid>
      );
    }
  };

  const renderSummaryComponent = (reportSummary) => {
    let primary = reportSummary?.filter((s) => s.primary == true)?.[0];
    let secondary = reportSummary?.filter((s) => s.primary != true);
    return (
      <Grid item md={3}>
        <div
          className={s.myfieldset}
          style={{
            padding: secondary?.length > 0 ? '' : '0px',
          }}
        >
          {primary ? (
            <Grid
              container
              className={s.summaryPrimaryCard}
              style={{
                border: secondary?.length > 0 ? '' : 'none',
                backgroundColor: secondary?.length > 0 ? '' : '#fff',
              }}
            >
              <Grid
                item
                className={s.summaryPrimaryLabel}
              >{`${primary?.label}`}</Grid>
              <Grid item className={s.summaryPrimaryValue}>{`${primary?.value || '0.00'
                }`}</Grid>
            </Grid>
          ) : (
            <></>
          )}
          {secondary?.map((item) => (
            <Grid
              container
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Grid
                item
                className={s.summarySecondaryLabel}
              >{`${item?.label}`}</Grid>
              <Grid item className={s.summarySecondaryValue}>{`${item?.value || '0.00'
                }`}</Grid>
            </Grid>
          ))}
        </div>
      </Grid>
    );
  };

  return (
    <div className={s.root}>
      <div className={s.container}>
        <Backdrop className={s.backdrop} open={loadingOpen}>
          <CircularProgress color="inherit" size="5rem" />
        </Backdrop>

        <Card
          className={s.topborder}
          outlined
          elevation={0}
          id="ReportsFilterCard"
        >
          <Grid container>
            <Grid
              container
              item
              md={8}
              spacing={1}
              style={{ height: 'fit-content' }}
            >
              {filters?.map((filter) => {
                return renderFilter(filter);
              })}
            </Grid>
            <Grid container item md={1} />

            {reportSummary?.length > 0 && renderSummaryComponent(reportSummary)}
          </Grid>
        </Card>

        <Output
          title={title}
          subTitle={handleReportSubTitle()}
          rows={printRows}
          columns={printColumns}
          csvRows={rows}
          csvColumns={columns}
          from={from}
          to={to}
          setPdfOpen={(state, type) => {
            if (type == 'mobile') setPdfSize([297.64, 841.89]);
            else setPdfSize('A4');
          }}
          checkedRows={checkboxSelectedRows}
          handleRowCheck={checkboxToggle}
          handleAllRowsCheck={allCheckboxToggle}
          sendEmail={(recipients, subject) => {
            setLoadingOpen(true);
            setEmailDetails({ recipients: recipients, subject: subject });
          }}
          emailOptionsOpen={emailOptionsOpen}
          filterEmails={{
            company: company?.email,
            supplier: supplier?.email,
            companyGroup: companyGroup?.email,
            customer: customer?.email,
            rep: rep?.email,
          }}
          setEmailOptionsOpen={setEmailOptionsOpen}
          outputType={report_view}
          orientation={orientation ?? 'portrait'}
          setOrientation={setOrientation}
          reportSettings={reportSettings}
          setReportSettings={setReportSettings}
        />

        {/* Company model */}
        <Dialog
          onClose={handleCompanyClose}
          aria-labelledby="customized-dialog-title"
          open={companyOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCompanyClose}
          >
            Select Company
          </DialogTitle>
          <DialogContent dividers>
            <CompanyTable rows={companyRows} rowSelected={companySelected} />
          </DialogContent>
        </Dialog>
        {/* Division model */}
        <Dialog
          onClose={handleCompanyClose}
          aria-labelledby="customized-dialog-title"
          open={companyOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCompanyClose}
          >
            Select Division
          </DialogTitle>
          <DialogContent dividers>
            <CompanyTable rows={companyRows} rowSelected={companySelected} />
          </DialogContent>
        </Dialog>

        {/* Company Group model */}
        <Dialog
          onClose={handleCompanyGroupClose}
          aria-labelledby="customized-dialog-title"
          open={companyGroupOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCompanyGroupClose}
          >
            Select Company Group
          </DialogTitle>
          <DialogContent dividers>
            <CompanyGroupTable
              rows={companyGroupRows}
              rowSelected={companyGroupSelected}
            />
          </DialogContent>
        </Dialog>

        {/* Product model */}
        <Dialog
          onClose={handleProductClose}
          aria-labelledby="customized-dialog-title"
          open={productOpen}
          fullWidth
          maxWidth={'xl'}
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleProductClose}
          >
            Select Product
          </DialogTitle>
          <DialogContent dividers>
            <ProductTable rows={productRows} rowSelected={productSelected} />
          </DialogContent>
        </Dialog>

        {/* Rep model */}
        <Modal
          open={repOpen}
          content={repModalContent}
          modalClose={() => setRepOpen(false)}
          size="lg"
          fullWidth
          maxWidth="xl"
        ></Modal>

        {/* Category model */}
        <Modal
          open={categoryOpen}
          content={categoryModalContent}
          modalClose={() => setCategoryOpen(false)}
          size="md"
          fullWidth
          maxWidth="xl"
        ></Modal>

        {/* Patient model */}
        <Dialog
          onClose={handlePatientClose}
          aria-labelledby="customized-dialog-title"
          open={patientOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handlePatientClose}
          >
            Select Patient
          </DialogTitle>
          <DialogContent dividers>
            <PatientTable rows={patientRows} rowSelected={patientSelected} />
          </DialogContent>
        </Dialog>

        {/* User model */}
        <Dialog
          onClose={handleUserClose}
          aria-labelledby="customized-dialog-title"
          open={userOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleUserClose}>
            Select User
          </DialogTitle>
          <DialogContent dividers>
            <UserTable rows={userRows} rowSelected={userSelected} />
          </DialogContent>
        </Dialog>

        {/* Ledger model */}
        <Dialog
          onClose={handleLedgerClose}
          aria-labelledby="customized-dialog-title"
          open={ledgerOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleLedgerClose}>
            Select Ledger
          </DialogTitle>
          <DialogContent dividers>
            <LedgerTable rows={ledgerRows} rowSelected={ledgerSelected} />
          </DialogContent>
        </Dialog>

        {/* Transport model */}
        <Dialog
          onClose={handleTransportClose}
          aria-labelledby="customized-dialog-title"
          open={transportOpen}
          fullWidth
          maxWidth={'md'}
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleTransportClose}
          >
            Select Transport
          </DialogTitle>
          <DialogContent dividers>
            <TransportTable
              rows={transportRows}
              rowSelected={transportSelected}
            />
          </DialogContent>
        </Dialog>

        {/* Disease model */}
        <Dialog
          onClose={handleDiseaseClose}
          aria-labelledby="customized-dialog-title"
          open={diseaseOpen}
          fullWidth
          maxWidth={'md'}
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleDiseaseClose}
          >
            Select Disease Category
          </DialogTitle>
          <DialogContent dividers>
            <DiseaseCategoryTable
              rows={diseaseRows}
              rowSelected={diseaseSelected}
            />
          </DialogContent>
        </Dialog>

        {/* Customer Category model */}
        <Dialog
          onClose={() => setCustomerCategoryOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={customerCategoryOpen}
          fullWidth
          maxWidth="xl"
          classes={{ paperFullWidth: s.tabpanel }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setCustomerCategoryOpen(false)}
          >
            Select Customer Category
          </DialogTitle>
          <DialogContent dividers>
            <SearchTable
              rows={customerCategoryRows}
              columns={[
                {
                  id: 'code',
                  label: 'Code',
                  minWidth: 50,
                  type: 'label',
                },
                {
                  id: 'name',
                  label: 'Name',
                  minWidth: 70,
                  type: 'label',
                },
                {
                  id: 'cash_disc',
                  label: 'Cash disc %',
                  minWidth: 60,
                  type: 'label',
                  align: 'center',
                },
                {
                  id: 'credit_disc',
                  label: 'Credit disc %',
                  minWidth: 60,
                  type: 'label',
                  align: 'center',
                },
              ]}
              rowSelected={customerCategorySelected}
            />
          </DialogContent>
        </Dialog>

        {/* Print Preview */}
        {pdfOpen && (
          <PrintPreview
            documentTitle={title}
            pdfSize={pdfSize}
            title={handleReportTitle()}
            columns={printColumns}
            rows={printRows}
            pdfOpen={pdfOpen}
            handlePrintClose={() => {
              setPdfSize('');
              setPdfOpen(false);
            }}
            orientation={print_orientation ?? 'portrait'}
            tableType={'mui-table'}
            reportSettings={reportSettings}
          />
        )}

        {/* Send Report Email */}
        {createBlob && (
          <Blob
            documentTitle={title}
            title={handleReportTitle()}
            columns={printColumns}
            rows={printRows}
            orientation={print_orientation ?? 'portrait'}
            tableType={'mui-table'}
            emailDetails={emailDetails}
            setLoadingOpen={(bool) => {
              setCreateBlob(false);
              setLoadingOpen(bool);
            }}
            setEmailOptionsOpen={setEmailOptionsOpen}
          />
        )}
      </div>
    </div>
  );
}

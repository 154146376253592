import React from 'react';
import s from './Invoice.module.css';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import { Grid } from '@mui/material';

export default function TableHeader({
  columns,
  tableType,
  additionalHeaderRow,
}) {
  // useStyles(s);

  function getWidth(allColumns, column) {
    let total_width = 0;
    if (tableType == 'mui-table')
      total_width = allColumns?.reduce(
        (n, { minWidth }) => n + parseInt(minWidth || 40),
        0,
        ``,
      );
    else
      total_width = allColumns?.reduce(
        (n, { width }) => n + parseInt(width),
        0,
      );

    let width_share =
      (column?.[`${tableType == 'mui-table' ? 'minWidth' : 'width'}`] /
        total_width) *
      100;
    return Math.floor(width_share * 100) / 100;
  }

  const styles = columns?.map((column, index) => {
    return {
      borderRight: `solid ${index == columns.length - 1 ? 0 : 2}px #b9b9b9`,
      color: '#000',
      boxSizing: 'border-box',
      width: `${getWidth(columns, column)}%`,
      padding: '6px 4px',
      textAlign: column.align ?? 'left',
      margin: '0',
      fontWeight: '500',
      height: '27px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  });

  const additionalHeaderStyle = additionalHeaderRow?.map((column, index) => {
    return {
      borderRight: `solid ${index == columns.length - 1 ? 0 : 2}px #b9b9b9`,
      color: '#000',
      boxSizing: 'border-box',
      width: `${getWidth(additionalHeaderRow, column)}%`,
      padding: '6px 4px',
      textAlign: column.align ?? 'left',
      margin: '0',
      fontWeight: '500',
      height: '27px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  });

  return (
    <>
      {additionalHeaderRow?.length > 0 ? (
        <Grid container className={s.additional_header_container}>
          {additionalHeaderRow?.map((element, index) => {
            let val =
              tableType == 'mui-table'
                ? additionalHeaderRow[index]?.label
                : additionalHeaderRow[index]?.headerName;
            let val1 =
              tableType == 'mui-table'
                ? additionalHeaderRow[index + 1]?.label ?? ''
                : additionalHeaderRow[index + 1]?.headerName ?? '';
            return (
              <div
                style={{
                  ...additionalHeaderStyle[index],
                  backgroundColor: (val ?? '') == '' ? '' : '#ddfaec',
                  borderRight: `solid ${index == additionalHeaderRow.length - 1 ? 0 : 2
                    }px ${(val1 ?? '') == '' ? '#ffffff' : '#b9b9b9'}`,
                  textAlign: 'center',
                }}
              >
                <p style={{ margin: '0' }}>{val}</p>
              </div>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
      {columns?.length > 0 ? (
        <Grid container className={s.table_header_container}>
          {columns?.map((element, index) => {
            return (
              <div style={styles[index]}>
                <p style={{ margin: '0' }}>
                  {tableType == 'mui-table'
                    ? columns[index].label
                    : columns[index].headerName}
                </p>
              </div>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

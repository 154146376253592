import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import TextField from '@mui/material/TextField';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import apiRequest from 'src/utils/api';
import { useSelector } from 'react-redux';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import SearchIcon from '@mui/icons-material/Search';
import { AsyncPaginate } from 'react-select-async-paginate';

// COMPONENTS
import Modal from '../Modal/Modal';
import SearchTable from 'src/components/Table/SearchTable.js';
import CustomerMultiSelectTable from 'src/components/Table/CustomerMultiSelectTable.js';
import SecondaryButton from 'src/components/Buttons/SecondaryButton.js';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -6px) scale(0.8)',
    },
  },
})(TextField);

const StyledTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

/* 
Props used to customize the search textfield 
PLEASE ADD ALL NEW PROPS TO THE DEFAULT PROPS OBJECT BELOW
*/
const defaultRenderProps = {
  readOnly: false,
  autoFocus: true,
  required: true,
  disabled: false,
  clientAdditionalInfo: false,
  searchIcon: false,
  multiSelection: false,
  backgroundColor: '',
  isClearable: false,
};

/*
PROPS :
1. text: used to display the selected customer code
2. customerOpen: useState for toggling the search dialog
3. setCustomerOpen: useState function for above state
4. customerSelected: parent function triggered when row selected in dialog
5. renderProps: used to customize the search textfield
6. internalClientTransfer: flag to know if search is restricted to internal branches
7. contractCustomer: flag to know if search is restricted to contract customers
8. inputRef: useRef for focus on textField
9. handleClientAdditionalInfoDisplay: parent function triggered when end icon clicked
*/
function CustomerSearch(
  {
    text,
    customerOpen,
    setCustomerOpen,
    customerSelected = () => { },
    customerMultiSelected = () => { },
    clearMultiSelect = () => { },
    selectedBranch = null,
    currentUser = null,
    renderProps = defaultRenderProps,
    internalClientTransfer = false,
    contractCustomer = false,
    companyCustomer = false,
    patientCustomer = false,
    inputRef,
    handleClientAdditionalInfoDisplay = () => { },
  },
  customerSearchRef,
) {
  const customizations = useSelector((state) => state.customizations);
  const entryState = useSelector((state) => state.entrystate);
  const [value, setValue] = React.useState('');
  const [areaFilter, setAreaFilter] = React.useState('');
  const [customerRows, setCustomerRows] = React.useState([]);
  const [filteredCustomerRows, setFilteredCustomerRows] = React.useState([]);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);

  // Columns fetched from the customization table
  const customerSearchColumns = customizations?.customer_search
    ?.defaultColumns ?? [
      { id: 'code', label: 'Code', minWidth: 50 },
      { id: 'name', label: 'Name', minWidth: 200 },
      { id: 'address_3', label: 'Addr 3', minWidth: 60 },
      { id: 'address_1', label: 'Addr 1', minWidth: 60 },
      { id: 'address_2', label: 'Addr 2', minWidth: 60 },
      { id: 'city', label: 'City', minWidth: 50 },
      { id: 'pincode', label: 'Pincode', minWidth: 50 },
      { id: 'state', label: 'State', minWidth: 80 },
      { id: 'phone', label: 'Phone', minWidth: 80 },
      { id: 'gstin', label: 'GSTIN', minWidth: 80 },
      { id: 'email', label: 'Email', minWidth: 80 },
      { id: 'contact_person', label: 'Cont. Person', minWidth: 100 },
      { id: 'secondary_phone', label: 'Sec. Phone', minWidth: 80 },
      { id: 'sms_cell', label: 'SMS Phone', minWidth: 80 },
    ];
  // Adding the props added in the parent to the default props predefined
  const componentCustomization = { ...defaultRenderProps, ...renderProps };

  useImperativeHandle(
    customerSearchRef,
    () => ({
      clear() {
        clear();
      },
    }),
    [],
  );

  React.useEffect(() => {
    setValue('');
  }, [text]);

  React.useEffect(() => {
    if (areaFilter?.length > 0) {
      let filtered_customers = customerRows?.filter((customer) =>
        customer?.area?.toLowerCase().includes(areaFilter.toLowerCase()),
      );
      setFilteredCustomerRows(filtered_customers);
    } else {
      setFilteredCustomerRows(customerRows);
    }
  }, [areaFilter]);

  const apiRequest = async (url, body, method) => {
    const branchHeader = {
      branch_id: selectedBranch?.branch_id,
      client_id: selectedBranch?.client_id,
      rep_id: selectedBranch?.id,
      phone: selectedBranch?.phone

    }
    // console.log('branchHeader', branchHeader, btoa(JSON.stringify(branchHeader)))
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        "sp360-header": btoa(JSON.stringify(branchHeader)),
        'Authorization': `Bearer ${currentUser?.access_token}`,

      },
    };
    if (method != 'GET') {
      options.body = JSON.stringify(body);
    }


    const data = await fetch(`${process.env.REACT_APP_SP360_URL}${url}`, options);
    return await data.json();

  }

  const customerModalContent = {
    body: (
      <>
        {componentCustomization?.multiSelection ? (
          <>
            <Grid item md={4} style={{ padding: '8px' }}>
              <CssTextField
                label="Search Customer"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: <SearchIcon fontSize="small" />,
                }}
                onFocus={(event) => event.target.select()}
                InputLabelProps={{ shrink: true }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    searchCustomer(ev.target.value);
                  }
                }}
                style={{
                  width: '100%',
                  margin: '0 0 4px 0',
                  backgroundColor: '#fff',
                }}
              />
            </Grid>
            <Grid container>
              <CustomerMultiSelectTable
                rows={filteredCustomerRows}
                rowSelected={(data) => {
                  customerSelected(data);
                  setCustomerOpen(false);
                }}
                multiSelection={componentCustomization?.multiSelection}
                checkedRows={selectedCustomers}
                setCheckedRows={setSelectedCustomers}
              />
            </Grid>
            <Grid
              container
              style={{
                flexDirection: 'column',
                borderTop: 'solid 1px #e3f0f6',
              }}
            >
              <Grid
                container
                item
                style={{ backgroundColor: '#f6f6f6', padding: '16px' }}
              >
                <Grid item md={11}>
                  <AsyncPaginate
                    isMulti
                    value={selectedCustomers}
                    onChange={setSelectedCustomers}
                    menuIsOpen={false}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#f6f6f6',
                      },
                    })}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        height: '56px',
                        lineHeight: '16px',
                        fontSize: '20px',
                        alignItems: 'start',
                        backgroundColor: '#f6f6f6',
                        border: 'none',
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        maxHeight: '58px',
                        padding: '0px',
                        overflow: 'auto',
                      }),
                      input: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      option: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      multiValue: (styles) => ({
                        ...styles,
                        backgroundColor: '#caeafc',
                        border: 'solid 1px #a9c6d6',
                        margin: '0 4px 8px 0',
                        height: '24px',
                        color: '#085a88',
                        borderRadius: '4px',
                      }),
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: '#085a88',
                        padding: '2px 4px 0px 8px',
                        fontSize: '12px',
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: '#1e6cc7',
                      marginTop: '4px',
                      fontSize: '12px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      clearMultiSelect();
                      setSelectedCustomers([]);
                    }}
                  >
                    Clear
                  </span>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '16px',
                }}
              >
                <PrimaryButton
                  title="Done"
                  customWidth="96px"
                  customHeight="29px"
                  executeClick={() => {
                    customerMultiSelected(selectedCustomers);
                    setCustomerOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              style={{ alignItems: 'center', padding: '6px 16px 16px' }}
            >
              <Grid item>Filters :</Grid>
              <Grid item md={3}>
                <StyledTextField
                  label="Filter by Area"
                  value={areaFilter}
                  variant="outlined"
                  margin="normal"
                  onChange={(event) => {
                    setAreaFilter(event.target.value);
                  }}
                  onKeyDown={(ev) => {
                    if (
                      ev.key === 'Enter' ||
                      ev.key === 'ArrowUp' ||
                      ev.key === 'ArrowDown'
                    ) {
                      ev.preventDefault();
                      ev.target.blur();
                    }
                  }}
                  onFocus={(event) => event.target.select()}
                  style={{
                    margin: '0 8px',
                    width: 'calc(100% - 8px)',
                  }}
                  InputProps={{
                    endAdornment: <SearchIcon fontSize="small" />,
                  }}
                />
              </Grid>
              <Grid item>
                <SecondaryButton
                  title="Clear"
                  executeClick={() => clearFilters()}
                  customHeight="28px"
                  customMargin="0 0 0 10px"
                />
              </Grid>
            </Grid>

            <Grid container>
              <SearchTable
                src="customer"
                rows={filteredCustomerRows}
                rowSelected={(row) => {
                  setValue('');
                  customerSelected(row);
                }}
                columns={customerSearchColumns}
              />
            </Grid>
          </>
        )}
      </>
    ),
  };
  const handleCustomerOpen = () => {
    if (value != '') {
      setCustomerOpen(true);
    }
  };

  const handleCustomerClose = () => {
    setAreaFilter('');
    setCustomerOpen(false);
  };

  const clearFilters = () => {
    setAreaFilter('');
  };
  const clear = () => {
    setSelectedCustomers([]);
  };

  async function searchCustomer(query) {
    try {
      let response;
      if (internalClientTransfer) {
        response = await apiRequest(
          `api/customer_branch_list?search=${query}`,
          null,
          'GET',
        );
      } else if (contractCustomer) {
        response = await apiRequest(
          `api/customer_search_list/contract?search=${query}`,
          null,
          'GET',
        );
      } else if (companyCustomer) {
        if ((entryState?.division_id_id ?? '') == '') {
          alert('Please select Company !');
        } else {
          response = await apiRequest(
            `api/customer_list?division_id=${entryState?.division_id_id}&search=${query}`,
            null,
            'GET',
          );
        }
      } else if (patientCustomer) {
        response = await apiRequest(
          `api/patient_customer_list?search=${query}`,
          null,
          'GET',
        );
      } else {

        response = await apiRequest(
          `api/customer_list?search=${query}`,
          null,
          'GET',
        );
      }
      setCustomerRows([]);
      setFilteredCustomerRows([]);
      if (response?.results.length == 0) {
        alert(`No Results for ${query}`);
        // handleCustomerClose();
      } else if (response?.results.length == 1) {
        if (query.toUpperCase() == response?.results[0].code.toUpperCase()) {
          customerSelected(response?.results);
        } else {
          setCustomerRows(response?.results.reverse());
          setFilteredCustomerRows(response?.results.reverse());
          handleCustomerOpen();
        }
      } else if (response) {
        setCustomerRows(response?.results.reverse());
        setFilteredCustomerRows(response?.results.reverse());
        handleCustomerOpen();
      }
    } catch (error) {
      handleCustomerClose();
      // console.log('Error in searchCustomer', error);
      alert(`Please try again \n${error}`);
    }
  }

  return (
    <div>
      {componentCustomization?.style == 'reports' ? (
        <CssTextField
          label="Customer"
          value={text === '' ? value ?? '' : value === '' ? text : value}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          onChange={(event) => {
            setValue(event.target.value);
            if (event.target.value.trim() == '') customerSelected([]);
          }}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.multiSelection &&
                  selectedCustomers?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedCustomers.length - 1}`}</span>
                ) : (
                  <></>
                )}
                <SearchIcon fontSize="small" onClick={(ev) => {
                  // console.log('On Click', value)
                  ev.preventDefault();

                  searchCustomer(value);
                }} />
              </Grid>
            ),
          }}
          onFocus={(event) => event.target.select()}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();

              searchCustomer(ev.target.value);
            }
          }}
          style={{
            width: '100%',
            margin: '0 0 4px 0',
            backgroundColor: '#fff',
          }}
        />
      ) : (
        <StyledTextField
          label="Customer"
          value={text === '' ? value : value === '' ? text : value}
          variant="outlined"
          margin="normal"
          inputRef={inputRef}
          autoFocus={componentCustomization?.autoFocus}
          disabled={componentCustomization?.disabled}
          required={componentCustomization?.required}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setValue(event.target.value);
            if (
              componentCustomization?.isClearable &&
              event.target.value.trim() == ''
            )
              customerSelected([{ name: '', id: null }]);
          }}
          onKeyDown={(ev) => {
            if (
              (ev.key === 'Enter' || ev.key === 'Tab') &&
              !componentCustomization?.readOnly
            ) {
              if (value == '') {
                if (text == '' || text == undefined) {
                  window.confirm(
                    'Invalid Input !! \nMinimum 1 letter required',
                  );
                } else {
                  setValue(text);
                  searchCustomer(text);
                }
              } else {
                searchCustomer(value);
              }
            } else if (ev.key === 'F2') {
              // handleCustomerOpen();
            }
          }}
          onFocus={(event) => event.target.select()}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.clientAdditionalInfo == true ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        handleClientAdditionalInfoDisplay();
                      }}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                      style={{ padding: '5px' }}
                    >
                      <LibraryBooksRoundedIcon style={{ fontSize: '16px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )}
                {componentCustomization?.multiSelection == true &&
                  selectedCustomers?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedCustomers.length - 1}`}</span>
                ) : (
                  <></>
                )}
                {componentCustomization?.searchIcon == true ? (
                  <SearchIcon fontSize="small" />
                ) : (
                  <></>
                )}
              </Grid>
            ),

            ...(componentCustomization?.readOnly == true
              ? {
                readOnly: true,
              }
              : ''),
          }}
          style={{
            margin: '4px 0px',
            width: '100%',
            backgroundColor: componentCustomization?.backgroundColor,
          }}
        />
      )}
      <Modal
        open={customerOpen}
        content={customerModalContent}
        modalClose={() => handleCustomerClose()}
        size="lg"
        fullWidth
        maxWidth="xl"
        dividers
      />
    </div>
  );
}

export default forwardRef(CustomerSearch);

import React from 'react';
import s from './Invoice.module.css';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import { Grid } from '@mui/material';
import SmartPharmalogo from 'src/components/images/Logo.png';

export default function InvoiceFooter({ date, username }) {
  // useStyles(s);

  return (
    <Grid container className={s.footer_container}>
      <Grid itemn style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ margin: '8px 10px 0 0', fontSize: '14px' }}>Powered by</p>
        <img
          src={SmartPharmalogo}
          // alt="SmartPharma360™"
          style={{ maxWidth: '156px' }}
        />
      </Grid>
      <Grid item>
        <p style={{ margin: '0', paddingTop: '8px', fontSize: '14px' }}>
          Taken by: {username} | Taken at: {date}
        </p>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableBlankSpace from './TableBlankSpace';

const ReportTable = ({
  columns,
  rows,
  maxItems,
  tableType,
  additionalHeaderRow,
  reportSettings,
}) => (
  <View
    style={{
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 0,
      borderWidth: 1,
      borderColor: '#dbdbdb',
      width: reportSettings?.half_page ? '50%' : '100%',
    }}
  >
    <TableHeader
      columns={columns}
      tableType={tableType}
      additionalHeaderRow={additionalHeaderRow}
    />
    <TableRow columns={columns} rows={rows} tableType={tableType} />
    <TableBlankSpace rowsCount={maxItems - rows?.length} />
  </View>
);

export default ReportTable;

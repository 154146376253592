// import React from 'react';
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// interface Props {
//   align?: string;
//   justify?: string;
//   gap?: string;
//   direction?: string;
// }

export const HBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 1rem;
`;

export const VBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  gap: 1rem;
`;

// export function VBox({
//   align = 'center',
//   justify = 'space-around',
//   gap = '1rem',
//   direction = 'row',
// }: Props) {
//   return <FlexBox />;
// }

// export function HBox({
//   align = 'center',
//   justify = 'space-around',
//   gap = '1rem',
//   direction = 'columns',
// }: Props) {
//   return <FlexBox />;
// }

import React, { forwardRef, useImperativeHandle } from 'react';
// import useStyles from 'isomorphic-style-loader/useStyles';
import { withStyles } from '@mui/styles';;
import s from './Dialogs.module.css';
import { Grid, Box, TextField, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import TableComponent from 'src/components/Table/TableComponent';
import PrimaryButton from 'src/components/Buttons/PrimaryButton.js';
import Moment from 'moment';
import { financialDateRange } from 'src/constants/financialDateRange';
import apiRequest from 'src/utils/api';
import Modal from 'src/components/Modal/Modal';

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const tranTypeOptions = [
  { value: 'SALE', label: 'Sale' },
  { value: 'SALE DC', label: 'Sale DC' },
  { value: 'RETAIL SALE', label: 'B2C Sale' },
  { value: 'PURCHASE RETURN', label: 'Purchase Return' },
  { value: 'TRANSFER OUT', label: 'Transfer Out' },
  { value: 'PURCHASE', label: 'Purchase' },
  { value: 'PURCHASE DC', label: 'Purchase DC' },
  { value: 'SALE RETURN', label: 'Sale Return' },
  { value: 'TRANSFER IN', label: 'Transfer In' },
];

const columns = [
  { id: 'vch_date', label: 'Vch Date', minWidth: 30, type: 'label' },
  { id: 'vch_number', label: 'Vch Number', minWidth: 50, type: 'label' },
  {
    id: 'particular',
    label: 'Particulars',
    type: 'label',
    minWidth: 150,
  },
  {
    id: 'credit',
    label: 'Credit',
    type: 'label',
    align: 'right',
    type: 'label',
    minWidth: 70,
  },
  {
    id: 'debit',
    label: 'Debit',
    align: 'right',
    type: 'label',
    minWidth: 70,
  },
  {
    id: 'running_balance',
    label: 'Running Balance',
    type: 'label',
    align: 'right',
    minWidth: 80,
  },
];

function ProductLedger({ triggerClose = () => { } }, productLedgerRef) {
  const UseFocus = () => {
    const htmlElRef = React.useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  // useStyles(s);

  useImperativeHandle(
    productLedgerRef,
    () => ({
      handleProductLedgerOpen(product) {
        getProductLedgerReport(product);
        setProduct(product);
        setFrom(fromDate);
        setTo(today);
        setBatch({});
      },
    }),
    [],
  );

  const perms = useSelector((state) => state.permissions);
  const today = Moment().format('YYYY-MM-DD');
  const fromDate = Moment().subtract(6, 'days').format('YYYY-MM-DD');
  // let [fromDate, toDate] = financialDateRange(today);
  const [from, setFrom] = React.useState(fromDate);
  const [to, setTo] = React.useState(today);
  const [batch, setBatch] = React.useState({});
  const [tranType, setTranType] = React.useState(0);
  const [product, setProduct] = React.useState({});
  const [analytics, setAnalytics] = React.useState({});

  const [productLedgerOpen, setProductLedgerOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [ledgerRows, setLedgerRows] = React.useState([]);
  const [ledgerColumns, setLedgerColumns] = React.useState([
    { id: 'invoice_number', label: 'Entry #', minWidth: 40, type: 'link' },
    { id: 'invoice_date', label: 'Entry Date', minWidth: 40, type: 'date' },
    { id: 'po_number', label: 'Inv #', minWidth: 40, type: 'label' },
    { id: 'po_date', label: 'Inv Date', minWidth: 40, type: 'date' },
    { id: 'party', label: 'Party', minWidth: 80, type: 'label' },
    { id: 'batch', label: 'Batch Number', minWidth: 40, type: 'label' },
    {
      id: 'inward',
      label: 'Inward',
      minWidth: 20,
      type: 'label',
      align: 'right',
    },
    {
      id: 'outward',
      label: 'Outward',
      minWidth: 20,
      type: 'label',
      align: 'right',
    },
    { id: 'type', label: 'Type', minWidth: 20, type: 'label' },
  ]);

  const [toRef, setToFocus] = UseFocus();
  const [batchRef, setBatchFocus] = UseFocus();
  const [tranTypeRef, setTranTypeFocus] = UseFocus();
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    setFrom(today);
    setTo(today);
  }, []);

  // React.useEffect(() => {
  //   setLedgerRows([]);
  // }, [product?.id]);

  const handleLoadingOpen = () => {
    setLoadingOpen(true);
  };
  const handleLoadingClose = () => {
    setLoadingOpen(false);
  };
  const handleProductLedgerOpen = () => {
    setProductLedgerOpen(true);
  };
  const handleProductLedgerClose = () => {
    setProductLedgerOpen(false);
    triggerClose();
  };

  async function getProductLedgerReport(product) {
    handleLoadingOpen();
    setLedgerRows([]);
    try {
      let apiCall = 'view_product_ledger';

      await apiRequest(
        `api/${apiCall}/${from}/${to}/${product?.id ?? ''}/-`,
        null,
        'GET',
      ).then((response) => {
        handleLoadingClose();
        if (response?.results?.length == 0) {
          alert(`No Results found !`);
        } else {
          handleProductLedgerOpen();
          setLedgerRows(response.results.items);
          setAnalytics(response.results.analytics);
          // setLedgerColumns(response.results.columns);
        }
      });
    } catch (error) {
      alert(error.message);
      handleLoadingClose();
    }
  }
  function handleInvoiceOpen(row) {
    try {
      window.open(`/${row?.navigate_to}?${row?.header_id}`, '_blank');
    } catch (error) {
      alert(error.message);
    }
  }
  return (
    <Modal
      open={productLedgerOpen}
      content={{
        header: `Ledger - ${product?.code || ''} - ${product?.name || ''} - ${product?.packing || ''
          } - ${product?.company_name || ''}`,
        body: (
          <Box style={{ minHeight: '500px' }}>
            <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '56px',
                width: '100%',
                padding: '0 8px',
                marginBottom: '8px',
                borderRadius: '8px',
                alignItems: 'center',
              }}
            >
              <Grid item md={2}>
                <CssTextField
                  className={s.margin}
                  label="From :"
                  variant="outlined"
                  value={from}
                  autoFocus
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setFrom(event.target.value);
                  }}
                  size="small"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setToFocus();
                    }
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <CssTextField
                  className={s.margin}
                  label="To :"
                  variant="outlined"
                  value={to}
                  onChange={(event) => {
                    setTo(event.target.value);
                  }}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={toRef}
                  size="small"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setTimeout(() => {
                        buttonRef.current.focus();
                      }, 50);
                    }
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <CssTextField
                  className={s.margin}
                  label="Batch Filter "
                  variant="outlined"
                  size="small"
                  inputRef={batchRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={batch?.name ?? ''}
                  onChange={(event) => {
                    if (event.target.value == '') {
                      setBatch({ ...batch, id: null, name: '' });
                    } else {
                      setBatch({ ...batch, name: event.target.value });
                    }
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      // searchBatch(batch?.name);
                      // setTimeout(() => {
                      //   buttonRef.current.focus();
                      // }, 50);
                    }
                  }}
                />
              </Grid>

              <PrimaryButton
                title="Generate"
                executeClick={() => {
                  getProductLedgerReport(product);
                }}
                customWidth="128px"
                customHeight="29px"
                ref={buttonRef}
              />
            </Grid>
            {loadingOpen ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '444px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#3c98e8',
                }}
              >
                <CircularProgress color="inherit" size="5rem" />
                <p style={{ fontSize: '14px', margin: '36px 0' }}>
                  Fetching Ledger. This will take a couple of seconds
                </p>
              </div>
            ) : (
              <TableComponent
                columns={ledgerColumns}
                rows={ledgerRows?.filter((row) =>
                  row?.batch
                    ?.toUpperCase()
                    ?.includes((batch?.name ?? '')?.toUpperCase()),
                )}
                linkTextClicked={handleInvoiceOpen}
                customHeight="444px"
                rowClickable={false}
                colorOnRowSelected={false}
              />
            )}
            <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '44px',
                width: '100%',
                justifyContent: 'space-between',
                padding: '0 8px',
                marginBottom: '8px',
                marginTop: '8px',
                borderRadius: '8px',
              }}
            >
              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Opening Stock:&nbsp;</p>
                <p className={s.headerValues}>
                  {analytics?.opening_stock || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Inward:&nbsp;</p>
                <p className={s.headerValues}>{analytics?.inward || 0}</p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Outward:&nbsp;</p>
                <p
                  className={s.headerValues}
                // style={{
                //   color:
                //     analytics?.net_outstanding_amount !=
                //     analytics?.closing_balance
                //       ? '#E16005'
                //       : '',
                // }}
                >
                  {analytics?.outward || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Closing Stock:&nbsp;</p>
                <p
                  className={s.headerValues}
                // style={{
                //   color:
                //     analytics?.net_outstanding_amount !=
                //     analytics?.closing_balance
                //       ? '#E16005'
                //       : '',
                // }}
                >
                  {analytics?.closing_stock || 0}
                </p>
              </Grid>
            </Grid>
          </Box>
        ),
      }}
      modalClose={handleProductLedgerClose}
      size="lg"
    />
  );
}

export default forwardRef(ProductLedger);

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  phone: string;
  access_token?: string;
  address?: string;
  created_at?: string;
  is_active?: boolean;
  name?: string;
  phone_number?: string;
  id?: number;
  metadata?: {
    is_password_set?: boolean;
    is_profile_set?: boolean;
  };
}

const initialState: UserState = {
  phone: "",
  access_token: "",
  address: "",
  created_at: "",
  is_active: false,
  name: "",
  phone_number: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.phone = action.payload.phone;
      state.access_token = action.payload.access_token;
      state.address = action.payload?.address;
      state.metadata = action.payload?.metadata;
      state.created_at = action.payload?.created_at;
      state.is_active = action.payload?.is_active;
      state.name = action.payload?.name;
      state.phone_number = action.payload?.phone_number;
      state.id = action.payload?.id;
    },
    logout: (state) => {
      // state.phone = "";
      state = { phone: "" };
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      return { ...initialState };
    });
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;

import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import {
  Paper,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { COLORS } from 'src/constants/colors';

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#bedcfa',
        },
      },
      row: {
        height: 10,
      },
      head: {
        height: 10,
      },
    },
  },
});

const columns = [
  { id: 'id', label: 'ID', minWidth: 20 },
  { id: 'code', label: 'Code', minWidth: 40 },
  { id: 'name', label: 'Area', minWidth: 260 },
  { id: 'description', label: 'Description', minWidth: 300 },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 420,
  },
  row: {
    border: 'none',
    height: 10,
  },
});

const CustomCheckbox = withStyles({
  root: {
    color: COLORS.cta_blue,
    '&$checked': {
      color: COLORS.cta_blue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function SearchTable({
  rows,
  rowSelected,
  multiSelection = false,
  checkedRows = [],
  setCheckedRows = () => { },
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(rows.length > 0 ? rows[0].code : '');
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows.length > 0 ? 0 : -1);
  const rowRef = useRef();

  function handleClick(row) {
    if (multiSelection) {
      handleRowCheck(row);
    } else {
      const data = [findArrayElement(rows, row.id)];
      rowSelected(data);
    }
  }

  function findArrayElement(array, id) {
    return array.find((obj) => {
      return obj.id === id;
    });
  }

  const isRowChecked = (id) => {
    return (checkedRows ?? []).findIndex((r) => r?.id == id) !== -1;
  };

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows[0].id);

      setRows(
        rows.map((item) =>
          item.id === rows[0].id ? { ...item, code: 'changed' } : item,
        ),
      );
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows.length - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      }
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function keyDown() {
    if (currentSelected < rows.length - 1) {
      const val = currentSelected + 1;

      setCurrent(val);
      setSelected(rows[val].id);

      setRows([...rows]);
    } else if (currentSelected == rows.length - 1) {
      setCurrent(0);
      setSelected(rows[0].id);

      setRows([...rows]);
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function rowClicked() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].id)];
      rowSelected(data);
    }
  }

  const handleRowCheck = (row) => {
    let previousRows = checkedRows ?? [];
    const selectedIndex = previousRows.findIndex((r) => r?.id == row?.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(previousRows, {
        id: row?.id,
        value: row?.name,
        label: row?.name,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(previousRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(previousRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        previousRows.slice(0, selectedIndex),
        previousRows.slice(selectedIndex + 1),
      );
    }
    setCheckedRows(newSelected);
  };

  const handleAllRowCheck = (event) => {
    let previousRows = [...(checkedRows ?? [])];
    if (event.target.checked == true) {
      rows?.forEach((row) => {
        const selectedIndex = previousRows.findIndex((r) => r?.id == row?.id);
        if (selectedIndex === -1) {
          previousRows.push({
            id: row?.id,
            value: row?.name,
            label: row?.name,
          });
        }
      });
      setCheckedRows(previousRows);
    } else if (event.target.checked == false) {
      setCheckedRows([]);
    }
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={rowClicked}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {multiSelection ? (
                <TableCell key="multiselect" align="center" padding="checkbox">
                  <CustomCheckbox
                    checked={
                      rows?.length === checkedRows?.length && rows?.length > 0
                        ? true
                        : false
                    }
                    onChange={(event) => handleAllRowCheck(event)}
                  />
                </TableCell>
              ) : (
                <></>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <ThemeProvider theme={theme}>
                  <TableRow
                    hover
                    className={classes.row}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={row.id === selected}
                    ref={row.id === selected ? rowRef : null}
                    onClick={(ev) => handleClick(row)}
                  >
                    {multiSelection ? (
                      <TableCell
                        key="multiselect"
                        align="center"
                        padding="checkbox"
                      >
                        <CustomCheckbox
                          checked={isRowChecked(row.id)}
                          onChange={(event) => handleRowCheck(row)}
                        />
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </ThemeProvider>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

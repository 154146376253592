import React, { forwardRef, useImperativeHandle } from 'react';
// import useStyles from 'isomorphic-style-loader/useStyles';
import { withStyles } from '@mui/styles';;
import s from './Dialogs.module.css';
import { Grid, Box, TextField, CircularProgress } from '@mui/material';
import TableComponent from 'src/components/Table/TableComponent';
import PrimaryButton from 'src/components/Buttons/PrimaryButton.js';
import Moment from 'moment';
import { financialDateRange } from 'src/constants/financialDateRange';
import apiRequest from 'src/utils/api';
import Modal from 'src/components/Modal/Modal';

const CssTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#ffffff',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const columns = [
  { id: 'vch_date', label: 'Vch Date', minWidth: 30, type: 'label' },
  { id: 'vch_number', label: 'Vch Number', minWidth: 50, type: 'link' },
  { id: 'vch_type', label: 'Vch Type', minWidth: 50, type: 'label' },
  {
    id: 'particular',
    label: 'Particulars',
    type: 'label',
    minWidth: 150,
  },
  {
    id: 'credit',
    label: 'Credit',
    type: 'label',
    align: 'right',
    type: 'label',
    minWidth: 70,
  },
  {
    id: 'debit',
    label: 'Debit',
    align: 'right',
    type: 'label',
    minWidth: 70,
  },
  {
    id: 'running_balance',
    label: 'Running Balance',
    type: 'label',
    align: 'right',
    minWidth: 80,
  },
];

function ClientLedger({ triggerClose = () => { } }, clientLedgerRef) {
  const UseFocus = () => {
    const htmlElRef = React.useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  // useStyles(s);

  useImperativeHandle(clientLedgerRef, () => ({
    handleClientLedgerOpen(client) {
      setClient(client);
      // handleClientLedgerOpen();
      setFrom(fromDate);
      setTo(today);
      getReceiptsRegisterReport(client);
    },
  }));

  const today = Moment().format('YYYY-MM-DD');
  let [fromDate, toDate] = financialDateRange(today);
  let lastFYearBegin = Moment(fromDate).subtract(1, 'y').format('YYYY-MM-DD');
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');

  const [clientLedgerOpen, setClientLedgerOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [ledgerRows, setLedgerRows] = React.useState([]);
  const [client, setClient] = React.useState({});
  const [analytics, setAnalytics] = React.useState({});

  const [toRef, setToFocus] = UseFocus();
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    setFrom(fromDate);
    setTo(today);
  }, []);

  React.useEffect(() => {
    setLedgerRows([]);
  }, [client?.id]);

  const handleLoadingOpen = () => {
    setLoadingOpen(true);
  };
  const handleLoadingClose = () => {
    setLoadingOpen(false);
  };
  const handleClientLedgerOpen = () => {
    setClientLedgerOpen(true);
  };
  const handleClientLedgerClose = () => {
    setClientLedgerOpen(false);
    triggerClose();
  };

  async function getReceiptsRegisterReport(client) {
    handleLoadingOpen();
    try {
      if (Moment(from).isBefore(lastFYearBegin)) {
        alert(
          `Invalid from date !\n\nValid Input:\n1. Must be after the begin of last financial year (${Moment(
            lastFYearBegin,
          ).format('DD-MM-YYYY')})`,
        );
        handleLoadingClose();
        setFromFocus();
      } else if (
        Moment(to).isBefore(lastFYearBegin) ||
        Moment(to).isAfter(toDate)
      ) {
        alert(
          `Invalid to date !\n\nValid Input:\n1. Must be after the begin of last financial year (${Moment(
            lastFYearBegin,
          ).format(
            'DD-MM-YYYY',
          )})\n2. Must be before the end of current financial year (${Moment(
            toDate,
          ).format('DD-MM-YYYY')})`,
        );
        handleLoadingClose();
        setToFocus();
      } else if (!client?.ledger_id) {
        alert('Ledger not found.');
      } else {
        await apiRequest(
          `api/view_accounts_ledger/${from}/${to}/${client?.ledger_id}`,
          null,
          'GET',
        ).then((response) => {
          handleLoadingClose();
          if (response?.results?.length == 0) {
            alert(`No Results found !`);
          } else {
            handleClientLedgerOpen();
            setLedgerRows(response?.results?.items);
            setAnalytics(response?.results?.analytics);
          }
        });
      }
    } catch (error) {
      alert(error.message);
      handleLoadingClose();
    }
  }
  function handleVoucherOpen(row) {
    try {
      if (row?.navigate_to)
        window.open(`/${row?.navigate_to}?${row?.header_id}`, '_blank');
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <Modal
      open={clientLedgerOpen}
      content={{
        header: `Ledger - ${client?.code || ''} - ${client?.name || ''} - ${client?.city || ''
          }`,
        body: (
          <Box style={{ minHeight: '500px' }}>
            <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '56px',
                width: '100%',
                padding: '0 8px',
                marginBottom: '8px',
                borderRadius: '8px',
                alignItems: 'center',
              }}
            >
              <Grid item md={2}>
                <CssTextField
                  className={s.margin}
                  label="From :"
                  variant="outlined"
                  value={from}
                  autoFocus
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setFrom(event.target.value);
                  }}
                  size="small"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setToFocus();
                    }
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <CssTextField
                  className={s.margin}
                  label="To :"
                  variant="outlined"
                  value={to}
                  onChange={(event) => {
                    setTo(event.target.value);
                  }}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={toRef}
                  size="small"
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setTimeout(() => {
                        buttonRef.current.focus();
                      }, 50);
                    }
                  }}
                />
              </Grid>
              <PrimaryButton
                title="Generate"
                executeClick={() => {
                  getReceiptsRegisterReport(client);
                }}
                customWidth="128px"
                customHeight="29px"
                ref={buttonRef}
              />
            </Grid>
            {loadingOpen ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '444px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#3c98e8',
                }}
              >
                <CircularProgress color="inherit" size="5rem" />
                <p style={{ fontSize: '14px', margin: '36px 0' }}>
                  Fetching Ledger. This will take a couple of seconds
                </p>
              </div>
            ) : (
              <TableComponent
                columns={columns}
                rows={ledgerRows}
                customHeight="444px"
                rowClickable={false}
                colorOnRowSelected={false}
                linkTextClicked={handleVoucherOpen}
              />
            )}
            <Grid
              container
              style={{
                backgroundColor: '#f4f4f4',
                height: '44px',
                width: '100%',
                justifyContent: 'space-between',
                padding: '0 8px',
                marginBottom: '8px',
                marginTop: '8px',
                borderRadius: '8px',
              }}
            >
              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Opening Balance:&nbsp;</p>
                <p className={s.headerValues}>
                  {analytics?.opening_balance || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Total Credit Amt:&nbsp;</p>
                <p className={s.headerValues}>{analytics?.total_credit || 0}</p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Total Debit Amt:&nbsp;</p>
                <p
                  className={s.headerValues}
                // style={{
                //   color:
                //     analytics?.net_outstanding_amount !=
                //     analytics?.closing_balance
                //       ? '#E16005'
                //       : '',
                // }}
                >
                  {analytics?.total_debit || 0}
                </p>
              </Grid>

              <Grid item alignItems="center" style={{ display: 'flex' }}>
                <p className={s.headerLabels}>Closing Balance:&nbsp;</p>
                <p
                  className={s.headerValues}
                // style={{
                //   color:
                //     analytics?.net_outstanding_amount !=
                //     analytics?.closing_balance
                //       ? '#E16005'
                //       : '',
                // }}
                >
                  {analytics?.closing_balance || 0}
                </p>
              </Grid>
            </Grid>
          </Box>
        ),
      }}
      modalClose={handleClientLedgerClose}
      size="lg"
    />
  );
}

export default forwardRef(ClientLedger);

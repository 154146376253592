import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Moment from 'moment';

const InvoiceTitle = ({ title, firmDetails, length, page, reportSettings }) => {
  const styles = StyleSheet.create({
    titleContainer: {
      flexDirection: 'row',
      marginTop: 0,
      height: '80px',
      width: reportSettings?.half_page ? '50%' : '100%',
    },
    secondaryContainer: {
      flexDirection: 'row',
      marginTop: 0,
      height: '35px',
      width: reportSettings?.half_page ? '50%' : '100%',
    },
    billTo: {
      marginTop: 0,
      paddingBottom: 3,
      fontFamily: 'Helvetica',
      fontStyle: 'heavy',
      textAlign: 'center',
      fontSize: 6,
    },
    page_number: {
      marginTop: 0,
      paddingBottom: 3,
      fontFamily: 'Helvetica',
      fontStyle: 'heavy',
      textAlign: 'right',
      fontSize: 8,
      paddingRight: '2px',
    },
    report_heading: {
      marginTop: 0,
      paddingBottom: 3,
      fontFamily: 'Helvetica',
      fontStyle: 'heavy',
      textAlign: 'center',
      fontSize: 10,
    },
    firm_name: {
      marginTop: 0,
      paddingBottom: 3,
      fontFamily: 'Helvetica',
      fontStyle: 'heavy',
      textAlign: 'center',
      fontSize: 12,
    },
  });

  if (page === 1) {
    return (
      <View style={styles.titleContainer}>
        <View
          style={{
            flexDirection: 'column',
            borderWidth: 1,
            borderColor: '#dbdbdb',
            paddingTop: '5px',
            width: '100%',
          }}
        >
          <Text style={styles.firm_name}>{firmDetails?.firm_name}</Text>
          <Text style={styles.billTo}>
            {`${[
              firmDetails.line_1,
              firmDetails.line_2,
              firmDetails.city,
              firmDetails.pincode,
            ].join()} \n ${[
              firmDetails.dl1,
              firmDetails.dl2,
              firmDetails.gstin,
            ].join()}`}
          </Text>
          <Text style={styles.report_heading}>{title}</Text>
          <Text style={styles.page_number}>
            Page: {page}/{length}
          </Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.secondaryContainer}>
        <View
          style={{
            flexDirection: 'column',
            borderWidth: 1,
            borderColor: '#dbdbdb',
            paddingTop: '5px',
            width: '100%',
          }}
        >
          <Text style={styles.report_heading}>{title}</Text>
          <Text style={styles.page_number}>
            Page: {page}/{length}
          </Text>
        </View>
      </View>
    );
  }
};

export default InvoiceTitle;

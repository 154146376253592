// // import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import Print from 'src/components/ReportPDF/Main';
import { StyleSheet, pdf } from '@react-pdf/renderer';
import axios from 'axios';
import { useSnackbar } from 'notistack';

export default function Blob({
  documentTitle,
  title,
  columns,
  rows,
  orientation = 'portrait',
  tableType = 'data-grid',
  moduleSettings = {},
  emailDetails = {},
  setLoadingOpen = () => { },
  setEmailOptionsOpen = () => { },
}) {
  // // useStyles(s);
  const userState = useSelector((state) => state.user);
  const firmDetails = useSelector((state) => state.settings);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const time = Moment().format('HH:mm:ss');
    let timestamp = `${Moment().format('DD-MMM-YYYY')} ${time}`;

    sendReportMail(emailDetails?.recipients, emailDetails?.subject, timestamp);
  }, []);

  async function sendReportMail(recipients, subject, timestamp) {
    try {
      if (recipients == '') {
        throw new Error('No Emails found !');
      }
      const formData = new FormData();
      formData.append('subject', `${subject}`);
      const element = await renderBlob(timestamp);
      const blob = await pdf(element).toBlob(); /*create blob*/
      var file = new File(
        [blob],
        `${title.toLowerCase().split(' ').join('_')}.pdf`,
        {
          lastModified: new Date().getTime(),
        },
      );
      formData.append('file', file);
      formData.append('toEmail', recipients);

      const html = `<html>
          <body>
          Hi There,<br />
            Please find the attached Report<br />
          Thank You.<br />
          </body>
        </html>`;
      formData.append('htmlText', html);

      let headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...headers,
      };
      const token = localStorage.getItem('authorization');
      if (token) {
        headers = { ...headers, Authorization: `${token}` };
      }
      const config = { headers };
      const uri = '/email_report';
      const response = await axios.post(uri, formData, config);
      if (response.code == 200) {
        enqueueSnackbar('Mail Sent Successfully', {
          variant: 'success',
          autoHideDuration: 1500,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        setLoadingOpen(false);
        setEmailOptionsOpen(false);
      } else {
        enqueueSnackbar('ERROR: Failed to send Email !', {
          variant: 'error',
          autoHideDuration: 1500,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        setLoadingOpen(false);
      }
    } catch (error) {
      setLoadingOpen(false);
      alert(error.message);
    }
  }

  async function renderBlob(timestamp) {
    return (
      <Print
        documentTitle={documentTitle}
        title={title}
        columns={columns}
        products={rows}
        firmDetails={firmDetails}
        timestamp={timestamp}
        username={userState?.username}
        orientation={orientation}
        tableType={tableType}
        moduleSettings={moduleSettings}
        printBlob={true}
      />
    );
  }

  return <></>;
}

import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  makeStyles,
  ThemeProvider,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { useSelector } from 'react-redux';

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '#PatientTable &$selected, &$selected:hover': {
          backgroundColor: '#bedcfa',
        },
      },
      row: {
        height: 10,
      },
      head: {
        height: 10,
      },
    },
  },
});

const SearchTable = (
  { rows, rowSelected, showFamily = () => { }, containerStyle = {} },
  tableRef,
) => {
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    row: {
      '& .Mui-selected': {
        color: '#3c98e8',
      },
    },
    container: {
      ...containerStyle,
    },
  });

  const classes = useStyles();
  const [selected, setSelected] = useState(
    rows.length > 0 ? rows[0].mobile : '',
  );
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows.length > 0 ? 0 : -1);
  const rowRef = useRef();
  const customizations = useSelector((state) => state.customizations);

  const columns = customizations?.patient_search?.defaultColumns ?? [
    { id: 'name', label: 'Name', minWidth: 200 },
    { id: 'mobile', label: 'Mobile', minWidth: 80 },
    { id: 'sex', label: 'Sex', minWidth: 20 },
    { id: 'age', label: 'Age', minWidth: 20 },
    { id: 'address_1', label: 'Addr 1', minWidth: 50 },
    { id: 'email', label: 'Email', minWidth: 80 },
  ];

  useImperativeHandle(
    tableRef,
    () => ({
      up() {
        keyUp();
      },
      down() {
        keyDown();
      },
      select() {
        rowClicked();
      },
      fetchFamily() {
        fetchFamily();
      },
    }),
    [currentSelected],
  );

  function handleClick(mobile) {
    const data = [findArrayElement(rows, mobile)];
    rowSelected(data);
  }

  function findArrayElement(array, mobile) {
    return array.find((obj) => {
      return obj.mobile === mobile;
    });
  }

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows[0].mobile);

      setRows(
        rows.map((item) =>
          item.mobile === rows[0].mobile ? { ...item, code: 'changed' } : item,
        ),
      );
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows.length - 1;
        setCurrent(val);
        setSelected(rows[val].mobile);

        setRows(
          rows.map((item) =>
            item.mobile === rows[val].mobile
              ? { ...item, code: 'changed' }
              : item,
          ),
        );
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].mobile);

        setRows(
          rows.map((item) =>
            item.mobile === rows[val].mobile
              ? { ...item, code: 'changed' }
              : item,
          ),
        );
      }
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function keyDown() {
    if (currentSelected < rows.length - 1) {
      const val = currentSelected + 1;

      setCurrent(val);
      setSelected(rows[val].mobile);

      setRows([...rows]);
    } else if (currentSelected == rows.length - 1) {
      setCurrent(0);
      setSelected(rows[0].mobile);

      setRows([...rows]);
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function rowClicked() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].mobile)];
      rowSelected(data);
    }
  }

  function fetchFamily() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].mobile)];
      showFamily(data);
    }
  }

  return (
    <Paper className={classes.root} elevation={0} id="PatientTable">
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={rowClicked}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <ThemeProvider theme={theme}>
                  <TableRow
                    hover
                    className={classes.row}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.mobile}
                    selected={row.mobile === selected}
                    ref={row.mobile === selected ? rowRef : null}
                    onClick={() => handleClick(row.mobile)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </ThemeProvider>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default forwardRef(SearchTable);

import React, { forwardRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import DefaultButton from '@mui/material/Button';
import { COLORS } from '../../constants/colors.js';
import clsx from 'clsx';

const UnfilledButton = forwardRef(
  (
    {
      title,
      keyboardShortcut,
      buttonSize = 'medium',
      buttonColor,
      buttonColorHover,
      executeClick,
      customMargin,
      customHeight,
      customWidth,
      disabled,
      className,
      customStyle = null,
    },
    ref,
  ) => {
    var buttonHeight;
    if (buttonSize === 'mini') {
      buttonHeight = '22px';
    } else if (buttonSize === 'small') {
      buttonHeight = '40px';
    } else if (buttonSize === 'medium') {
      buttonHeight = '52px';
    } else if (buttonSize === 'large') {
      buttonHeight = '65px';
    }

    const styles = makeStyles({
      root: {
        color: '#ffffff',
        fontSize: buttonSize === 'mini' ? 12 : 16,
        float: 'left',
        width: customWidth ? customWidth : '100%',
        borderRadius: '6px',
        height: customHeight ? customHeight : buttonHeight,
        margin: customMargin ? customMargin : '0px',
        lineHeight: 1.5,
        textTransform: 'none',
        backgroundColor: buttonColor ? buttonColor : COLORS.app_primary,
        '&:hover': {
          backgroundColor: buttonColorHover
            ? buttonColorHover
            : COLORS.app_primary_dark,
        },
      },
    });
    const classes = styles();

    return (
      <DefaultButton
        ref={ref}
        variant="contained"
        onClick={executeClick}
        className={clsx(className, classes.root)}
        disabled={disabled ? disabled : false}
        style={customStyle}
        disableElevation
      >
        {title}{' '}
        <p style={{ color: '#95ACB7' }}>
          {' '}
          {keyboardShortcut ? keyboardShortcut : ''}
        </p>
      </DefaultButton>
    );
  },
);

export default UnfilledButton;

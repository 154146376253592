import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';;
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import Moment from 'moment';

const columns = [
  { id: 'code', label: 'Code', minWidth: 20, type: 'label' },
  { id: 'name', label: 'Name', minWidth: 80, type: 'label' },
  { id: 'packing', label: 'Packing', minWidth: 20, type: 'label' },
  { id: 'purchase_price', label: 'Pur. Price', minWidth: 40, type: 'label' },
  { id: 'selling_price', label: 'Rate', minWidth: 40, type: 'label' },
  { id: 'mrp', label: 'MRP', minWidth: 40, type: 'label' },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  row: {
    '& .Mui-selected': {
      color: '#3c98e8',
    },
  },
});

export default function SearchTable({ rows, rowSelected }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [selected, setSelected] = useState(rows.length > 0 ? rows[0].code : '');
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows.length > 0 ? 0 : -1);

  function handleClick(id) {
    const data = [findArrayElement(rows, id)];
    console.log(data);
    rowSelected(data);
  }

  function findArrayElement(array, id) {
    return array.find((obj) => {
      return obj.id === id;
    });
  }

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows[0].id);
      setRows([...rows]);
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows.length - 1;
        setCurrent(val);
        setSelected(rows[val].id);
        setRows([...rows]);
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].id);
        setRows([...rows]);
      }
    }
  }

  function keyDown() {
    if (currentSelected < rows.length - 1) {
      const val = currentSelected + 1;
      console.log(`KeyUP PRESSED${rows[val].id}`);

      setCurrent(val);
      setSelected(rows[val].id);
      setRows([...rows]);
    } else if (currentSelected == rows.length - 1) {
      setCurrent(0);
      setSelected(rows[0].id);
      setRows([...rows]);
    }
    console.log(`KeyUP BLAH PRESSED${rows[0].id}`);
    console.log(rows.length);
  }

  function rowClicked() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].id)];
      console.log(data);
      rowSelected(data);
    }
  }

  function addNew() { }

  return (
    <Paper className={classes.root}>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={rowClicked}
      />
      <KeyHandler keyValue="F2" onKeyHandle={addNew} />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  hover
                  className={classes.row}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={row.id === selected}
                  onClick={() => handleClick(row.id)}
                  classes={{
                    hover: classes.hover,
                    selected: classes.selected,
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.type == 'date') {
                      const date =
                        value === '' ? '' : Moment(value).format('DD-MM-YYYY');
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {date}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface BranchType {
  name: string;
  id: number;
  branch_id: number;
  ["branch_.name"]: string;
}

export type BranchListType = Array<BranchType>;

export interface BranchState {
  branches: BranchListType;
  selectedBranch: BranchType | null;
  invoiceSettings: any | null;
}

const initialState: BranchState = {
  branches: [],
  selectedBranch: null,
  invoiceSettings: null,
};

export const branchSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    setBranches: (state, action: PayloadAction<BranchListType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.branches = action.payload;
      const branch = action.payload?.find(
        (branch: BranchType) => branch.id === state.selectedBranch?.id
      );
      if (!branch || !branch?.id) {
        state.selectedBranch = action.payload[0];
      }
    },
    selectBranch: (state, action: PayloadAction<BranchType>) => {
      state.selectedBranch = action.payload;
    },
    setInvoiceSettings: (state, action: PayloadAction<BranchType>) => {
      state.invoiceSettings = action.payload;
    },
    reset: (state) => {
      state.branches = [];
      state.selectedBranch = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      return { ...initialState };
    });
  },
});

// Action creators are generated for each case reducer function
export const { setBranches, selectBranch, reset, setInvoiceSettings } =
  branchSlice.actions;

export default branchSlice.reducer;

// // // import useStyles from 'isomorphic-style-loader/useStyles';

import React, { Component, Fragment } from 'react';
import ReportTitle from './ReportTitle';
import ReportTable from './ReportTable';
import ReportFooter from './ReportFooter';
import { Grid } from '@mui/material';

import s from './Invoice.module.css';
import { useDispatch, useSelector } from 'react-redux';

export default function Main({
  title = '',
  subTitle,
  columns,
  products,
  firmDetails,
  maxRowsPerPagePortrait = 50,
  maxRowsPerPageLandscape = 31,
  timestamp,
  username,
  orientation,
  tableType,
  additionalHeaderRow,
  reportSettings,
}) {
  // // useStyles(s);

  const pages = [];
  let max_items =
    orientation === 'portrait'
      ? maxRowsPerPagePortrait - (additionalHeaderRow?.length > 0 ? 1 : 0)
      : maxRowsPerPageLandscape - (additionalHeaderRow?.length > 0 ? 1 : 0);

  let product_rows = [...products];

  const count = parseInt((product_rows.length / max_items).toFixed(0));
  let i = 0;
  if (count > product_rows.length / max_items) {
    var start = 0;
    var end = 0;
    for (i = 0; i < count; i++) {
      end =
        product_rows.length >= start + max_items
          ? start + (i == 0 ? max_items : max_items + 5)
          : product_rows.length;
      pages[i] = product_rows.slice(start, end);
      start = end;
    }
  } else {
    var start = 0;
    var end = 0;
    for (i = 0; i < count + 1; i++) {
      end =
        product_rows.length >= start + max_items
          ? start + (i == 0 ? max_items : max_items + 5)
          : product_rows.length;
      pages[i] = product_rows.slice(start, end);
      start = end;
    }
  }

  return (
    <>
      {pages.map((page, index) => {
        if (page?.length > 0)
          return (
            <Grid
              item
              className={s.a4_page}
              style={{
                minHeight: orientation == 'portrait' ? '1754px' : '1240px',
              }}
            >
              <div
                className={s.content_container}
                style={{
                  width:
                    orientation == 'portrait'
                      ? reportSettings?.half_page
                        ? '620px'
                        : '1240px'
                      : '1754px',
                }}
              >
                <ReportTitle
                  title={title}
                  subTitle={subTitle}
                  firmDetails={firmDetails}
                  length={pages?.filter((pg) => pg.length > 0)?.length}
                  page={index + 1}
                />

                <ReportTable
                  columns={columns}
                  rows={page}
                  maxItems={max_items}
                  tableType={tableType}
                  additionalHeaderRow={additionalHeaderRow}
                />
              </div>
              <ReportFooter date={timestamp} username={username} />
            </Grid>
          );
      })}
    </>
  );
}

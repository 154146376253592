import moment from 'moment';

export const financialDateRange = (dateInput) => {
  let fYearBegin = '';
  let fYearEnd = '';

  if (parseInt(moment(dateInput).format('MM')) < 4) {
    fYearBegin = moment(dateInput).subtract(1, 'y').format('YYYY') + '-04-01';
    fYearEnd = moment(dateInput).format('YYYY') + '-03-31';
  } else {
    fYearBegin = moment(dateInput).format('YYYY') + '-04-01';
    fYearEnd = moment(dateInput).add(1, 'y').format('YYYY') + '-03-31';
  }

  let lastFYearBegin = moment(fYearBegin).subtract(1, 'y').format('YYYY-MM-DD');
  let lastFYearEnd = moment(fYearEnd).subtract(1, 'y').format('YYYY-MM-DD');
  let nextFYearBegin = moment(fYearBegin).add(1, 'y').format('YYYY-MM-DD');
  let nextFYearEnd = moment(fYearEnd).add(1, 'y').format('YYYY-MM-DD');

  return [
    fYearBegin,
    fYearEnd,
    lastFYearBegin,
    lastFYearEnd,
    nextFYearBegin,
    nextFYearEnd,
  ];
};

import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 14.5,
    fontStyle: 'bold',
    color: 'white',
  },
  dummy: {
    width: '100%',
    height: 14,
  },
});

const TableBlankSpace = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.dummy}>-</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableBlankSpace;

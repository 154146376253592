


export default async (
    path,
    body,
    method = 'POST',
    headers = {},
    cancelToken = '',
) => {
    // const uri = `${axios.defaults.baseURL}${path.replace('api', '')}`;

    // const response = await axiosPrivate({
    //   method: method,
    //   url: uri,
    //   data: body,
    //   cancelToken: cancelToken,
    // });

    return parseJsonResponse({});
};

export async function parseJsonResponse(response) {
    if (!response?.errorMessage) {
        window.json = response;
        return response;
    }
    const error = new Error(response?.errorMessage);
    error.isFromServer = true;
    error.response = response;
    error.message = response?.errorMessage;
    error.errors = response?.error;
    error.responseJson = response;
    console.log('jsonerror: %o', response?.errorMessage);
    throw new Error(response?.errorMessage);
}
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import TextField from '@mui/material/TextField';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import apiRequest from 'src/utils/api';
import Modal from '../Modal/Modal';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { AsyncPaginate } from 'react-select-async-paginate';

import SupplierMultiSelectTable from 'src/components/Table/SupplierMultiSelectTable.js';
import SearchTable from 'src/components/Table/SearchTable.js';
import SecondaryButton from 'src/components/Buttons/SecondaryButton.js';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -6px) scale(0.8)',
    },
  },
})(TextField);

const StyledTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '11px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const TableSupplierTextField = withStyles({
  root: {
    '& label': {
      display: 'none',
    },
    // '& label.MuiInputLabel-shrink': {
    //   transform: 'translate(14px, -6px) scale(0.85)',
    // },
    '& input': {
      padding: '8px 10px',
      fontSize: '11px',
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#0000',
      },
      '&:hover fieldset': {
        borderColor: '#0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000',
      },
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  box: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 25,
    margin: 10,
  },
  dialog: {
    position: 'center',
    width: '90%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: 10,
    width: '100%',
  },
}));

/*
Props used to customize the search textfield
PLEASE ADD ALL NEW PROPS TO THE DEFAULT PROPS OBJECT BELOW
*/

const defaultRenderProps = {
  readOnly: false,
  autoFocus: true,
  required: true,
  shrink: true,
  multiSelection: false,
  searchIcon: false,
  backgroundColor: '',
  isClearable: false,
  style: 'others',
};

/*
PROPS :
1. text: used to display the selected supplier code
2. supplierOpen: useState for toggling the search dialog
3. setSupplierOpen: useState function for above state
4. supplierSelected: parent function triggered when row selected in dialog
5. renderProps: used to customize the search textfield
6. internalClientTransfer: flag to know if search is restricted to internal branches
8. inputRef: useRef for focus on textField
*/

function SupplierSearch(
  {
    text,
    supplierOpen,
    setSupplierOpen,
    supplierSelected = () => { },
    supplierMultiSelected = () => { },
    clearMultiSelect = () => { },
    inputChanged = () => { },
    renderProps = defaultRenderProps,
    customMargin = '',
    internalClientTransfer,
    inputRef,
    type = 'SupplierSearch',
  },
  supplierSearchRef,
) {
  const customizations = useSelector((state) => state.customizations);
  const [value, setValue] = React.useState('');
  const [supplierRows, setSupplierRows] = React.useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = React.useState([]);

  const supplierSearchColumns = customizations?.supplier_search
    ?.defaultColumns ?? [
      { id: 'code', label: 'Code', minWidth: 50 },
      { id: 'name', label: 'Name', minWidth: 200 },
      { id: 'city', label: 'City', minWidth: 50 },
      { id: 'address_1', label: 'Addr 1', minWidth: 60 },
      { id: 'address_2', label: 'Addr 2', minWidth: 60 },
      { id: 'address_3', label: 'Addr 3', minWidth: 60 },
      { id: 'pincode', label: 'Pincode', minWidth: 50 },
      { id: 'state', label: 'State', minWidth: 80 },
      { id: 'phone', label: 'Phone', minWidth: 80 },
      { id: 'gstin', label: 'GSTIN', minWidth: 80 },
      { id: 'email', label: 'Email', minWidth: 80 },
      { id: 'contact_person', label: 'Cont. Person', minWidth: 100 },
      { id: 'secondary_phone', label: 'Sec. Phone', minWidth: 80 },
      { id: 'sms_cell', label: 'SMS Phone', minWidth: 80 },
    ];

  const componentCustomization = { ...defaultRenderProps, ...renderProps };

  useImperativeHandle(
    supplierSearchRef,
    () => ({
      clear() {
        clear();
      },
    }),
    [],
  );

  React.useEffect(() => {
    setValue('');
  }, [text]);

  const supplierModalContent = {
    body: (
      <>
        {componentCustomization?.multiSelection ? (
          <>
            <Grid item md={4} style={{ padding: '8px' }}>
              <CssTextField
                label="Search Supplier"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: <SearchIcon fontSize="small" />,
                }}
                onFocus={(event) => event.target.select()}
                InputLabelProps={{ shrink: true }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    ev.stopPropagation();

                    searchSupplier(ev.target.value);
                  }
                }}
                style={{
                  width: '100%',
                  margin: '0 0 4px 0',
                  backgroundColor: '#fff',
                }}
              />
            </Grid>
            <Grid container>
              {/* <SupplierMultiSelectTable */}
              <SupplierMultiSelectTable
                rows={supplierRows}
                rowSelected={(data) => {
                  supplierSelected(data);
                  setSupplierOpen(false);
                }}
                multiSelection={componentCustomization?.multiSelection}
                checkedRows={selectedSuppliers}
                setCheckedRows={setSelectedSuppliers}
              />
            </Grid>
            <Grid
              container
              style={{
                flexDirection: 'column',
                borderTop: 'solid 1px #e3f0f6',
              }}
            >
              <Grid
                container
                item
                style={{ backgroundColor: '#f6f6f6', padding: '16px' }}
              >
                <Grid item md={11}>
                  <AsyncPaginate
                    isMulti
                    value={selectedSuppliers}
                    onChange={setSelectedSuppliers}
                    menuIsOpen={false}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#f6f6f6',
                      },
                    })}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        height: '56px',
                        lineHeight: '16px',
                        fontSize: '20px',
                        alignItems: 'start',
                        backgroundColor: '#f6f6f6',
                        border: 'none',
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        maxHeight: '58px',
                        padding: '0px',
                        overflow: 'auto',
                      }),
                      input: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      option: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        fontSize: '12px',
                      }),
                      multiValue: (styles) => ({
                        ...styles,
                        backgroundColor: '#caeafc',
                        border: 'solid 1px #a9c6d6',
                        margin: '0 4px 8px 0',
                        height: '24px',
                        color: '#085a88',
                        borderRadius: '4px',
                      }),
                      multiValueLabel: (styles) => ({
                        ...styles,
                        color: '#085a88',
                        padding: '2px 4px 0px 8px',
                        fontSize: '12px',
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: '#1e6cc7',
                      marginTop: '4px',
                      fontSize: '12px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      clearMultiSelect();
                      setSelectedSuppliers([]);
                    }}
                  >
                    Clear
                  </span>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '16px',
                }}
              >
                <PrimaryButton
                  title="Done"
                  customWidth="96px"
                  customHeight="29px"
                  executeClick={() => {
                    supplierMultiSelected(selectedSuppliers);
                    setSupplierOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container>
            <SearchTable
              src="supplier"
              rows={supplierRows}
              rowSelected={(row) => {
                setValue('');
                supplierSelected(row);
              }}
              columns={supplierSearchColumns}
            />
          </Grid>
        )}
      </>
    ),
  };
  const handleSupplierOpen = () => {
    if (value == '') {
      setSupplierOpen(false);
    } else {
      setSupplierOpen(true);
    }
  };
  const clear = () => {
    setSelectedSuppliers([]);
  };

  async function searchSupplier(query) {
    try {
      let response;
      if (internalClientTransfer) {
        response = await apiRequest(
          `api/supplier_branch_list?search=${query}`,
          null,
          'GET',
        );
      } else {
        response = await apiRequest(
          `api/supplier_list?search=${query}`,
          null,
          'GET',
        );
      }
      setSupplierRows([]);
      if (response?.results.length == 0) {
        alert(`No Results for ${query}`);
        // setSupplierOpen(false);
      } else if (response?.results.length == 1) {
        if (query.toUpperCase() == response?.results[0].code.toUpperCase()) {
          supplierSelected(response?.results);
        } else {
          setSupplierRows(response?.results);
          handleSupplierOpen();
        }
      } else {
        setSupplierRows(response?.results);
        handleSupplierOpen();
      }
    } catch (error) {
      setSupplierOpen(false);
      alert(`Please try again \n${error}`);
    }
  }

  function supplierSearchField() {
    if (type == 'dataGridSearch')
      return (
        <TableSupplierTextField
          label="Supplier"
          required={componentCustomization?.required}
          variant="outlined"
          sx={{
            root: {
              '& .MuiOutlinedInput-root': {
                // borderRadius: '6px',
                '& fieldset': {
                  borderColor: '#0000 !important',
                },
              },
              '& label': {
                display: 'none !important',
              },
            },
          }}
          value={text === '' ? value : value === '' ? text : value}
          autoFocus={componentCustomization?.autoFocus}
          margin="normal"
          inputRef={inputRef}
          InputLabelProps={{ shrink: componentCustomization?.shrink }}
          onChange={(event) => {
            if (
              event.target.value == '' &&
              componentCustomization?.isClearable == true
            ) {
              setValue('');
              supplierSelected([{ id: '', name: '' }]);
            }

            setValue(event.target.value);
            inputChanged();
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              ev.stopPropagation();

              if (value == '') {
                if (text == '' || text == undefined) {
                  window.confirm(
                    'Invalid Input !! \nMinimum 1 letter required',
                  );
                } else {
                  setValue(text);
                  searchSupplier(text);
                }
              } else {
                searchSupplier(value);
              }
            } else if (ev.key === 'F2') {
              // handleSupplierDetailsOpen();
            }
          }}
          InputProps={{
            ...(componentCustomization?.readOnly == true
              ? {
                readOnly: true,
              }
              : ''),
            ...(componentCustomization?.searchIcon == true
              ? {
                endAdornment: <SearchIcon fontSize="small" />,
              }
              : ''),
          }}
          onFocus={(event) => event.target.select()}
          style={{
            margin: customMargin == '' ? '4px 0px' : customMargin,
            width: '100%',
            backgroundColor: componentCustomization?.backgroundColor,
          }}
        />
      );
    else
      return (
        <StyledTextField
          label="Supplier"
          required={componentCustomization?.required}
          variant="outlined"
          sx={{
            root: {
              '& .MuiOutlinedInput-root': {
                // borderRadius: '6px',
                '& fieldset': {
                  borderColor: '#0000 !important',
                },
              },
              '& label': {
                display: 'none !important',
              },
            },
          }}
          value={text === '' ? value : value === '' ? text : value}
          autoFocus={componentCustomization?.autoFocus}
          margin="normal"
          inputRef={inputRef}
          InputLabelProps={{ shrink: componentCustomization?.shrink }}
          onChange={(event) => {
            if (
              event.target.value == '' &&
              componentCustomization?.isClearable == true
            ) {
              setValue('');
              supplierSelected([{ id: '', name: '' }]);
            }

            setValue(event.target.value);
            inputChanged();
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              // ev.stopPropagation();

              if (value == '') {
                if (text == '' || text == undefined) {
                  window.confirm(
                    'Invalid Input !! \nMinimum 1 letter required',
                  );
                } else {
                  setValue(text);
                  searchSupplier(text);
                }
              } else {
                searchSupplier(value);
              }
            } else if (ev.key === 'F2') {
              // handleSupplierDetailsOpen();
            }
          }}
          InputProps={{
            ...(componentCustomization?.readOnly == true
              ? {
                readOnly: true,
              }
              : ''),
            ...(componentCustomization?.searchIcon == true
              ? {
                endAdornment: <SearchIcon fontSize="small" />,
              }
              : ''),
          }}
          onFocus={(event) => event.target.select()}
          style={{
            margin: customMargin == '' ? '4px 0px' : customMargin,
            width: '100%',
            backgroundColor: componentCustomization?.backgroundColor,
          }}
        />
      );
  }

  return (
    <div>
      {componentCustomization?.style == 'reports' ? (
        <CssTextField
          label="Supplier"
          value={text === '' ? value ?? '' : value === '' ? text : value}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          onChange={(event) => {
            setValue(event.target.value);
            if (event.target.value.trim() == '') supplierSelected([]);
          }}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.multiSelection &&
                  selectedSuppliers?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedSuppliers.length - 1}`}</span>
                ) : (
                  <></>
                )}
                <SearchIcon fontSize="small" />
              </Grid>
            ),
          }}
          onFocus={(event) => event.target.select()}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              ev.stopPropagation();

              searchSupplier(ev.target.value);
            }
          }}
          style={{
            width: '100%',
            margin: '0 0 4px 0',
            backgroundColor: '#fff',
          }}
        />
      ) : (
        supplierSearchField()
      )}
      <Modal
        open={supplierOpen}
        content={supplierModalContent}
        modalClose={() => setSupplierOpen(false)}
        size="lg"
        fullWidth
        maxWidth="xl"
        dividers
      />
    </div>
  );
}

export default forwardRef(SupplierSearch);

import * as React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage } from "src/pages/LoginPage/Loadable";
import Reports from "src/pages/Reports/Reports";

import { HomePage } from "src/pages/Home/Loadable";
import Layout from "./components/Layouts/";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}.
//     </Typography>
//   );
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/reports",
    element: (
      <Layout title="Reports">
        <Reports />
      </Layout>
    ),
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

export const COLORS = {
  primary: '#3c98e8',
  primary_dark: '#0062cc',
  green: '#33673B',
  green_dark: '#29522f',
  orange: '#f88f01',
  orange_dark: '#e27802',
  app_primary: '#1c5268',
  app_primary_dark: '#04384d',
  app_secondary: '#02aa7b',
  app_secondary_light: '#5bd9b5',
  app_secondary_dark: '#02845f',
  app_yellow: '#F7B519',
  app_yellow_dark: '#EE9F10',
  app_red: '#e24545',
  app_red_dark: '#ca2f2f',
  app_red_hover: '#FFEFEE',
  app_sunset: '#FCA189',
  app_sky_blue: '#eaf2f5',
  app_moss_green: '#D5F4ED',

  // NEW COLORS
  white: '#ffffff',
  app_bg: '#f4faff',
  cta_blue: '#003471',
  cta_blue_light: '#004AA0',
  cta_green: '#E3F7EE',
  hover_blue: '#EAF0F3',
  black: '#222222',
  blue_bright: '#397BC8',
  blue_sky: '#c7eafa',
  pale_red: '#FFE1DF',
  bright_red: '#F04134',
  rp_green: '#07B67B',
  rp_grey: '#DDDDDD',

  // NEW SETUP ////////////////////////////////////////////
  theme_primary: '#003471',
  theme_primary_text: '#003471',
  login_bg: '#003471',
  login_card_bg: 'rgb(255, 255, 255, 0.07)',
  login_card_primary_text: '#fff',
  login_card_tc: 'rgb(255,255,255,0.7)',
  login_card_span: 'rgb(255,255,255,0.1)',
  login_button: '#c4f049',
  login_button_text: '#003471',
  login_description_bg: '#e0f0ff',
  login_textfield_bg: 'transparent',

  // ENTERO
  // theme_primary: '#e43c00',
  // theme_primary_text: '#222222',
  // login_bg: '#25A492',
  // login_card_bg: '#fff',
  // login_card_primary_text: '#222222',
  // login_card_tc: '#222222',
  // login_card_span: 'rgb(255,255,255,0.1)',
  // login_button: '#e43c00',
  // login_button_text: '#fff',
  // login_description_bg: '#fafafa',
};

import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export default function TableHeader({
  columns,
  tableType,
  additionalHeaderRow,
}) {
  function getWidth(allColumns, column) {
    let total_width = 0;
    if (tableType == 'mui-table')
      total_width = allColumns?.reduce(
        (n, { minWidth }) => n + parseInt(minWidth || 40),
        0,
        ``,
      );
    else
      total_width = allColumns?.reduce(
        (n, { width }) => n + parseInt(width),
        0,
      );

    let width_share =
      (column?.[`${tableType == 'mui-table' ? 'minWidth' : 'width'}`] /
        total_width) *
      100;
    return Math.floor(width_share * 100) / 100;
  }

  const styles = columns.map((column, index) => {
    return {
      borderRightColor: '#838383',
      borderRightWidth: index == columns.length - 1 ? 0 : 1,
      color: '#000',
      height: 14.5,
      width: `${getWidth(columns, column)}%`,
      paddingLeft: '2px',
      paddingRight: '2px',
      paddingTop: '2px',
      textAlign: column.align ?? 'left',
    };
  });

  const additionalHeaderStyle = additionalHeaderRow?.map((column, index) => {
    return {
      borderRightColor: '#838383',
      borderBottomColor: '#838383',
      borderBottomWidth: 1,
      borderRightWidth: index == additionalHeaderRow.length - 1 ? 0 : 1,
      color: '#000',
      height: 14.5,
      width: `${getWidth(additionalHeaderRow, column)}%`,
      paddingLeft: '2px',
      paddingRight: '2px',
      paddingTop: '2px',
      textAlign: 'center',
    };
  });

  return (
    <>
      {additionalHeaderRow?.length > 0 ? (
        <View
          style={{
            flexDirection: 'row',
            borderBottomColor: '#dbdbdb',
            backgroundColor: '#dbdbdb',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 14,
            fontStyle: 'bold',
            width: '100%',
            fontSize: 8,
          }}
        >
          {additionalHeaderRow?.map((element, index) => {
            let val =
              tableType == 'mui-table'
                ? additionalHeaderRow[index]?.label
                : additionalHeaderRow[index]?.headerName;
            let val1 =
              tableType == 'mui-table'
                ? additionalHeaderRow[index + 1]?.label ?? ''
                : additionalHeaderRow[index + 1]?.headerName ?? '';
            return (
              <Text
                style={{
                  ...additionalHeaderStyle[index],
                  borderRightWidth:
                    index == additionalHeaderRow.length - 1 ? 0 : 1,
                  borderRightColor: (val1 ?? '') == '' ? '#dbdbdb' : '#838383',
                  // backgroundColor: (val ?? '') == '' ? '' : '#ddfaec',
                  textAlign: 'center',
                }}
              >
                {val}
              </Text>
            );
          })}
        </View>
      ) : (
        <></>
      )}
      <View
        style={{
          flexDirection: 'row',
          borderBottomColor: '#dbdbdb',
          backgroundColor: '#dbdbdb',
          borderBottomWidth: 1,
          alignItems: 'center',
          height: 14,
          fontStyle: 'bold',
          width: '100%',
          fontSize: 8,
        }}
      >
        {columns.map((element, index) => {
          return (
            <Text style={styles[index]}>
              {tableType == 'mui-table'
                ? columns[index].label
                : columns[index].headerName}
            </Text>
          );
        })}
      </View>
    </>
  );
}

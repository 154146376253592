import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ReportType {
  name: string;
  id: number;
  branch_id: number;
  ["branch_.name"]: string;
}

export type ReportListType = Array<ReportType>;

export interface ReportState {
  reports: ReportListType;
  selectedReport: ReportType | null;
  reportSettings: any | null;
}

const initialState: ReportState = {
  reports: [],
  selectedReport: null,
  reportSettings: null,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<ReportListType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.reports = action.payload;
      state.selectedReport = action.payload[0];
    },
    selectReport: (state, action: PayloadAction<ReportType>) => {
      state.selectedReport = action.payload;
    },
    reportSettings: (state, action: PayloadAction<any>) => {
      state.reportSettings = action.payload;
    },
    reset: (state) => {
      state.reports = [];
      state.selectedReport = null;
      state.reportSettings = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => Object.assign(state, initialState));
  },
});

// Action creators are generated for each case reducer function
export const { setReports, selectReport, reset, reportSettings } =
  reportSlice.actions;

export default reportSlice.reducer;

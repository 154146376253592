import React from 'react';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Invoice.module.css';
import { Grid } from '@mui/material';

export default function InvoiceTitle({
  title,
  subTitle,
  firmDetails,
  length,
  page,
}) {
  // useStyles(s);

  const renderReportHeader = () => {
    return (
      <Grid container className={s.report_details_container}>
        <Grid item md={10}>
          <p className={s.report_title}>{title}</p>
          <p className={s.report_subTitle}>{subTitle}</p>
        </Grid>
        <Grid item md={2}>
          <p className={s.page_number}>
            Page: {page}/{length}
          </p>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {page === 1 ? (
        <>
          <div className={s.header_container}>
            <div
              style={{
                flexDirection: 'column',
                borderWidth: 1,
                borderColor: '#dbdbdb',
                paddingTop: '5px',
                width: '100%',
              }}
            >
              <p className={s.firm_name}>{firmDetails?.firm_name}</p>
              <p className={s.firm_address}>
                {`${[
                  firmDetails.line_1,
                  firmDetails.line_2,
                  firmDetails.city,
                  firmDetails.pincode,
                ].join()}`}
              </p>
              <p className={s.firm_address}>
                {`${[
                  firmDetails.dl1,
                  firmDetails.dl2,
                  firmDetails.gstin,
                ].join()}`}
              </p>
              <div className={s.header_divider} />
            </div>
          </div>
          {renderReportHeader()}
        </>
      ) : (
        <>{renderReportHeader()}</>
      )}
    </>
  );
}

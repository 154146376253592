import React, { forwardRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import MUIDialog from '@mui/material/Dialog';
// import MUIDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import DefaultButton from '@mui/material/Button';
import { COLORS } from '../../constants/colors.js';
import clsx from 'clsx';
/**
 * 描述
 * @date 2021-10-27
 * @param {any} {refEl
 * @param {any} status
 * @param {any} content
 * @param {any} style}
 * @returns {any}
 */

export default function Modal({ open, content, ...props }) {
  // const [open, setOpen] = React.useState(status);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClose = () => {
    props.modalClose(false);
  };

  const handleBack = () => {
    props.modalBack();
  };
  // const [openState, setOpenState] = React.useState(open);
  const padding = content?.header || props.title ? '8px 16px' : '0px';
  const marginTop = content?.header || props.title ? '0px' : '42px';
  const StyledModal = withStyles({
    dialogPaper: {
      maxHeight: props?.height,
      minHeight: props?.height,
    },
    dialogContent: {
      padding: '0px',
      // marginTop: content.header || props.title ? '0px' : '32px',
    },
  });
  const header =
    content.header || props.title ? (
      // <div className="popper-header">{content.header || props.title}</div>
      <DialogTitle className="modal-header">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {content.header || props.title || ''}
        </Grid>
      </DialogTitle>
    ) : null;

  const body =
    content.body || props.value ? (
      <DialogContent
        style={{
          padding: props?.modalType == 'noPadding' ? '0' : padding,
          marginTop: marginTop,
          ...props?.dialogContentStyle,
        }}
      >
        {content.body || props.value}
      </DialogContent>
    ) : null;

  const footer = content.footer ? (
    <div className="popper-footer">{content.footer}</div>
  ) : null;
  return (
    <>
      {/* {props.children} */}
      <MUIDialog
        className={clsx('sp-modal', props?.size, props?.classes)}
        classes={{ paper: StyledModal.dialogPaper }}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {props?.backArrow ? (
          <IconButton
            className="sp-modal-back"
            aria-label="close"
            onClick={handleBack}
          >
            <ArrowBackSharpIcon />
          </IconButton>
        ) : (
          <></>
        )}
        <IconButton
          className="sp-modal-close"
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {header}
        {body}
        {footer}
      </MUIDialog>
    </>
  );
}

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Grid,
  Tabs,
  Tab,
  TextField,
  Switch,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import apiRequest from 'src/utils/api';
import Select from 'react-select';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import s from 'src/components/Settings.module.css';
import { CustomizationSaleDragIcon } from 'src/components/icons/customIcons';
import { COLORS } from 'src/constants/colors';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import SecondaryButton from 'src/components/Buttons/SecondaryButton';
import Modal from 'src/components/Modal/Modal';

import { withStyles } from '@mui/styles';;
import _ from 'lodash';

const CssTextField = withStyles({
  root: {
    width: 'calc(100% - 16px)',
    '& label': {
      transform: 'translate(10px, 10px) scale(1)',
      fontSize: '14px',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
    },
    '& input': {
      height: '18px',
      padding: '8px 10px',
      fontSize: '14px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '12px',
    },
    '& .MuiOutlinedInput-input': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {},
  },
})(TextField);
const GreenSwitch = withStyles({
  switchBase: {
    color: '#ccd1d3',
    '&$checked': {
      color: '#029862',
    },
    '&$checked + $track': {
      backgroundColor: '#029862',
    },
  },
  checked: {},
  track: {},
})(Switch);

function TabPanel(props) {
  const { children, value, index, height, overflowY, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: 'calc(100% - 156px)',
        height: '420px',
        overflowY: index == 2 ? 'hidden' : overflowY ?? 'auto',
      }}
    >
      {value === index && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 0 16px',
            height: height ?? '100%',
            overflowY: overflowY ?? 'auto',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
const align = [
  { value: 'left', label: 'left' },
  { value: 'right', label: 'right' },
  { value: 'center', label: 'center' },
];
function Customizations(
  { onDialogClose = () => { }, handleSaveClicked = () => { } },
  settingsRef,
) {
  useImperativeHandle(
    settingsRef,
    () => ({
      handleDialogOpen(settings) {
        handleDialogOpen();
        setSettings({ ...settings });
        setSelectedCustomization(
          settings?.customizations.map((a) => Object.assign({}, a)),
        );
      },
      handleDialogClose() {
        setOpen(false);
      },
    }),
    [],
  );

  const [open, setOpen] = React.useState(false);
  const [selectedCustomization, setSelectedCustomization] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const saleHomedrop = (e, item_arr) => {
    const copyListItems = [...item_arr];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    let customization_clone = [...selectedCustomization];
    customization_clone = copyListItems;
    setSelectedCustomization([...customization_clone]);
  };

  function handleDialogOpen() {
    setOpen(true);
  }
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  function handleTableRowValueChange(key, index, value) {
    let customization_clone = [...selectedCustomization];
    customization_clone[index][`${key}`] = value;
    setSelectedCustomization([...customization_clone]);
    // setChangesFlag(true);
  }

  const modalContent = {
    header: `Report Settings`,
    body: (
      <Grid item>
        <Grid container style={{ flexWrap: 'nowrap' }}>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={tab}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            classes={{
              root: s.settingsTabs,
              indicator: s.parentTabsIndicator,
            }}
          >
            <Tab
              classes={{
                root: s.moduleSettingsTab,
                disabled: s.parentTabDisabled,
                selected: s.parentTabSelected,
              }}
              label="General"
              disableRipple
            />
            <Tab
              classes={{
                root: s.moduleSettingsTab,
                disabled: s.parentTabDisabled,
                selected: s.parentTabSelected,
              }}
              label="Customization"
              disableRipple
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Grid
              item
              style={{
                backgroundColor: '#f4faff',
                padding: '12px 0 12px 16px',
                marginBottom: '12px',
                borderRadius: '6px',
              }}
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item md={3}>
                  Display half page only
                </Grid>
                <Grid item>
                  <GreenSwitch
                    onClick={(ev) => {
                      let input = ev.target.checked;
                      setSettings({ ...settings, half_page: input });
                    }}
                    size="small"
                    checked={settings?.half_page ?? false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid
              item
              style={{
                backgroundColor: '#f4faff',
                padding: '12px 0 12px 16px',
                marginBottom: '12px',
                borderRadius: '6px',
              }}
            >
              <Grid item md={6}>
                <CssTextField
                  autoFocus
                  label="Title"
                  variant="outlined"
                  value={settings?.title ?? ''}
                  onChange={(e) => {
                    let input = e.target.value;
                    setSettings({ ...settings, title: input });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              style={{
                backgroundColor: '#f4faff',
                height: 310,
                padding: '16px 0 16px 16px',
                borderRadius: '6px',
                overflow: 'auto',
              }}
            >
              {selectedCustomization.map((item, index) => (
                <Grid style={{ paddingBottom: '12px' }} container>
                  <Grid
                    item
                    style={{ display: 'flex' }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span
                      draggable
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={(e) => {
                        saleHomedrop(e, selectedCustomization);
                        //   setChangesFlag(true);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                      key={index}
                    >
                      <CustomizationSaleDragIcon />
                    </span>
                  </Grid>
                  <Grid item style={{ paddingLeft: '40px' }} md={10}>
                    <Grid container>
                      <Grid item md={2}>
                        <CssTextField
                          label="ID"
                          variant="outlined"
                          value={item.id}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <CssTextField
                          label="Label"
                          variant="outlined"
                          value={item.label}
                          onChange={(e) => {
                            let input = e.target.value;
                            handleTableRowValueChange('label', index, input);
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <CssTextField
                          label="Width"
                          type="number"
                          inputProps={{
                            style: { textAlign: 'right' },
                          }}
                          variant="outlined"
                          value={item.minWidth}
                          onChange={(e) => {
                            let input =
                              parseInt(e.target.value || 0) > 500
                                ? 500
                                : parseInt(e.target.value || 0);
                            handleTableRowValueChange(
                              'minWidth',

                              index,
                              input,
                            );
                          }}
                        />
                      </Grid>

                      <Grid item md={2}>
                        <CssTextField
                          label="type"
                          variant="outlined"
                          value={item.type ?? 'label'}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <Select
                          placeholder="Align"
                          menuPosition="fixed"
                          options={align}
                          value={align.filter(
                            (x) => x.value === (item?.align ?? 'left'),
                          )}
                          onChange={(event) => {
                            let input = event.value;
                            handleTableRowValueChange('align', index, input);
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                              ...theme.colors,
                              neutral20: '#A5ADB0',
                              neutral30: '#A5ADB0',
                              neutral40: 'black',
                            },
                          })}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: '35px',
                              height: '35px',
                              margin: '0px',
                            }),
                            singleValue: (styles) => ({
                              ...styles,
                              fontSize: '14px',
                            }),
                            valueContainer: (styles) => ({
                              ...styles,
                              padding: '0px 8px',
                              height: '100%',
                            }),
                            indicatorSeparator: (styles) => ({
                              ...styles,
                              width: '0px',
                            }),
                            dropdownIndicator: (styles) => ({
                              ...styles,
                              padding: '4px',
                            }),
                            option: (styles) => ({
                              ...styles,
                              fontSize: '14px',
                            }),
                            input: (styles) => ({
                              ...styles,
                              fontSize: '14px',
                            }),
                            placeholder: (styles) => ({
                              ...styles,
                              fontSize: '14px',
                            }),
                            zindex: `899 !important`,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" md={1}>
                    <GreenSwitch
                      onClick={(ev) => {
                        let input = ev.target.checked;
                        handleTableRowValueChange('enabled', index, input);
                      }}
                      size="small"
                      checked={selectedCustomization[index].enabled ?? false}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '12px 0',
          }}
        >
          <PrimaryButton
            title="Save"
            customWidth="104px"
            executeClick={() =>
              handleSaveClicked({
                ...settings,
                customizations: selectedCustomization,
              })
            }
          />
        </Grid>
      </Grid>
    ),
  };

  return (
    <Modal
      open={open}
      content={modalContent}
      modalClose={() => {
        setOpen(false);
        onDialogClose();
      }}
      size="lg"
      fullWidth
    />
  );
}

export default forwardRef(Customizations);

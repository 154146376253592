export enum StyleConstants {
  NAV_BAR_HEIGHT = "4rem",
  PAGE_PADDING = "1rem",
  GAP = "1rem",
}

export enum ColorConstants {
  BLACK = "#000",
  PRIMARY_BLACK = "#333",
  SEC_BLACK = "#222",
  WHITE = "#fff",
  OFF_WHITE = "#f3f3f3",

  PRIMARY = "#FE9C0A",
  PRIMARY_LIGHT = "#ffe586",
  PRIMARY_LIGHT_XX = "#FFFAEA",
  PRIMARY_DARK = "#030712",
  PRIMARY_DARK_X = "#d98200",

  PRIMARY_ACTIVE = "#e17100",

  SECONDARY = "#C4FFDD",
  SECONDARY_LIGHT = "#f5f5f5",
  SECONDARY_DARK = "#008137",

  BACKGROUND_COLOR = "#FFF",
  GRAY = "#696969",
  GRAY_LIGHT = "#b0b0b0",
  GRAY_LIGHT_X = "#e7e7e7",
  GRAY_LIGHT_XX = "#f2f2f2",

  GRAY_DARK = "#696969",

  GRAY_DARK_X = "#4f4f4f",
  GRAY_DARK_XX = "#3d3d3d",

  GRAY_DARK_XXX = "#262626",
}

import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as SettingIcon } from "./assets/setting-icon.svg";
// import authUser from "recoilState/authUser";
// import { useRecoilValue } from "recoil";
// import { useSnackbar } from "notistack";
import { PillButton, PrimaryButton } from "src/components/Button";
import { StyleConstants, ColorConstants } from "src/styles/StyleConstants";

import ClickAwayListener from "@mui/material/ClickAwayListener";

// import { cartState } from "recoilState/newOrderForm";
import Icon from "../Icon";
// import { useUserActions } from "recoilState/authUser/actions";
import {
  Box,
  Button as MuiButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import type { RootState } from "src/store/store";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "src/store/slices/user.slice";

export function Nav() {
  const theme = useTheme();
  // const { enqueueSnackbar } = useSnackbar();

  // const user = useRecoilValue(authUser);
  // const userActions = useUserActions();
  // const cart = useRecoilValue(cartState);
  const user = useSelector((state: RootState) => state.user);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));
  // console.log(user, 'user=====');

  const profileMenu = () => {
    return (
      <ProfileWrapper className="profileDropDown">
        <ProfileSection>
          <Section onClick={() => navigate("/profile-edit")}>
            <ProfileEditIcon>
              <Icon name="profile-edit"></Icon>
            </ProfileEditIcon>
            <H3>Profile</H3>
          </Section>
          <HR></HR>
          <Section onClick={() => navigate("/store-edit")}>
            <ProfileEditIcon>
              <Icon name="profile-edit"></Icon>
            </ProfileEditIcon>
            <H3>Store</H3>
          </Section>
          <HR></HR>
          <Section>
            <LogoutWrapper>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/login");
                  dispatch({ type: "LOGOUT" });
                }}
              >
                <span>Logout</span>
              </Button>
            </LogoutWrapper>
          </Section>
        </ProfileSection>
      </ProfileWrapper>
    );
  };
  return (
    <Wrapper>
      <LoggedInInfo>
        <LoggedInInfo></LoggedInInfo>
      </LoggedInInfo>

      <LogoutWrapper>
        <UsernameContainer>
          <Box
            sx={{
              color: ColorConstants?.PRIMARY_DARK_X,
              fontSize: "12px",
              display: "inline-block",
              marginRight: "6px",
            }}
          >
            Logged In :
          </Box>{" "}
          {user?.phone}
          {/* <RoleContainer>Admin</RoleContainer> */}
        </UsernameContainer>
        <LogoutButton
          sx={{
            marginLeft: "1rem",
          }}
          variant="outlined"
          onClick={() => {
            // enqueueSnackbar("Successfully Logged out", {
            //   variant: "success",
            // });
            // userActions.logout();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
            navigate("/login");

            dispatch({ type: "LOGOUT" });
          }}
        >
          <span>Logout</span>
        </LogoutButton>
      </LogoutWrapper>
      {/* <ClickAwayListener
        onClickAway={() => {
          setProfileMenuOpen(false);
        }}
      >
        <ProfileDrawer>
          <ProfileIcon onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
            <Icon name="user"></Icon>
          </ProfileIcon>

          {profileMenuOpen ? profileMenu() : ""}
        </ProfileDrawer>
      </ClickAwayListener> */}
    </Wrapper>
  );
}
const ProfileEditIcon = styled.div`
  position: absolute;
  /* top: 0; */
  right: 0;
`;
const LinkButton = styled.span`
  color: #6251ad;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled(MuiButton)`
  border-color: ${ColorConstants.PRIMARY_DARK_X} !important;
  color: ${ColorConstants.PRIMARY_DARK_X} !important;
  width: 180px;
  text-transform: none !important;
`;

const LogoutButton = styled(Button)`
  border-radius: 20px !important;
  width: 100px;
  height: 30px;
`;
const ClickableIcon = styled.div`
  cursor: pointer;
`;
const ProfileWrapper = styled.div`
  position: absolute;
  right: 0;
  background: #fff;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
const Section = styled.div`
  padding: 0 20px;
  cursor: pointer;
`;
const HR = styled.hr`
  background: #eaeaea;
  border: 1px solid #eaeaea;
`;
const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  /* width: 100; */
`;
const H3 = styled.h3`
  color: #6251ad;
  font-weight: bold;
  font-size: 0.875rem;
  margin: 20px 0 10px 0;
`;
const CartIconButton = styled.div`
  /* background: #9d87fe !important; */
  position: relative;
  /* max-width: 100px; */
  display: flex;
  gap: 6px;
  font-weight: 400;
  display: flex;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
`;

const CartCount = styled.div`
  position: absolute;
  left: 17px;
  top: -10px;
  background: #f9e79b;
  color: #6251ad;
  border-radius: 50%;
  padding: 3px 7px;
  /* width: 26px; */
  text-align: center;
  vertical-align: middle;
  /* height: 26px; */
  font-size: 12px;
  font-weight: 500;
  font-family: SANS-SERIF;
  line-height: 14px;
`;

const ProfileSection = styled.div`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  p {
    font-size: 0.75rem;
    margin: 6px 0;
  }
`;
const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LoggedInInfo = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 0.825rem;
`;

const CompanyInfo = styled(LoggedInInfo)`
  background: #edebf6;
  border-radius: 6px;
  padding: 2px 1rem;
`;

const LabelText = styled.div`
  padding-right: 10px;

  font-size: 0.8125rem;
  @media (max-width: 450px) {
    display: none;
  }
`;
const RoleContainer = styled.div`
  color: #333;
  text-transform: capitalize;
  background-color: rgb(239, 242, 247);
  border-radius: 4px;
  padding: 4px;
  font-size: 0.75rem;
  margin-left: 4px;
`;
const UsernameContainer = styled.div`
  font-weight: bold;
`;

const ProfileIcon = styled.div`
  display: flex;
  background-color: ${ColorConstants.PRIMARY};
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  color: #eeeef1;
  font-size: 0.75rem;
  svg {
    margin-right: -3px;
    path {
      fill: #fff;
    }
  }
`;
const ProfileDrawer = styled.div`
  position: relative;
`;

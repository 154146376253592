import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  makeStyles,
  ThemeProvider,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { ClientLedger } from 'src/components/Dialogs';

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#bedcfa',
        },
      },
      row: {
        // for the body
        height: 10,
      },
      head: {
        // for the head
        height: 10,
      },
    },
  },
});

const SearchTable = (
  { src = '', rows, rowSelected = () => { }, columns = [], containerStyle = {} },
  searchTableRef,
) => {
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 490,
    },
    row: {
      border: 'none',
      height: 10,
    },
    container: {
      ...containerStyle,
    },
  });
  const classes = useStyles();

  const [selected, setSelected] = useState(
    rows?.length > 0 ? rows?.[0]?.id : '',
  );
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows?.length > 0 ? 0 : -1);
  const [disableSelect, setDisableSelect] = useState(false);

  const rowRef = useRef();
  const ledgerRef = React.useRef();

  useImperativeHandle(
    searchTableRef,
    () => ({
      up() {
        keyUp();
      },
      down() {
        keyDown();
      },
      select() {
        rowClicked();
      },
    }),
    [currentSelected],
  );
  React.useEffect(() => {
    setSelected(rows?.length > 0 ? rows?.[0]?.id : '');
    setCurrent(rows?.length > 0 ? 0 : -1);
  }, [rows]);

  function handleClick(code) {
    const data = [findArrayElement(rows, code)];
    console.log(data);
    rowSelected(data);
  }
  function findArrayElement(array, code) {
    return array.find((obj) => {
      if (obj?.code) return obj.code === code;
      else return obj.id === code;
    });
  }

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows?.[0]?.id);

      setRows(
        rows?.map((item) =>
          item.id === rows?.[0]?.id ? { ...item, code: 'changed' } : item,
        ),
      );
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows?.length - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows?.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].id);

        setRows(
          rows?.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      }
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function keyDown() {
    if (currentSelected < rows?.length - 1) {
      const val = currentSelected + 1;

      setCurrent(val);
      setSelected(rows[val].id);

      setRows([...rows]);
    } else if (currentSelected == rows?.length - 1) {
      setCurrent(0);
      setSelected(rows?.[0]?.id);

      setRows([...rows]);
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function rowClicked() {
    if (!disableSelect) {
      if (currentSelected == -1) {
      } else {
        rowSelected([rows[currentSelected]]);
      }
    }
  }

  function ledgerTrigger() {
    if (currentSelected == -1) {
    } else {
      if (src === 'customer' || src === 'supplier') {
        const data = findArrayElement(rows, rows[currentSelected].code);
        ledgerRef?.current.handleClientLedgerOpen(data);
        setDisableSelect(true);
      }
    }
  }

  return (
    <Paper className={classes.root}>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={rowClicked}
      />
      <KeyHandler keyValue="p" onKeyHandle={ledgerTrigger} />
      <KeyHandler keyValue="P" onKeyHandle={ledgerTrigger} />

      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <ThemeProvider theme={theme}>
                  <TableRow
                    hover
                    className={classes.row}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={row.id === selected}
                    ref={row.id === selected ? rowRef : null}
                    onClick={() => handleClick(row.code ?? row?.id)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </ThemeProvider>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ClientLedger
        ref={ledgerRef}
        triggerClose={() => {
          setDisableSelect(false);
        }}
      />
    </Paper>
  );
};

export default forwardRef(SearchTable);

/**
 *
 * Layout
 *
 */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavBar } from "../NavBar";
import { PageWrapper } from "../PageWrapper";
import type { RootState } from "src/store/store";
import { useSelector, useDispatch } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
// import { useRecoilValue } from 'recoil';
// import authAtom from 'recoilState/authUser';
// import { ConfirmProvider } from 'material-ui-confirm';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Layout(props: Props) {
  // const user = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (!user || !user?.phone) {
      navigate("/login");
    }
  }, [user]);

  return (
    <>
      <NavBar title={props.title} />
      <PageWrapper>{props.children}</PageWrapper>
    </>
  );
}

import React from 'react';
import { View, StyleSheet, Font, Text } from '@react-pdf/renderer';

Font.register({
  family: 'Helvetica',
  fonts: [
    {
      src: `./data/Helvetica.ttf`,
    },
    {
      src: `./data/Helvetica-Bold.ttf`,
      fontWeight: 'bold',
    },
  ],
});

function InvoiceFooter({ date, username, reportSettings }) {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 50,
      fontStyle: 'bold',
      flexGrow: 1,
      fontSize: 7,
      width: '100%',
    },
    taken: {
      width: '60%',
      color: '#000',
      textAlign: 'right',
      height: 16,
      paddingTop: '4px',
    },
    company: {
      width: '40%',
      color: '#000',
      paddingTop: '4px',
      textAlign: 'left',
      height: 16,
    },
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 0,
        height: 14,
        width: reportSettings?.half_page ? '50%' : '100%',
      }}
    >
      <View style={styles.container}>
        <Text style={styles.company}>Powered by SmartPharma360™</Text>
        <Text style={styles.taken}>
          Taken by: {username} | Taken at: {date}
        </Text>
      </View>
    </View>
  );
}

export default InvoiceFooter;

// // import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'moment';
import { financialDateRange } from 'src/constants/financialDateRange';
import Print from './Main';
import { isMobile } from 'react-device-detect';


export default function PrintPreview({
  documentTitle,
  pdfSize,
  title,
  columns,
  rows,
  pdfOpen,
  handlePrintClose,
  orientation = 'portrait',
  tableType = 'data-grid',
  moduleSettings = {},
  reportSettings = {},
  additionalHeaderRow = [],
}) {
  // // useStyles(s);
  const userState = useSelector((state) => state.user);
  const firmDetails = useSelector((state) => state.settings);
  const [timestamp, setTimeStamp] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(true);

  React.useEffect(() => {
    const time = Moment().format('HH:mm:ss');
    setTimeStamp(`${Moment().format('DD-MMM-YYYY')} ${time}`);
  }, []);

  return (
    <div>
      <Dialog fullScreen open={pdfOpen} onClose={handlePrintClose}>
        <AppBar style={{ position: 'relative', height: '44px' }}>
          <Toolbar style={{ minHeight: '44px', justifyContent: 'flex-end' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlePrintClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Print
          documentTitle={documentTitle}
          pdfSize={pdfSize}
          title={title}
          columns={columns}
          products={rows}
          firmDetails={firmDetails}
          timestamp={timestamp}
          username={userState?.username}
          orientation={orientation}
          tableType={tableType}
          moduleSettings={moduleSettings}
          additionalHeaderRow={additionalHeaderRow}
          reportSettings={reportSettings}
          isMobile={isMobile}
          closePreview={handlePrintClose}
        // printBlob={true}
        />
      </Dialog>
    </div>
  );
}

import styled from "@emotion/styled";
import { StyleConstants } from "src/styles/StyleConstants";
// import { themes } from "styles/theme/themes";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8125rem;
  width: 100%;
  height: calc(100vh - ${StyleConstants.NAV_BAR_HEIGHT});
  margin: 0 auto;
  margin-top: ${StyleConstants.NAV_BAR_HEIGHT};
  padding: ${StyleConstants.PAGE_PADDING};
  box-sizing: border-box;
  background-color: "#fff";
  overflow: auto;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
`;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
type SettingsType = any;
const initialState: SettingsType = {};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setInvoiceSettings: (state, action: PayloadAction<SettingsType>) => {
      state = action.payload;
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      return { ...initialState };
    });
  },
});

// Action creators are generated for each case reducer function
export const { setInvoiceSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

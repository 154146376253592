import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Moment from 'moment';
import { elementType } from 'prop-types';

export default function TableRow({ columns, rows, tableType }) {
  let total_width = 0;
  if (tableType == 'mui-table')
    total_width = columns.reduce(
      (n, { minWidth }) => n + parseInt(minWidth || 40),
      0,
    );
  else total_width = columns.reduce((n, { width }) => n + parseInt(width), 0);

  const styles = columns.map((column, index) => {
    return {
      borderRightColor: '#dbdbdb',
      borderRightWidth: index == columns.length - 1 ? 0 : 1,
      color: '#000',
      height: 14.5,
      width: `${
        ((tableType == 'mui-table' ? column.minWidth : column.width) /
          total_width) *
        100
      }%`,
      paddingLeft: '2px',
      paddingRight: '2px',
      textAlign: column.align ?? 'left',
    };
  });

  const table_rows = rows.map((item) => {
    if (item?.type && item?.type == 'group_head') {
      return (
        <View
          style={{
            flexDirection: 'row',
            borderBottomColor: '#dbdbdb',
            borderBottomWidth: 1,
            alignItems: 'center',
            fontStyle: 'bold',
            fontSize: 8,
            width: '100%',
            color: '#000',
          }}
        >
          <Text
            style={{
              color: '#000',
              height: 14.5,
              width: '100%',
              paddingLeft: '2px',
              paddingRight: '2px',
              textAlign: 'left',
            }}
          >
            {item.value}
          </Text>
        </View>
      );
    } else if (
      item?.type &&
      (item?.type == 'group_header' || item?.type == 'group_footer')
    ) {
      return (
        <View
          style={{
            flexDirection: 'row',
            borderBottomColor: '#dbdbdb',
            borderBottomWidth: 1,
            alignItems: 'center',
            fontStyle: 'bold',
            fontSize: 8,
            width: '100%',
            color: '#000',
          }}
        >
          {item?.value?.map((val) => (
            <Text
              style={{
                color: '#000',
                height: 14.5,
                width: `${(val?.gridItem / 12) * 100}%`,
                paddingLeft: '2px',
                paddingRight: '2px',
                textAlign: 'left',
              }}
            >
              {val?.label}:&nbsp;
              {val?.type == 'date'
                ? Moment(val?.value)?.format('DD-MM-YYYY')
                : val?.value}
            </Text>
          ))}
        </View>
      );
    } else if (item?.type && item?.type == 'blank') {
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            fontStyle: 'bold',
            fontSize: 8,
            width: '100%',
            color: '#000',
          }}
        >
          <Text
            style={{
              color: '#000',
              height: 14.5,
              width: '100%',
              paddingLeft: '2px',
              paddingRight: '2px',
              textAlign: 'left',
            }}
          >
            {item.value}
          </Text>
        </View>
      );
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          borderBottomColor: '#dbdbdb',
          borderBottomWidth: 1,
          alignItems: 'center',
          fontStyle: 'bold',
          fontSize: 8,
          width: '100%',
          color: '#000',
        }}
      >
        {columns.map((element, index) => {
          if (element?.type == 'date') {
            if (item?.name == 'TOTAL' || item?.name == 'total')
              return <Text style={styles[index]}></Text>;
            else {
              return (
                <Text style={styles[index]}>
                  {Moment(
                    item[
                      `${
                        tableType == 'mui-table'
                          ? columns[index].id
                          : columns[index].field
                      }`
                    ],
                  ).format('DD-MM-YYYY')}
                </Text>
              );
            }
          }
          if (element?.type == 'value') {
            return (
              <Text style={styles[index]}>
                {parseFloat(
                  item[
                    `${
                      tableType == 'mui-table'
                        ? columns[index].id
                        : columns[index].field
                    }`
                  ] || 0,
                ).toFixed(2)}
              </Text>
            );
          }
          return (
            <Text style={styles[index]}>
              {
                item[
                  `${
                    tableType == 'mui-table'
                      ? columns[index].id
                      : columns[index].field
                  }`
                ]
              }
            </Text>
          );
        })}
      </View>
    );
  });
  return <Fragment>{table_rows}</Fragment>;
}

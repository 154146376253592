import React, { useRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import apiRequest from 'src/utils/api';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { AsyncPaginate } from 'react-select-async-paginate';

// COMPONENTS
import Modal from '../Modal/Modal';
import BranchTable from 'src/components/Table/BranchTable.js';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import { COLORS } from 'src/constants/colors';
import { GlobalHotKeys, configure } from 'react-hotkeys';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -6px) scale(0.8)',
    },
  },
})(TextField);

const StyledTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(8px, 9px) scale(1)',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.8)',
    },
    '& input': {
      padding: '8px 8px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#A5ADB0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
  },
})(TextField);

/* 
Props used to customize the search textfield 
PLEASE ADD ALL NEW PROPS TO THE DEFAULT PROPS OBJECT BELOW
*/
const defaultRenderProps = {
  readOnly: false,
  autoFocus: true,
  disabled: false,
  multiSelection: false,
  style: 'old',
};

/*
PROPS :
1. text: used to display the selected branch code
2. branchSelected: parent function triggered when row selected in dialog
3. renderProps: used to customize the search textfield
4. inputRef: useRef for focus on textField
*/
export default function BranchSearch({
  text,
  branchSelected = () => { },
  branchMultiSelected = () => { },
  clearMultiSelect = () => { },
  renderProps = defaultRenderProps,
  inputRef,
}) {
  const customizations = useSelector((state) => state.customizations);
  const [value, setValue] = React.useState('');
  const [branchOpen, setBranchOpen] = React.useState(false);
  const [branchRows, setBranchRows] = React.useState([]);
  const [selectedBranches, setSelectedBranches] = React.useState([]);

  // Adding the props added in the parent to the default props predefined
  const componentCustomization = { ...defaultRenderProps, ...renderProps };

  React.useEffect(() => {
    setValue('');
  }, [text]);

  const keyMap = {
    select_multiple_branches: 'F8',
  };
  const handlers = {
    select_multiple_branches: (event) => {
      event.preventDefault();
      branchMultiSelected(selectedBranches);
      setBranchOpen(false);
    },
  };
  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: function () { },
  });

  const branchModalContent = {
    body: (
      <>
        {componentCustomization?.multiSelection && (
          <Grid item md={4} style={{ padding: '8px', marginLeft: '4px' }}>
            <CssTextField
              label="Search Branch"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: <SearchIcon fontSize="small" />,
              }}
              onFocus={(event) => event.target.select()}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();

                  searchBranch(ev.target.value);
                }
              }}
              style={{
                width: '100%',
                margin: '0 0 4px 0',
                backgroundColor: '#fff',
              }}
            />
          </Grid>
        )}
        <Grid container>
          <BranchTable
            rows={branchRows}
            rowSelected={(data) => {
              console.log(data);
              branchSelected(data);
              setBranchOpen(false);
            }}
            multiSelection={componentCustomization?.multiSelection}
            checkedRows={selectedBranches}
            setCheckedRows={setSelectedBranches}
          />
        </Grid>
        {componentCustomization?.multiSelection && (
          <Grid
            container
            style={{
              flexDirection: 'column',
              borderTop: 'solid 1px #e3f0f6',
            }}
          >
            <Grid
              container
              item
              style={{ backgroundColor: '#f6f6f6', padding: '16px' }}
            >
              <Grid item md={11}>
                <AsyncPaginate
                  isMulti
                  value={selectedBranches}
                  onChange={setSelectedBranches}
                  menuIsOpen={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#f6f6f6',
                    },
                  })}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      height: '56px',
                      lineHeight: '16px',
                      fontSize: '20px',
                      alignItems: 'start',
                      backgroundColor: '#f6f6f6',
                      border: 'none',
                    }),
                    valueContainer: (styles) => ({
                      ...styles,
                      maxHeight: '58px',
                      padding: '0px',
                      overflow: 'auto',
                    }),
                    input: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    option: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: '#caeafc',
                      border: 'solid 1px #a9c6d6',
                      margin: '0 4px 8px 0',
                      height: '24px',
                      color: '#085a88',
                      borderRadius: '4px',
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: '#085a88',
                      padding: '2px 4px 0px 8px',
                      fontSize: '12px',
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                  }}
                />
              </Grid>
              <Grid
                item
                md={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#1e6cc7',
                    marginTop: '4px',
                    fontSize: '12px',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    clearMultiSelect();
                    setSelectedBranches([]);
                  }}
                >
                  Clear
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '16px',
              }}
            >
              <PrimaryButton
                title="Done"
                customWidth="96px"
                customHeight="29px"
                executeClick={() => {
                  branchMultiSelected(selectedBranches);
                  setBranchOpen(false);
                }}
                keyboardShortcut="&nbsp;(F8)"
              />
            </Grid>
          </Grid>
        )}
      </>
    ),
  };

  async function searchBranch(query) {
    try {
      setBranchRows([]);
      const response = await apiRequest(
        `api/get_branches?search=${query}`,
        null,
        'GET',
      );
      if (response?.results.length == 0) {
        alert(`No Results for ${query}`);
      } else {
        setBranchRows(
          response?.results?.map((branch) => {
            return { ...branch, value: branch?.name, label: branch?.name };
          }),
        );
        setBranchOpen(true);
      }
    } catch (error) {
      alert(`Please try again \n${error.message}`);
    }
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
      <div>
        <CssTextField
          label="Branch"
          value={text === '' ? value ?? '' : value === '' ? text : value}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.multiSelection &&
                  selectedBranches?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedBranches.length - 1}`}</span>
                ) : (
                  <></>
                )}
                <SearchIcon fontSize="small" />
              </Grid>
            ),
          }}
          onFocus={(event) => event.target.select()}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              searchBranch(ev.target.value);
            }
          }}
          style={{
            width: '100%',
            margin: '0 0 4px 0',
            backgroundColor: '#fff',
          }}
        />

        <Modal
          open={branchOpen}
          content={branchModalContent}
          modalClose={() => setBranchOpen(false)}
          size="md"
        />
      </div>
    </GlobalHotKeys>
  );
}

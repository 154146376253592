import React from "react";
import styled from "@emotion/styled";
import Icon from "src/components/Icon";
import Home from "@mui/icons-material/Home";

import Menu from "./Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { StyleConstants, ColorConstants } from "src/styles/StyleConstants";
import { HBox } from "../FlexWrapper";

// import { themes } from 'styles/theme/themes';
// import { ReactComponent as MenuIcon } from './assets/menu-icon.svg';

type Props = {
  title?: string;
};
export function Logo({ title = "Create Order" }: Props) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <MenuActionIcon onClick={() => navigate("/")}>
        {/* <Icon name="home" /> */}
        {/* <Home sx={{ color: ColorConstants.PRIMARY, marginTop: "5px" }}></Home> */}
        <img
          width="60px"
          // max-width="80%"
          alt="Mr Rep"
          src="/images/logo.png"
        ></img>
      </MenuActionIcon>{" "}
      {title === "Home" ? (
        <></>
      ) : (
        // <Title>{title}</Title>
        <HBox>
          <PreviousPageTitle onClick={() => navigate("/")}>
            Home
          </PreviousPageTitle>
          <Title>{" > "}</Title>
          <Title>{title}</Title>
        </HBox>
      )}
    </Wrapper>
  );
}
const MenuActionIcon = styled.div`
  cursor: pointer;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
`;

const PreviousPageTitle = styled.div`
  font-size: 1.25rem;
  color: ${ColorConstants.PRIMARY};
  font-weight: bold;
  margin-right: 1rem;
  cursor: pointer;
  :hover {
    color: ${ColorConstants.PRIMARY_DARK};
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  color: ${ColorConstants.PRIMARY};
  font-weight: bold;
  margin-right: 1rem;
`;

// const Description = styled('div')`
//   font-size: 0.875rem;
//   color: ${p => p.theme.textSecondary};
//   font-weight: normal;
// `;

import React, { forwardRef, useImperativeHandle } from 'react';
import Moment from 'moment';
import { numberFormat } from 'src/utils/number';
import s from './Reports.module.css';

import _ from 'lodash';
import * as XLSX from 'sheetjs-style';

const excel_header_style = {
  font: {},
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFDDFAEC' },
  },
  border: {
    right: {
      style: 'thin',
    },
    top: {
      style: 'thin',
    },
    bottom: {
      style: 'thin',
    },
  },
};
const excel_title_style = {
  font: {
    bold: true,
    color: { rgb: 'FF003471' },
    sz: 18,
  },
  alignment: {
    horizontal: 'center',
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFECEFF2' },
  },
};
const excel_sub_title_style = {
  font: {
    color: { rgb: 'FF222222' },
    sz: 12,
  },
  alignment: {
    horizontal: 'center',
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFECEFF2' },
  },
};
const excel_report_name_style = {
  font: {
    bold: true,
    color: { rgb: 'FF003471' },
    sz: 12,
  },
  border: {
    bottom: {
      style: 'thin',
      color: { rgb: 'FFA8A8AA' },
    },
  },
};
const excel_report_description_style = {
  font: {
    color: { rgb: 'FF222222' },
    sz: 12,
  },
};
const excel_report_group_head_style = {
  font: {
    sz: 12,
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFD7EAFD' },
  },
};
const excel_report_group_header_style = {
  font: {
    sz: 12,
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFD7EAFD' },
  },
};
const excel_report_group_footer_style = {
  font: {
    sz: 12,
  },
};
const excel_report_group_total_style = {
  font: {
    sz: 12,
    bold: true,
    color: { rgb: 'FF139FFF' },
  },
};
const excel_report_grand_total_style = {
  fill: {
    patternType: 'solid',
    fgColor: { rgb: 'FFE16005' },
    bgColor: { rgb: 'FFE16005' },
  },
  font: {
    sz: 12,
    bold: true,
    color: { rgb: 'FFFFFFFF' },
  },
};
const excel_report_value_cell_style = {
  alignment: {
    horizontal: 'right',
  },
};

const GenerateExcel = ({ }, generateExcelRef) => {
  useImperativeHandle(
    generateExcelRef,
    () => ({
      downloadAsExcel(title, subTitle, rows, columns, invoiceSettings) {
        downloadAsExcel(title, subTitle, rows, columns, invoiceSettings);
      },
    }),
    [],
  );

  function downloadAsExcel(title, subTitle, rows, columns, invoiceSettings) {
    const workbook = XLSX.utils.book_new();

    // Rearrange row data in the order of headers
    let parsed_rows = rows.map((row) => {
      let new_row_obj = {};
      if (Object.keys(row).includes('type') && row.type == 'group_head') {
        new_row_obj[`${0}_${columns[0].id}`] = row.value;
      } else if (
        Object.keys(row).includes('type') &&
        (row.type == 'group_header' || row.type == 'group_footer')
      ) {
        let footer_row_val = row?.value
          ?.map(
            (val) =>
              `${val?.label}: ${val?.type == 'date'
                ? Moment(val?.value)?.format('DD-MM-YYYY')
                : val?.type == 'value'
                  ? numberFormat(val?.value || 0)
                  : val?.value
              }`,
          )
          ?.join('         ');
        new_row_obj[`${0}_${columns[0].id}`] = footer_row_val;
        new_row_obj['type'] = row.type;
      } else if (
        Object.keys(row).includes('type') &&
        row.type == 'group_total'
      ) {
        columns.forEach((col, index) => {
          new_row_obj[`${index}_${col.id}`] = row[`${col.id}`];
        });
      } else {
        columns.forEach((col, index) => {
          new_row_obj[`${index}_${col.id}`] = row[`${col.id}`];
        });
      }
      return new_row_obj;
    });

    // Create Base Table Data with titles and subtitles appending in the beginning
    let headers = columns?.map((col) => col?.label);
    let json_data = [{}, {}, {}, {}, {}, {}, ...parsed_rows];
    const worksheet_input = json_data.map(({ type, ...item }) => item);
    var worksheet = XLSX.utils.json_to_sheet(worksheet_input);

    // Merge rows of Header, Title, Subtitle, Table Header
    let merge_rows_addresses = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: headers?.length - 1 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: headers?.length - 1 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: headers?.length - 1 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: headers?.length - 1 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: headers?.length - 1 } },
      { s: { r: 5, c: 0 }, e: { r: 5, c: headers?.length - 1 } },
    ];

    // Find and merge group head rows
    for (let i = 6; i < json_data?.length; i++) {
      if (
        Object.keys(json_data[i]).length == 1 ||
        json_data[i]?.type == 'group_header' ||
        json_data[i]?.type == 'group_footer'
      ) {
        merge_rows_addresses.push({
          s: { r: i + 1, c: 0 },
          e: { r: i + 1, c: headers?.length - 1 },
        });
      }
    }

    worksheet['!merges'] = merge_rows_addresses;

    // Add Headers
    XLSX.utils.sheet_add_aoa(worksheet, [[`${invoiceSettings?.firm_name}`]], {
      origin: 'A1',
    });
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          `${[
            invoiceSettings.line_1,
            invoiceSettings.line_2,
            invoiceSettings.city,
            invoiceSettings.pincode,
          ].join()}`,
        ],
      ],
      {
        origin: 'A2',
      },
    );
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          `${[
            invoiceSettings?.dl1,
            invoiceSettings?.dl2,
            invoiceSettings?.gstin,
          ].join()}`,
        ],
      ],
      {
        origin: 'A3',
      },
    );
    // Add Title and Subtitle
    XLSX.utils.sheet_add_aoa(worksheet, [[`${title}`]], {
      origin: 'A5',
    });
    XLSX.utils.sheet_add_aoa(worksheet, [[`${subTitle}`]], {
      origin: 'A6',
    });

    // Add Table Header
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A7' });

    // STYLING //////////////////////////////////////////////////////////////
    worksheet['A1'].s = excel_title_style;
    worksheet['A2'].s = excel_sub_title_style;
    worksheet['A3'].s = excel_sub_title_style;
    worksheet['A5'].s = excel_report_name_style;
    worksheet['A6'].s = excel_report_description_style;
    // Table header style
    for (let i = 0; i < headers?.length; i++)
      worksheet[`${String.fromCharCode(65 + i)}7`].s = excel_header_style;
    // Group header style
    for (let i = 6; i < json_data?.length; i++) {
      if (Object.keys(json_data[i]).length == 1) {
        worksheet[`A${i + 2}`].s = excel_report_group_head_style;
      } else if (json_data[i]?.type == 'group_header') {
        worksheet[`A${i + 2}`].s = excel_report_group_header_style;
      } else if (json_data[i]?.type == 'group_footer') {
        worksheet[`A${i + 2}`].s = excel_report_group_footer_style;
      }
    }
    // Group total style
    for (let i = 0; i < rows.length; i++) {
      if (
        Object.keys(rows[i]).includes('type') &&
        rows[i].type == 'group_total'
      ) {
        let row_index = i + 8;
        for (let i = 0; i < headers?.length; i++)
          worksheet[`${String.fromCharCode(65 + i)}${row_index}`].s =
            excel_report_group_total_style;
      }
    }
    // Grand total style
    let gt_index = rows.findIndex((row) => row.type == 'grand_total');
    if (gt_index >= 0) {
      let grand_total_row_index = gt_index + 8;
      for (let i = 0; i < headers?.length; i++) {
        worksheet[`${String.fromCharCode(65 + i)}${grand_total_row_index}`].s =
          excel_report_grand_total_style;
      }
    }
    // Value type cell right align
    for (let i = 0; i < rows.length; i++) {
      let row_index = i + 8;
      if (
        Object.keys(json_data[i + 6])?.length != 1 &&
        (json_data[i + 6]?.type != 'group_header' ||
          json_data[i + 6]?.type != 'group_footer')
      )
        for (let j = 0; j < columns?.length; j++) {
          if (columns[j].type == 'value') {
            worksheet[`${String.fromCharCode(65 + j)}${row_index}`].s =
              excel_report_value_cell_style;
          }
        }
    }

    // FORMAT //////////////////////////////////////////////////////////////
    function formatColumn(worksheet, col, fmt) {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let row = range.s.r + 1; row <= range.e.r; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[ref]) {
          worksheet[ref].z = fmt;
        }
      }
      return worksheet;
    }

    let value_type_column_indexes = [];
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].type == 'date') value_type_column_indexes.push(i);
    }
    for (let col of value_type_column_indexes) {
      worksheet = formatColumn(worksheet, col, 'dd-mm-yy');
    }

    // EXPORT //////////////////////////////////////////////////////////////
    XLSX.utils.book_append_sheet(workbook, worksheet, title?.slice(0, 30));
    XLSX.writeFile(
      workbook,
      `${title.toLowerCase().split(' ').join('_')}.xlsx`,
    );
  }

  return <></>;
};

export default forwardRef(GenerateExcel);

import React, { useRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import apiRequest from 'src/utils/api';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { AsyncPaginate } from 'react-select-async-paginate';

// COMPONENTS
import Modal from '../Modal/Modal';
import AreaTable from 'src/components/Table/AreaTable.js';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import { COLORS } from 'src/constants/colors';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '2px',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(13px, -6px) scale(0.8)',
    },
  },
})(TextField);

const StyledTextField = withStyles({
  root: {
    '& label': {
      transform: 'translate(14px, 10px) scale(1)',
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.8)',
    },
    '& input': {
      padding: '8px 8px',
    },
    '& label.Mui-focused': {
      color: '#2981E9',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#A5ADB0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#A5ADB0',
      },
      '&:hover fieldset': {
        borderColor: '#6A6A7D',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2981E9',
        borderWidth: '2px',
      },
    },
  },
})(TextField);

/* 
Props used to customize the search textfield 
PLEASE ADD ALL NEW PROPS TO THE DEFAULT PROPS OBJECT BELOW
*/
const defaultRenderProps = {
  readOnly: false,
  autoFocus: true,
  disabled: false,
  multiSelection: false,
  required: false,
  style: 'reports',
  isClearable: true,
};

/*
PROPS :
1. text: used to display the selected area code
2. areaSelected: parent function triggered when row selected in dialog
3. renderProps: used to customize the search textfield
4. inputRef: useRef for focus on textField
*/
export default function AreaSearch({
  text,
  areaSelected = () => { },
  areaMultiSelected = () => { },
  clearMultiSelect = () => { },
  renderProps = defaultRenderProps,
  inputRef,
}) {
  const customizations = useSelector((state) => state.customizations);
  const [value, setValue] = React.useState('');
  const [areaOpen, setAreaOpen] = React.useState(false);
  const [areaRows, setAreaRows] = React.useState([]);
  const [selectedAreas, setSelectedAreas] = React.useState([]);

  // Adding the props added in the parent to the default props predefined
  const componentCustomization = { ...defaultRenderProps, ...renderProps };

  React.useEffect(() => {
    setValue('');
  }, [text]);

  const areaModalContent = {
    body: (
      <>
        {componentCustomization?.multiSelection ? (
          <Grid item md={4} style={{ padding: '8px' }}>
            <CssTextField
              label="Search Area"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: <SearchIcon fontSize="small" />,
              }}
              onFocus={(event) => event.target.select()}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  searchArea(ev.target.value);
                }
              }}
              style={{
                width: '100%',
                margin: '0 0 4px 0',
                backgroundColor: '#fff',
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid container>
          <AreaTable
            rows={areaRows}
            rowSelected={(data) => {
              console.log(data);
              areaSelected(data);
              setAreaOpen(false);
            }}
            multiSelection={componentCustomization?.multiSelection}
            checkedRows={selectedAreas}
            setCheckedRows={setSelectedAreas}
          />
        </Grid>
        {componentCustomization?.multiSelection ? (
          <Grid
            container
            style={{
              flexDirection: 'column',
              borderTop: 'solid 1px #e3f0f6',
            }}
          >
            <Grid
              container
              item
              style={{ backgroundColor: '#f6f6f6', padding: '16px' }}
            >
              <Grid item md={11}>
                <AsyncPaginate
                  isMulti
                  value={selectedAreas}
                  onChange={setSelectedAreas}
                  menuIsOpen={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#f6f6f6',
                    },
                  })}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      height: '56px',
                      lineHeight: '16px',
                      fontSize: '20px',
                      alignItems: 'start',
                      backgroundColor: '#f6f6f6',
                      border: 'none',
                    }),
                    valueContainer: (styles) => ({
                      ...styles,
                      maxHeight: '58px',
                      padding: '0px',
                      overflow: 'auto',
                    }),
                    input: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    option: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      fontSize: '12px',
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: '#caeafc',
                      border: 'solid 1px #a9c6d6',
                      margin: '0 4px 8px 0',
                      height: '24px',
                      color: '#085a88',
                      borderRadius: '4px',
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: '#085a88',
                      padding: '2px 4px 0px 8px',
                      fontSize: '12px',
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                  }}
                />
              </Grid>
              <Grid
                item
                md={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#1e6cc7',
                    marginTop: '4px',
                    fontSize: '12px',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    clearMultiSelect();
                    setSelectedAreas([]);
                  }}
                >
                  Clear
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '16px',
              }}
            >
              <PrimaryButton
                title="Done"
                customWidth="96px"
                customHeight="29px"
                executeClick={() => {
                  areaMultiSelected(selectedAreas);
                  setAreaOpen(false);
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </>
    ),
  };

  async function searchArea(query) {
    try {
      setAreaRows([]);
      const response = await apiRequest(
        `api/area_list?search=${query}`,
        null,
        'GET',
      );
      if (response?.results.length == 0) {
        alert(`No Results for ${query}`);
      } else {
        setAreaRows(response?.results);
        setAreaOpen(true);
      }
    } catch (error) {
      alert(`Please try again \n${error.message}`);
    }
  }

  return (
    <div>
      {componentCustomization?.style == 'reports' ? (
        <CssTextField
          label="Area"
          value={text === '' ? value ?? '' : value === '' ? text : value}
          variant="outlined"
          size="small"
          inputRef={inputRef}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.multiSelection &&
                  selectedAreas?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedAreas.length - 1}`}</span>
                ) : (
                  <></>
                )}
                <SearchIcon fontSize="small" />
              </Grid>
            ),
          }}
          onFocus={(event) => event.target.select()}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              searchArea(ev.target.value);
            }
          }}
          style={{
            width: '100%',
            margin: '0 0 4px 0',
            backgroundColor: '#fff',
          }}
        />
      ) : (
        <StyledTextField
          label="Area"
          value={text === '' ? value ?? '' : value === '' ? text : value}
          variant="outlined"
          margin="normal"
          inputRef={inputRef}
          onChange={(event) => {
            setValue(event.target.value);
            if (
              componentCustomization?.isClearable &&
              event.target.value.trim() == ''
            )
              areaSelected([]);
          }}
          InputProps={{
            endAdornment: (
              <Grid
                container
                style={{
                  width: 'auto',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                {componentCustomization?.multiSelection &&
                  selectedAreas?.length > 1 ? (
                  <span
                    style={{
                      marginRight: '4px',
                      height: '100%',
                      backgroundColor: '#f6deac',
                      padding: '2px 4px',
                      borderRadius: '4px',
                    }}
                  >{`+${selectedAreas.length - 1}`}</span>
                ) : (
                  <></>
                )}
                <SearchIcon fontSize="small" />
              </Grid>
            ),
          }}
          onFocus={(event) => event.target.select()}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              searchArea(ev.target.value);
            }
          }}
          style={{
            margin: '0px',
            width: '100%',
            backgroundColor: '#fff',
          }}
          contentEditable={true}
          suppressContentEditableWarning={true}
        />
      )}

      <Modal
        open={areaOpen}
        content={areaModalContent}
        modalClose={() => setAreaOpen(false)}
        size="md"
      />
    </div>
  );
}

import React, { Fragment } from 'react';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Invoice.module.css';
import { Grid } from '@mui/material';
import { View } from '@react-pdf/renderer';
import Moment from 'moment';
import { numberFormat } from 'src/utils/number';
import { el } from 'date-fns/locale';

export default function TableRow({ columns, rows, tableType }) {
  // useStyles(s);

  let total_width = 0;
  if (tableType == 'mui-table')
    total_width = columns.reduce(
      (n, { minWidth }) => n + parseInt(minWidth || 40),
      0,
    );
  else total_width = columns.reduce((n, { width }) => n + parseInt(width), 0);

  function getWidth(column) {
    let width_share =
      (column?.[`${tableType == 'mui-table' ? 'minWidth' : 'width'}`] /
        total_width) *
      100;
    return Math.floor(width_share * 100) / 100;
  }
  function getValue(item, index, element) {
    let value =
      item[
      `${tableType == 'mui-table' ? columns[index].id : columns[index].field}`
      ];
    if (element.type == 'value') {
      if (item?.name?.toString()?.toUpperCase() == 'TOTAL')
        return <p style={{ margin: '0', fontWeight: '600' }}>{value}</p>;
      else
        return (
          <p style={{ margin: '0' }}>
            {value == undefined ? '' : numberFormat(parseFloat(value || 0))}
          </p>
        );
    }
    return <p style={{ margin: '0' }}>{value}</p>;
  }
  const styles = columns.map((column, index) => {
    return {
      borderRight: `solid ${index == columns.length - 1 ? 0 : 2}px #b9b9b9`,
      color: '#000',
      boxSizing: 'border-box',
      width: `${getWidth(column)}%`,
      padding: '6px 4px',
      textAlign: column.align ?? 'left',
      fontWeight: '400',
      height: '27px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  });

  const table_rows = rows.map((item) => {
    if (item?.type && item?.type == 'group_head') {
      return (
        <Grid container className={s.table_row_container}>
          <div
            style={{
              backgroundColor: item?.value?.length > 0 ? '#ddedfd' : '',
              color: '#000',
              boxSizing: 'border-box',
              width: '100%',
              padding: '6px 4px',
              textAlign: 'left',
              fontWeight: '400',
              height: '27px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <p style={{ margin: '0' }}>{item.value}</p>
          </div>
        </Grid>
      );
    }

    if (
      item?.type &&
      (item?.type == 'group_header' || item?.type == 'group_footer')
    ) {
      return (
        <Grid container className={s.table_row_container}>
          <div
            style={{
              backgroundColor: item?.type == 'group_header' ? '#ddedfd' : '',
              color: '#000',
              boxSizing: 'border-box',
              width: '100%',
              padding: '6px 4px',
              textAlign: 'left',
              fontWeight: '400',
              height: '27px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Grid container>
              {item?.value?.map((val) => {
                return (
                  <Grid item md={val?.gridItem} style={{ paddingRight: '5px' }}>
                    <p
                      style={{
                        margin: '0',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {val?.label}:&nbsp;
                      {val?.type == 'date'
                        ? Moment(val?.value)?.format('DD-MM-YYYY')
                        : val?.type == 'value'
                          ? numberFormat(val?.value || 0)
                          : val?.value}
                    </p>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      );
    }
    return (
      <Grid container className={s.table_row_container}>
        {columns.map((element, index) => {
          if (element?.type == 'date') {
            let date_value =
              item[
              `${tableType == 'mui-table'
                ? columns[index].id
                : columns[index].field
              }`
              ];
            if (
              Moment(date_value).isValid() == false ||
              date_value == undefined
            )
              return (
                <div style={styles[index]}>
                  <p style={{ margin: '0' }}></p>
                </div>
              );
            else {
              return (
                <div style={styles[index]}>
                  <p style={{ margin: '0' }}>
                    {Moment(date_value).format('DD-MM-YYYY')}
                  </p>
                </div>
              );
            }
          }
          return (
            <div style={styles[index]}>{getValue(item, index, element)}</div>
          );
        })}
      </Grid>
    );
  });
  return table_rows;
}

import React, { useRef, useState, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@mui/styles';;
import {
  Dialog,
  Paper,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { isMobile } from 'react-device-detect';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import { CSVLink, CSVDownload } from 'react-csv';
import DefaultButton from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Moment from 'moment';
import { numberFormat } from 'src/utils/number';
import s from './Reports.module.css';
import ReportHTML from 'src/components/ReportHTML';
import { useSelector } from 'react-redux';
import { COLORS } from 'src/constants/colors';

import Modal from 'src/components/Modal/Modal';
import PrimaryButton from 'src/components/Buttons/PrimaryButton';
import SecondaryButton from 'src/components/Buttons/SecondaryButton';
import RecipientsTable from 'src/components/Table/RecipientsTable';
import { DialogTitle, DialogContent } from 'src/components/Modal/Dialog';
import apiRequest from 'src/utils/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { id, te } from 'date-fns/locale';
import _ from 'lodash';
import GenerateExcel from './GenerateExcel';
import Settings from './Settings';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    flexDirection: 'column',
    padding: '8px 0 0',
    overflow: 'hidden',
  },
  container: {
    borderRadius: '8px',
    margin: '8px 0 0',
    height: '100%',
    backgroundColor: 'transparent !important',
  },
});

const CustomCheckbox = withStyles({
  root: {
    color: COLORS.cta_blue,
    '&$checked': {
      color: COLORS.cta_blue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
  },
}))(TableRow);

const ColorButton1 = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    fontSize: 12,
    float: 'left',
    width: '40px',
    height: '20px',
    margin: '0px',
    lineHeight: 0.5,
    backgroundColor: '#02aa7b',
    '&:hover': {
      backgroundColor: '#02845f',
    },
  },
}))(DefaultButton);

const StyledMenu = withStyles({
  paper: {
    outline: '1px solid #CAD8DF',
    minWidth: '94px',
    top: '162px !important',
    boxShadow: '0px 2px 8px 1px rgba(184, 207, 220, 1)',
    height: 'fit-content',
    left: '76px !important',
  },
  list: {
    padding: '0px',
  },
})((props) => <Menu elevation={0} getContentAnchorEl={null} {...props} />);

const StyledMenuItem = withStyles(() => ({
  root: {
    width: '100%',
    padding: '4px 0px',
    display: 'block',
    '& .MuiListItemText-primary': {
      fontSize: '12px',
    },
    '&:focus': {
      '&:hover': {
        '& .MuiListItemText-primary': {
          fontSize: '12px',
        },
      },
      '& .MuiListItemText-primary': {
        fontSize: '12px',
      },
    },
    '&:hover': {
      backgroundColor: '#daecf3',
      '& .MuiListItemText-primary': {
        fontSize: '12px',
      },
    },
  },
}))(MenuItem);

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3c98e8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3c98e8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3c98e8',
      },
      '&:hover fieldset': {
        borderColor: '#3c98e8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3c98e8',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

export default function TableUI({
  title,
  subTitle,
  rows,
  columns,
  csvRows,
  csvColumns,
  checkedRows,
  handleRowCheck,
  handleAllRowsCheck = () => { },
  setPdfOpen,
  sendEmail = () => { },
  emailOptionsOpen = false,
  filterEmails = {},
  setEmailOptionsOpen = () => { },
  outputType,
  orientation,
  setOrientation = () => { },
  reportSettings = {},
  setReportSettings = () => { },
}) {
  const classes = useStyles();
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [rowsData, setRowsData] = React.useState([]);
  const [csvRowsData, setCsvRowsData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [downloadOptions, setDownloadOptions] = React.useState(null);
  const [recipients, setRecipients] = React.useState('');
  const [recipientSearchText, setRecipientSearchText] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [additionalRecipientOpen, setAdditionalRecipientOpen] = useState(false);
  const [additionalRecipientRows, setAdditionalRecipientRows] = useState([]);

  const generateExcelRef = React.useRef();
  const settingsRef = React.useRef();

  React.useEffect(() => {
    setRowsData(rows);
    setSubject(title);
  }, [rows]);
  React.useEffect(() => {
    setCsvRowsData(csvRows);
  }, [csvRows]);

  React.useEffect(() => {
    setRowsData([]);
  }, [title]);

  React.useEffect(() => {
    if (emailOptionsOpen) {
      let mails = [];
      for (const [key, value] of Object.entries(filterEmails)) {
        if ((value ?? '') != '') mails.push(value);
      }
      setRecipients(
        (recipients?.length > 0 ? recipients.concat(',') : '').concat(
          mails.join(','),
        ),
      );
    }
  }, [emailOptionsOpen]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleClick(id) {
    updateRowSelected(id);
  }

  const invoiceSettings = useSelector((state) => state.settings);
  const [rowsToDownload, setRowsToDownload] = React.useState([]);

  const isRowChecked = (id) => checkedRows?.indexOf(id) !== -1;

  React.useEffect(() => {
    if (csvRows.length > 0) {
      const temp = [];
      let removeColumns = [];
      let tempColumns = [];
      csvColumns.forEach((col) => {
        if (col.label == '') {
          removeColumns.push(col.id);
        }
        if (col.label != '') {
          tempColumns.push(col.label);
        }
      });
      temp.push([`Firm Name: ${invoiceSettings?.firm_name}`]);
      temp.push([`Address: ${invoiceSettings?.line_1}`]);
      temp.push([`             : ${invoiceSettings?.line_2}`]);
      temp.push([
        `             : ${invoiceSettings?.city}, ${invoiceSettings?.pincode}`,
      ]);
      temp.push([`DL1: ${invoiceSettings?.dl1}`]);
      temp.push([`DL2: ${invoiceSettings?.dl2}`]);
      temp.push([`GSTIN: ${invoiceSettings?.gstin}`]);
      temp.push([``]);
      temp.push([title]);
      temp.push([subTitle]);

      temp.push([``]);
      temp.push([...tempColumns]);
      csvRows.map((x, index) => {
        let { ...tempX } = x;
        removeColumns.forEach((y) => delete tempX[y]);
        temp.push([...Object.values(tempX)]);
      });
      setRowsToDownload(temp);
    }
  }, [csvRowsData]);

  // API CALLS START HERE
  async function searchAllEmails(query = '') {
    setLoadingOpen(true);
    try {
      await apiRequest(`api/get_all_emails?search=${query}`, null, 'GET').then(
        (response) => {
          if (response?.results?.length == 0)
            alert(`No results found for query : ${query} !`);
          else {
            handleAdditionalRecipientOpen();
            setAdditionalRecipientRows([...response?.results]);
          }
        },
      );
    } catch (error) {
      alert(error.message);
    }
    setLoadingOpen(false);
  }
  async function handleSettingsSave(settings) {
    let id = window.location.href.split('?')[1] ?? '';
    let customization_clone = settings?.customizations?.filter(
      (row) => row?.enabled == true,
    );
    try {
      let body = {
        ...settings,
        reset: false,
        print_columns: customization_clone,
      };
      await apiRequest(
        `api/update_report_customizations/${id}`,
        body,
        'POST',
      ).then((response) => {
        if (response) {
          setRowsData([]);
          setReportSettings({ ...settings });
          settingsRef.current.handleDialogClose();
        }
      });
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  }
  async function getCustomizationSettings() {
    let id = window.location.href.split('?')[1] ?? '';
    try {
      await apiRequest(`api/get_report_customizations/${id}`, null, 'GET').then(
        (response) => {
          if (response?.results) {
            settingsRef.current.handleDialogOpen(response?.results);
          }
        },
      );
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  }
  const handleAdditionalRecipientOpen = () => {
    setAdditionalRecipientOpen(true);
  };
  const handleAdditionalRecipientClose = () => {
    setAdditionalRecipientOpen(false);
  };

  function additionalRecipientSelected(rowData) {
    let recipients_clone = recipients.split(',');
    recipients_clone.push(rowData[0]?.email);
    recipients_clone?.join(',');
    setRecipients(
      recipients_clone[0] !== ''
        ? recipients_clone?.join(',')
        : rowData[0]?.email,
    );
    setRecipientSearchText('');
    handleAdditionalRecipientClose();
  }

  const emailOptionsModalContent = {
    header: `Email Report`,
    body: (
      <Grid container style={{ padding: '8px 0' }}>
        <CssTextField
          label="Subject"
          className={s.margin}
          style={{ marginBottom: '12px' }}
          variant="outlined"
          value={subject}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setSubject(event.target.value);
          }}
          size="small"
        />
        <CssTextField
          label="Recipients"
          className={s.margin}
          variant="outlined"
          autoFocus
          value={recipients}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setRecipients(event.target.value);
          }}
          rows={4}
          multiline
          size="small"
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
            }
          }}
        />
        <Grid
          item
          md={12}
          style={{
            display: 'flex',
            marginTop: '12px',
            gap: '16px',
          }}
        >
          <Grid item>
            <CssTextField
              label="Additional Recipients"
              className={s.margin}
              size="small"
              variant="outlined"
              value={recipientSearchText}
              onChange={(event) => {
                setRecipientSearchText(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <SearchIcon fontSize="small" />,
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  searchAllEmails(ev.target.value);
                }
              }}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              title="Send Email"
              customWidth="104px"
              executeClick={() => {
                sendEmail(recipients, subject);
                setRecipients('');
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    ),
  };

  const renderTable = () => {
    if (outputType == 0)
      return (
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {csvColumns &&
                csvColumns.map((column) => {
                  if (column.type == 'hidden') {
                    return <></>;
                  }
                  if (column.type == 'multiSelectCheckbox') {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          width: column.minWidth,
                          background: '#696969',
                          color: '#ffffff',
                        }}
                      >
                        <CustomCheckbox
                          checked={checkedRows?.length == csvRowsData?.length}
                          onChange={(event) =>
                            handleAllRowsCheck(event, csvRowsData)
                          }
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          width: column.minWidth,
                          background: '#696969',
                          color: '#ffffff',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  }
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {csvRowsData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {csvColumns &&
                      csvColumns.map((column) => {
                        const value = row[column.id];
                        let bg = '';
                        if (row.discontinued == true) {
                          bg = '#FF8484';
                        }
                        if (column.type == 'value') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {numberFormat(value)}
                            </TableCell>
                          );
                        }
                        if (column.type == 'date') {
                          const date =
                            (value ?? '') === ''
                              ? ''
                              : Moment(value).format('DD-MM-YYYY');
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {date}
                            </TableCell>
                          );
                        }
                        if (column.type == 'sale_scheme') {
                          var val = '';
                          if (
                            row.sale_scheme_quantity == '' ||
                            row.sale_scheme_free == 0
                          ) {
                          } else {
                            val = `${row.sale_scheme_quantity}+${row.sale_scheme_free}`;
                          }

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {val}
                            </TableCell>
                          );
                        }
                        if (column.type == 'purchase_scheme') {
                          var val = '';
                          if (
                            row.purchase_scheme_quantity == '' ||
                            row.purchase_scheme_quantity == 0
                          ) {
                          } else {
                            val = `${row.purchase_scheme_quantity}+${row.purchase_scheme_free}`;
                          }

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {val}
                            </TableCell>
                          );
                        }
                        if (column.type == 'button') {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ background: `${bg}` }}
                            >
                              <ColorButton1
                                variant="contained"
                                color="primary"
                                onClick={() => handleClick(row.id)}
                              >
                                View
                              </ColorButton1>
                            </TableCell>
                          );
                        }
                        if (column.type == 'multiSelectCheckbox') {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              padding="checkbox"
                            >
                              <CustomCheckbox
                                checked={isRowChecked(row.id)}
                                onChange={(event) =>
                                  handleRowCheck(event, row.id)
                                }
                              />
                            </TableCell>
                          );
                        }
                        if (column.type == 'hidden') {
                          return <></>;
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ background: `${bg}` }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      );
  };

  const renderPdfHtml = () => {
    if (outputType == 1 && rowsData?.length > 0)
      return (
        <Grid
          container
          alignItems={orientation == 'portrait' ? (isMobile ? 'flex-start' : 'center') : 'flex-start'}
          style={{
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '100%',
            backgroundColor: '#d5d7d9',
            padding: `24px ${orientation == 'portrait' ? '0' : '16px'} 40px`,
            overflow: 'auto',
          }}
        >
          <ReportHTML
            title={title}
            subTitle={subTitle}
            columns={columns}
            rows={rowsData}
            tableType="mui-table"
            orientation={orientation}
            reportSettings={reportSettings}
          />
        </Grid>
      );
  };

  return (
    <div className={classes.root}>
      <Backdrop className={s.backdrop} open={loadingOpen}>
        <CircularProgress color="inherit" size="5rem" />
      </Backdrop>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ margin: outputType == 1 ? '8px 0' : '' }}
      >
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledMenu
            anchorEl={downloadOptions}
            keepMounted
            open={Boolean(downloadOptions)}
            onClose={() => setDownloadOptions(null)}
          >
            <StyledMenuItem
              onClick={() => {
                setDownloadOptions(null);
                setPdfOpen(true, 'A4');
              }}
            >
              <ListItemText
                primary="PDF (.pdf)"
                style={{ padding: '0px 10px' }}
              />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                setDownloadOptions(null);
                setPdfOpen(true, 'mobile');
              }}
            >
              <ListItemText
                primary="Mobile PDF (.pdf)"
                style={{ padding: '0px 10px' }}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <CSVLink
                data={rowsToDownload}
                filename={`${title.toLowerCase().split(' ').join('_')}.csv`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <ListItemText
                  primary="CSV (.csv)"
                  style={{ padding: '0px 10px' }}
                />
              </CSVLink>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                setDownloadOptions(null);
                generateExcelRef.current.downloadAsExcel(
                  title,
                  subTitle,
                  rows,
                  columns,
                  invoiceSettings,
                );
              }}
            >
              <ListItemText
                primary="EXCEL (.xlsx)"
                style={{ padding: '0px 10px' }}
              />
            </StyledMenuItem>
          </StyledMenu>
          <SecondaryButton
            title="Download"
            customHeight="29px"
            customWidth="130px"
            endIcon={<KeyboardArrowDownIcon />}
            executeClick={(event) => setDownloadOptions(event.currentTarget)}
            disabled={rowsData?.length > 0 ? false : true}
          />
          {/* <SecondaryButton
            title="Email"
            customHeight="29px"
            customWidth="80px"
            customMargin="0 0 0 12px"
            executeClick={(event) => {
              setEmailOptionsOpen(true);
            }}
            disabled={rowsData?.length > 0 ? false : true}
          /> */}
        </Grid>

        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {outputType == 1 && (
            <SecondaryButton
              title="Switch Orientation"
              customHeight="29px"
              customWidth="200px"
              customMargin="0 0 0 12px"
              executeClick={(event) => {
                setOrientation(
                  orientation == 'portrait' ? 'landscape' : 'portrait',
                );
              }}
              disabled={rowsData?.length > 0 ? false : true}
            />
          )}
          {outputType == 0 && (
            <TablePagination
              rowsPerPageOptions={[]}
              count={rowsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ borderBottom: '0px' }}
              backIconButtonProps={{
                style: {
                  border: '2px solid #A5ADB0',
                  padding: '4px',
                  borderRadius: '6px',
                  marginRight: '8px',
                  backgroundColor: '#ffffff',
                },
              }}
              nextIconButtonProps={{
                style: {
                  border: '2px solid #A5ADB0',
                  padding: '4px',
                  borderRadius: '6px',
                  backgroundColor: '#ffffff',
                },
              }}
            />
          )}
          {/* <SecondaryButton
            title="Settings"
            customHeight="29px"
            customWidth="88px"
            customMargin="0 0 0 12px"
            executeClick={(event) => {
              getCustomizationSettings();
            }}
          /> */}
        </Grid>
      </Grid>

      <TableContainer
        className={classes.container}
        style={{ overflow: outputType == 0 ? 'auto' : 'hidden' }}
      >
        {renderTable()}

        {renderPdfHtml()}
      </TableContainer>

      <Modal
        open={emailOptionsOpen}
        content={emailOptionsModalContent}
        modalClose={() => {
          setRecipients('');
          setEmailOptionsOpen(false);
        }}
        fullWidth
        classes={{ paperFullWidth: s.tabpanel }}
        size="sm"
        maxWidth={'md'}
      ></Modal>

      {/* COMPONENT TO CREATE EXCEL FILE */}
      <GenerateExcel ref={generateExcelRef} />

      {/* Additional Recipients model */}
      <Dialog
        onClose={handleAdditionalRecipientClose}
        aria-labelledby="customized-dialog-title"
        open={additionalRecipientOpen}
        fullWidth
        maxWidth={'md'}
        classes={{ paperFullWidth: s.tabpanel }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleAdditionalRecipientClose}
        >
          Select Additional Recipient
        </DialogTitle>
        <DialogContent dividers>
          <RecipientsTable
            rows={additionalRecipientRows}
            rowSelected={additionalRecipientSelected}
          />
        </DialogContent>
      </Dialog>

      {/* Settings */}
      <Settings
        ref={settingsRef}
        onDialogClose={() => { }}
        handleSaveClicked={handleSettingsSave}
      />
    </div>
  );
}

import React from 'react';
// // import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Invoice.module.css';
import { Grid } from '@mui/material';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableBlankSpace from './TableBlankSpace';

export default function ReportTable({
  columns,
  rows,
  maxItems,
  tableType,
  additionalHeaderRow,
}) {
  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <TableHeader
        columns={columns}
        tableType={tableType}
        additionalHeaderRow={additionalHeaderRow}
      />
      <TableRow columns={columns} rows={rows} tableType={tableType} />
    </Grid>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import { Paper, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { COLORS } from 'src/constants/colors';
import KeyHandler, { KEYPRESS } from 'react-key-handler';

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#bedcfa',
        },
      },
      row: {
        // for the body
        height: 10,
      },
      head: {
        // for the head
        height: 10,
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: 400,
  },
  row: {
    border: 'none',
    height: 10,
  },
});

const CustomCheckbox = withStyles({
  root: {
    color: COLORS.cta_blue,
    '&$checked': {
      color: COLORS.cta_blue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function SearchTable({
  rows,
  rowSelected,
  multiSelection = false,
  selectedCompanies = [],
  setSelectedCompanies = () => { },
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(rows.length > 0 ? rows[0].id : '');
  const [rowData, setRows] = useState(rows);
  const [currentSelected, setCurrent] = useState(rows.length > 0 ? 0 : -1);
  const [columns, setColumns] = useState([
    { id: 'code', label: 'Code', minWidth: 10 },
    { id: 'name', label: 'Company Name', minWidth: 200 },
  ]);
  const rowRef = useRef();

  React.useEffect(() => {
    if (multiSelection) {
      setColumns([
        {
          id: 'checkbox',
          label: '',
          minWidth: 10,
          type: 'multiSelectCheckbox',
        },
        { id: 'code', label: 'Code', minWidth: 10 },
        { id: 'name', label: 'Company Name', minWidth: 200 },
      ]);
    } else {
      setColumns([
        { id: 'code', label: 'Code', minWidth: 10 },
        { id: 'name', label: 'Company Name', minWidth: 200 },
      ]);
    }
  }, [multiSelection]);

  React.useEffect(() => {
    setCurrent(rows.length > 0 ? 0 : -1);
    setSelected(rows.length > 0 ? rows[0].id : '');
  }, [rows]);
  function handleClick(event, id) {
    if (!multiSelection) {
      const data = [findArrayElement(rows, id)];
      rowSelected(data);
    } else {
      handleRowCheck(id);
    }
  }

  function findArrayElement(array, id) {
    return array.find((obj) => {
      return obj.id === id;
    });
  }

  function keyUp() {
    if (currentSelected == -1) {
      setCurrent(0);
      setSelected(rows[0].id);
      setRows(
        rows.map((item) =>
          item.id === rows[0].id ? { ...item, code: 'changed' } : item,
        ),
      );
    } else if (currentSelected > -1) {
      if (currentSelected == 0) {
        var val = rows.length - 1;
        setCurrent(val);
        setSelected(rows[val].id);
        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      } else {
        var val = currentSelected - 1;
        setCurrent(val);
        setSelected(rows[val].id);
        setRows(
          rows.map((item) =>
            item.id === rows[val].id ? { ...item, code: 'changed' } : item,
          ),
        );
      }
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function keyDown() {
    if (currentSelected < rows.length - 1) {
      const val = currentSelected + 1;
      setCurrent(val);
      setSelected(rows[val].id);
      setRows([...rows]);
    } else if (currentSelected == rows.length - 1) {
      setCurrent(0);
      setSelected(rows[0].id);
      setRows([...rows]);
    }
    rowRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  function rowClicked() {
    if (currentSelected == -1) {
    } else {
      const data = [findArrayElement(rows, rows[currentSelected].id)];
      rowSelected(data);
    }
  }

  const isRowChecked = (id) =>
    selectedCompanies?.map((company) => company?.id)?.indexOf(id) !== -1;
  const handleRowCheck = (id) => {
    const selectedIndex = selectedCompanies
      ?.map((company) => company?.id)
      .indexOf(id);
    const data = [findArrayElement(rows, id)];

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCompanies, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCompanies.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selectedCompanies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCompanies.slice(0, selectedIndex),
        selectedCompanies.slice(selectedIndex + 1),
      );
    }
    setSelectedCompanies(newSelected);
  };

  return (
    <Paper className={classes.root}>
      <KeyHandler keyValue="ArrowUp" onKeyHandle={keyUp} />
      <KeyHandler keyValue="ArrowDown" onKeyHandle={keyDown} />
      <KeyHandler
        keyEventName={KEYPRESS}
        keyValue="Enter"
        onKeyHandle={() => {
          multiSelection ? handleRowCheck(selected) : rowClicked();
        }}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: '#000000',
                    color: '#ffffff',
                    height: '10px',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <ThemeProvider theme={theme}>
                  <TableRow
                    hover
                    className={classes.row}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={row.id === selected}
                    ref={row.id === selected ? rowRef : null}
                    onClick={(ev) => handleClick(ev, row.id)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.type == 'multiSelectCheckbox') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            padding="checkbox"
                          >
                            <CustomCheckbox
                              checked={isRowChecked(row.id)}
                              onChange={(event) => handleRowCheck(row?.id)}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </ThemeProvider>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

import React, { forwardRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';;
import DefaultButton from '@mui/material/Button';
import { COLORS } from '../../constants/colors.js';
import clsx from 'clsx';
// import { BulkEdit } from 'src/components/icons/customIcons';

const EditButton = forwardRef(
  (
    {
      keyboardShortcut,
      buttonSize = 'medium',
      executeClick,
      customFontSize,
      customMargin,
      customHeight,
      customWidth,
      buttonType,
      borderWidth,
      disabled,
      className,
      // icon = <BulkEdit />,
      ...props
    },
    ref,
  ) => {
    var button_height;
    var font_size;
    if (buttonSize === 'small') {
      button_height = '28px';
      font_size = '11px';
    } else if (buttonSize === 'medium') {
      button_height = '36px';
      font_size = '12px';
    } else if (buttonSize === 'large') {
      button_height = '52px';
      font_size = '13px';
    }

    var btn_color = COLORS.cta_blue;
    var btn_color_hover = COLORS.hover_blue;
    if (buttonType == 'alert') {
      var btn_color = COLORS.app_red;
      var btn_color_hover = COLORS.app_red_hover;
    }

    const styles = makeStyles({
      root: {
        color: btn_color,
        fontSize: customFontSize ? customFontSize : font_size,
        fontWeight: 500,
        fontFamily: 'Work Sans',
        width: customWidth ? customWidth : 'auto',
        borderRadius: '6px',
        height: customHeight ? customHeight : button_height,
        margin: customMargin ? customMargin : '0px',
        lineHeight: 1.5,
        textTransform: 'none',
        backgroundColor: COLORS.white,
        border: `${borderWidth == 'thin' ? 1 : 2}px solid ${disabled ? '#B3B3B3' : btn_color
          }`,
        '&:hover': {
          backgroundColor: btn_color_hover,
        },
        padding: '4px',
        minWidth: 'auto',
      },
    });
    const classes = styles();

    return (
      <DefaultButton
        ref={ref}
        variant="contained"
        onClick={executeClick}
        className={clsx(className, classes.root)}
        disabled={disabled ? disabled : false}
        disableElevation
        {...props}
      >
        {icon}
      </DefaultButton>
    );
  },
);

export default EditButton;
